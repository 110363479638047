import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../../components/Tables";
import { Button } from "../../../../components/Forms";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../../api";
import Segment from "../../../../components/Segment";
import CommonFunctions from "../../../../CommonFunctions";
import Loader from "../../../../components/Loader";
import { PlusIcon } from "@heroicons/react/solid";
import DebtorBar from "../_components/DebtorBar";
import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";

function TaskList({ matter, debtor }) {
  const params = useParams();
  const history = useHistory();

  const [contactTasks, setContactTasks] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    API.matters.debtors.tasks
      .list(params.matter_id, params.debtor_id)
      .then((res) => {
        setContactTasks(res);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setMountLoading(false);
      });
  }, [params.matter_id, params.debtor_id]);

  return (
    <>
      <div className="">
        <Segment>
          <DebtorBar debtor={debtor} contact={matter.contact} />
          <div>
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">Tasks</span>
              <Button
                colour="green"
                label="Create"
                onClick={() =>
                  history.push(
                    `/matters/${params.matter_id}/debtors/${params.debtor_id}/tasks/add`
                  )
                }
                className="ml-auto"
                icon={PlusIcon}
              />
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {mountLoading ? (
            <Loader />
          ) : (
            <ThemeTable
              header={[
                { text: "Task", value: "description" },
                { text: "Fee Earner", value: "assigned" },
                { text: "Due Date", value: "due_date" },
                { text: "Status", value: "status" },
                { text: "Time", value: "time" },
                { text: "Billable", value: "isBillable" },
              ]}
              body={contactTasks.map((item) => ({
                ...item,
                isBillable: item.isBillable === true ? "Yes" : "No",
                matter_display_number:
                  item.matter && item.matter !== null
                    ? item.matter.display_number
                    : "--",
                assignee_name: item.assignee ? item.assignee.name : "--",
                due_date: item.due_date
                  ? dayjs(item.due_date).format("DD/MM/YYYY")
                  : "",
              }))}
              action={(item) =>
                history.push(
                  `/matters/${params.matter_id}/debtors/${params.debtor_id}/tasks/${item._id}`
                )
              }
              noDataMessage="No Tasks..."
            />
          )}
        </Segment>
      </div>
    </>
  );
}
export default ChainedContext(TaskList, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
