import React from "react";
import { Switch, Route } from "react-router-dom";
import list from "./list";
import add from "./forms/add";
import edit from "./forms/edit";
import view from "./view";
import NotFound from "../../../../components/NotFound";

const routePrefix = "/matters/:matter_id/debtors/:debtor_id/activities";

export default function ActivityPages() {
  return (
    <Switch>
      <Route exact path={`${routePrefix}/add`} component={add} />
      <Route exact path={`${routePrefix}`} component={list} />
      <Route
        path={`${routePrefix}/:activity_id`}
        component={ViewActivitiesRoutes}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function ViewActivitiesRoutes() {
  return (
    <Switch>
      <Route exact path={`${routePrefix}/:activity_id/edit`} component={edit} />
      <Route exact path={`${routePrefix}/:activity_id`} component={view} />
    </Switch>
  );
}
