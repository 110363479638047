import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default {
  normal: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/base_rates/normal${org ? "?org_id="+org : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/base_rates/normal`, data),
    update: (rateId, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/base_rates/normal/${rateId}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/base_rates/normal/${rateId}`)
  },
  statutory: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/base_rates/statutory${org ? "?org_id="+org : ""}`),
    update: (id, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/base_rates/statutory/${id}`, data),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/base_rates/statutory`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/base_rates/statutory/${rateId}`)
  },
  compensation: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/base_rates/compensation${org ? "?org_id="+org : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/base_rates/compensation`, data),
    update: (id, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/base_rates/compensation/${id}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/base_rates/compensation/${rateId}`)
  },
  rateTester: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/base_rates/tester`, data),
  late_payments: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/base_rates/late_payments${org ? "?org_id="+org : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/base_rates/late_payments`, data),
    update: (rateId, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/base_rates/late_payments/${rateId}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/base_rates/late_payments/${rateId}`)
  }
}