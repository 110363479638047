import React from "react";
import { Disclosure } from "@headlessui/react";

export default function Header() {
  return (
    <Disclosure as="nav" className="bg-gray-100 sticky-item-sub-nav">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 border-b border-primary">
            <div className="flex items-center justify-between h-12">
              <div className="flex items-center">
                <div className="block">
                  <div className="flex items-center justify-center text-center space-x-4">
                    <div className="cursor-pointer flex gap-2 antialiased text-xl px-2 py-2 justify-center items-center text-primary tracking-wider">
                      <span>Global Search</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
