import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../components/NotFound";
import ContactProvider from "../../contexts/contact_context/Contact_Provider";
import api from "../../api";

// CONTACTS
import Dash from "./list";
import AddContact from "./addContact";
import EditContact from "./editContact";

// VIEW CONTACT
import { Navbar } from "./components";
import ContactView from "./view";
import Settings from "./settings";
import MatterRoutes from "./matters";
import TaskRoutes from "./tasks";
import CreditCheckRoutes from "./creditCheck";
import ActivityRoutes from "./activities";
import viewDetails from "./viewDetails";

const routePrefix = "/contacts";

export default function Index() {
  return (
    <div>
      <Switch>
        <Route exact path={`${routePrefix}/add`} component={AddContact} />
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route
          path={`${routePrefix}/:contact_id`}
          component={ViewContactRoutes}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

function ViewContactRoutes({ history, match }) {
  return (
    <div>
      <ContactProvider api={api}>
        <Navbar history={history} match={match} />
        <Switch>
          <Route
            path={`${routePrefix}/:contact_id/activities`}
            component={ActivityRoutes}
          />
          <Route
            path={`${routePrefix}/:contact_id/tasks`}
            component={TaskRoutes}
          />
          <Route
            path={`${routePrefix}/:contact_id/matters`}
            component={MatterRoutes}
          />
          <Route
            path={`${routePrefix}/:contact_id/credit_checks`}
            component={CreditCheckRoutes}
          />
          <Route
            exact
            path={`${routePrefix}/:contact_id/settings`}
            component={Settings}
          />
          <Route
            exact
            path={`${routePrefix}/:contact_id/edit`}
            component={EditContact}
          />
          <Route
            exact
            path={`${routePrefix}/:contact_id/details`}
            component={viewDetails}
          />
          <Route
            exact
            path={`${routePrefix}/:contact_id`}
            component={ContactView}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </ContactProvider>
    </div>
  );
}
