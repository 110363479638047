import { ArrowRightIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";
import React from "react";
import Loader from "../../../Loader";

const ResultsContainer = ({
  isLoading,
  results,
  isCompact = false,
  history,
  query,
}) => {
  if (isLoading) {
    return <Loader>Loading Results...</Loader>;
  }

  if (results.contacts.length === 0 && results.companies.length === 0) {
    return <p className={"p-4 text-sm"}>There are no results to display.</p>;
  }

  const renderContacts = () => {
    if (results.contacts.length) {
      return (
        <React.Fragment>
          <div className="z-10 sticky top-0 border-t border-b rounded-t-xl border-gray-300 bg-gray-300 px-6 py-1 text-sm font-medium text-gray-500">
            <h3>Contacts</h3>
          </div>
          {results.contacts.map((contact) => {
            return (
              <div
                className={"p-2 border cursor-pointer hover:bg-gray-50"}
                key={contact._id}
                onClick={() => history.push("/contacts/" + contact._id)}
              >
                <span className={"text-lg"}>
                  {contact.first_name} {contact.last_name}
                </span>
                {contact.primary_phone_number && (
                  <div className="flex items-center gap-1 items-middle text-sm">
                    <PhoneIcon className="h-4 text-amber-500" />{" "}
                    {contact.primary_phone_number
                      ? contact.primary_phone_number
                      : "None"}
                  </div>
                )}
                <div className="flex items-center gap-1 items-middle text-sm">
                  <MailIcon className="h-4 text-amber-500" />{" "}
                  {contact.primary_email_address
                    ? contact.primary_email_address
                    : "None"}
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    }
    return <></>;
  };

  const renderCompanies = () => {
    if (results.companies.length) {
      return (
        <React.Fragment>
          <div className="z-10 sticky top-0 border-t border-b border-gray-300 bg-gray-300 px-6 py-1 text-sm font-medium text-gray-500">
            <h3>Companies</h3>
          </div>
          {results.companies.map((contact) => {
            return (
              <div
                className={"p-2 border cursor-pointer hover:bg-gray-50"}
                key={contact._id}
                onClick={() => history.push("/contacts/" + contact._id)}
              >
                <span className={"text-lg"}>{contact.name}{contact.suffix ? " "+ contact.suffix : ""}</span>
                <div className="flex items-center gap-1 items-middle text-sm">
                  <PhoneIcon className="h-4 text-amber-500" />{" "}
                  {contact.primary_phone_number
                    ? contact.primary_phone_number
                    : "None"}
                </div>
                <div className="flex items-center gap-1 items-middle text-sm">
                  <MailIcon className="h-4 text-amber-500" />{" "}
                  {contact.primary_email_address
                    ? contact.primary_email_address
                    : "None"}
                </div>
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return <></>;
  };

  return (
    <div>
      {renderContacts()}
      {renderCompanies()}
      {isCompact && (
        <div className="text-right flex justify-end ">
          <button
            className={
              "p-2 text-center text-sm rounded-xl hover:bg-gray-200 flex "
            }
            onClick={() => history.push("/search", { query })}
          >
            View Full Search <ArrowRightIcon className="ml-2 h-5" />
          </button>
        </div>
      )}
    </div>
  );
};
export default ResultsContainer;
