import React, { useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  Button,
  Group,
  Input,
  SelectTwo,
  SelectZero,
  TextArea,
} from "../../../../components/Forms";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { CountiesList } from "../../../../_lists";
const addressTypes = [
  { key: 0, text: "Work", value: "Work" },
  { key: 1, text: "Home", value: "Home" },
  { key: 2, text: "Billing", value: "Billing" },
  { key: 3, text: "Other", value: "Other" },
];

const defaultData = {
  street: "",
  city: "",
  postal_code: "",
  province: "",
  country: "United Kingdom",
  name: "Work",
  primary: false,
};

const AddressList = ({ addresses = [], onChange, showValidationErrors }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState({
    ...defaultData,
  });

  const onAddressChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const onRegionChange = (e) => setData({ ...data, province: e.target.value });
  const onCountryChange = (val) => setData({ ...data, country: val });
  const onAddressTypeChange = (val) =>
    setData({ ...data, name: val.target.value });

  const handleAddressFormSubmit = () => {
    if (editIndex !== null) {
      onChange(
        addresses.map((item, index) => {
          if (index !== editIndex) return item;
          return {
            ...item,
            ...data,
          };
        })
      );
      setEditIndex(null);
    } else {
      if(!addresses.length > 0){
        data.primary = true;
      }
      onChange(
        [
          {
            ...data,
          },
        ].concat(addresses)
      );
    }
    setData({
      ...defaultData,
    });
  };
  const handleAddressEditButton = (index) => () => {
    setData(addresses[index]);
    setEditIndex(index);
  };
  const handleAddressDeleteButton = (index) => () => {
    onChange(addresses.filter((a, i) => i !== index));
  };
  const handleSetPrimary = (index) => () => {
    onChange(
      addresses.map((a, i) => {
        if (i === index) {
          return {
            ...a,
            primary: true,
          };
        }
        return {
          ...a,
          primary: false,
        };
      })
    );
  };

  const renderInputFields = () => {
    return (
      <React.Fragment>
        <TextArea
          label="Street"
          name="street"
          value={data.street}
          onChange={onAddressChange}
        />
        <Group className="py-2">
          <Input
            label="City"
            name="city"
            value={data.city}
            onChange={onAddressChange}
          />
          <Input
            label="Postcode"
            name="postal_code"
            value={data.postal_code}
            onChange={onAddressChange}
          />
        </Group>
        <Group className="py-2">
          <div className="">
            <label
              htmlFor={"country"}
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Country
            </label>
            <CountryDropdown
              name="country"
              value={data.country}
              onChange={onCountryChange}
              classes="p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200"
              // valueType="short"
            />
          </div>
          {data.country === "United Kingdom" && <div className="">
            {/*  
            <label
              htmlFor={"county"}
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              County
            </label>
            <RegionDropdown
              country={data.country}
              value={data.province}
              onChange={onRegionChange}
              // countryValueType="short"
              classes="p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200"
            />*/}

              <SelectTwo
                label="County"
                placeholder="Please Select Type"
                field="county"
                value={data.province}
                options={CountiesList}
                onChange={onRegionChange}
              />

          </div>}
          

          <SelectTwo
            label="Type"
            placeholder="Please Select Type"
            field="name"
            value={data.name}
            options={addressTypes}
            onChange={onAddressTypeChange}
          />
        </Group>
        <div className="flex justify-between border-t border-gray-300 py-4 mt-2">
          {editIndex !== null ? (
            <Button
              colour={"subtle"}
              label={`Cancel`}
              onClick={() => {
                setData({
                  ...defaultData,
                });
                setEditIndex(null);
              }}
              className="mb-1"
            />
          ) : (
            <div></div>
          )}
          <Button
            colour={"green"}
            label={`${editIndex !== null ? "Update" : "Add"}`}
            onClick={handleAddressFormSubmit}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      {renderInputFields()}
      {addresses.length === 0 ? (
        <div>
          There are no addresses to display, create one using the form above.
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 table-auto">
          <thead>
            <tr>
              <th className="text-left">Address</th>
              <th className="text-center ">Type</th>
              <th className="text-center ">Is Primary</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {addresses.map((address, index) => {
              return (
                <tr key={index}>
                  <td className="p-2  w-3/6">
                    {address.street}, {address.city}, {address.province},{" "}
                    {address.postal_code} {address.country}
                  </td>
                  <td className="p-2 text-center w-1/6 ">{address.name}</td>
                  <td className="p-2   text-center w-1/4">
                    {address.primary ? (
                      "[Primary]"
                    ) : (
                      <Button
                        colour={"subtle"}
                        label={`Set Primary`}
                        onClick={handleSetPrimary(index)}
                        className="mx-auto"
                      />
                    )}
                  </td>
                  <td className="w-1/6 ">
                    <div className="flex gap-2 p-2  justify-end">
                      <Button
                        colour={"red"}
                        label={<TrashIcon className="h-4" />}
                        onClick={handleAddressDeleteButton(index)}
                      />
                      <Button
                        colour={"blue"}
                        label={<PencilIcon className="h-4" />}
                        onClick={handleAddressEditButton(index)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default AddressList;
