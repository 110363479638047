import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import AppConfig from "../_appConfig";
import Axios from "axios";
import query_string from "query-string";


const AuthReturnHandler = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const baseURL = `${AppConfig.api_server}/api/settings/background_tasks`;
    const urlParams = query_string.parse(window.location.search);

    Axios.post(`${baseURL}/finalise_background_auth`, {
      code: urlParams.code,
      auth: urlParams.auth,
      redirectUrl: AppConfig.applicationRoot
    }).then(res => {
      setIsLoading(false);
      setIsSuccess(true);
    }).catch(err => {
      setIsLoading(false);


    });
  }, [])

  if(isLoading){
    return (
      <div className={"py-5 text-center text-3xl"}>
        Processing Authentication...
      </div>
    )
  }

  if(isSuccess){
    return (
      <div className={"py-5 text-center text-3xl"}>
        Authentication successful, you can now close this window.
      </div>
    )
  }

  return (
    <div className={"py-5 text-center text-3xl"}>
      There was an error, please restart the process and try again.
    </div>
  )
};
export default ({children}) => {
  return (
    <Switch>
      <Route exact path="/background/auth/return" component={AuthReturnHandler}/>
      <Route path="*">
        {children}
      </Route>
    </Switch>
  );
}