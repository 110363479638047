import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../../components/NotFound";
import MatterCustomFields from "./matterFields";
import AddTemplate from "./add";
import CustomFieldsNavbar from "../../_components/CustomFieldsNavbar"
import Groups from "./groups";
import AddGroup from "./group_add";
import ShortcodesList from "./shortcodes_list";

const routePrefix = "/settings/matter_custom_fields";

export default function Index({history, match}) {
  console.log(`${routePrefix}/add`);
  return (
    <>
      <CustomFieldsNavbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/groups/edit/:id`} component={AddGroup} />
        <Route exact path={`${routePrefix}/groups/add`} component={AddGroup} />
        <Route exact path={`${routePrefix}/groups`} component={Groups} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/shortcodes_list`} component={ShortcodesList} />
        <Route exact path={`${routePrefix}`} component={MatterCustomFields} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
