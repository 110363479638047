import React, {useEffect, useRef, useState} from "react";
import SunEditor from "suneditor-react";
import ShortcodeDropdown from "./editorShortcodes/editorShortcodes_SunEditor";

const MyEditorButtons = [
  ["undo","redo"],["font","fontSize","formatBlock"],["bold","underline","italic","strike","subscript","superscript"],["removeFormat"],
  "/",
  ["fontColor","hiliteColor"],["outdent","indent"],["align","horizontalRule","list","table"],["link","image"],["fullScreen","showBlocks","codeView"]
]

const HtmlEditor = ({
  content,
  onContentUpdate,
  templates,
  options = {height: 400},
  buttonList = MyEditorButtons
}) => {
  const [pickableTemplate, setPickableTemplate] = useState([]);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const editorRef = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const handleThisDropdownItemClick = (shortcode) => {
    if (editorRef.current) {
      const currentContents = editorRef.current.getContents();
      const updatedContents = currentContents + shortcode;
      editorRef.current.setContents(updatedContents);
    }
  };

  useEffect(() => {
    if(templates !== undefined){
      templates().then(res => {
        setPickableTemplate(res.map(item => {
          return {
            name: item.name,
            html: item.content
          }
        }));
        setTemplatesLoaded(true);
      })
    }else{
      setTemplatesLoaded(true);
    }
  }, [templates]);

  const renderEditor = () => {
    if (templates !== undefined){
      if(!templatesLoaded){
        return null; // Waiting for templates to load
      }
      return (
        <SunEditor
          getSunEditorInstance={getSunEditorInstance}
          setContents={content}
          onChange={editorState => onContentUpdate(editorState)}
          setOptions={{
            ...options,
            templates: pickableTemplate,
            buttonList: buttonList.concat([ ["template"] ])
          }}
        />
      );
    }

    return (
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        setContents={content}
        onChange={editorState => onContentUpdate(editorState)}
        setOptions={{
          ...options,
          buttonList
        }}
      />
    );
  }

  return (
    <>
      <ShortcodeDropdown onChange={(e) => handleThisDropdownItemClick(e)}/>
      {renderEditor()}
    </>
  );
}

export default HtmlEditor;