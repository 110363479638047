import React from "react";
import { Route, Switch } from "react-router-dom";
import AddDebt from "./Add";
import EditDebt from "./Edit";
import DebtDash from "./Dash";
import DebtPage from "./DebtPage";

const routePrefix = "/matters/:matter_id/debtors/:debtor_id/debt";
export default function DebtPages({ match }) {
  return (
    <div>
      <Switch>
        <Route exact path={`${routePrefix}/add`} component={AddDebt} />
        <Route exact path={`${routePrefix}/:debt_id`} component={DebtPage} />
        <Route exact path={`${routePrefix}/:debt_id/edit`} component={EditDebt} />

        <Route exact path={`${routePrefix}`} component={DebtDash} />
      </Switch>
    </div>
  );
}
