import React from "react";
import PropTypes from "prop-types";

export default function Input({
  name = "",
  label = "",
  value = "",
  type = "text",
  incrementBy = "1",
  disabled = false,
  placeholder = "",
  onChange = () => {},
  className = "",
  error = false,
}) {
  return (
    <div className={className ? className : ""}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="">
        <input
          disabled={disabled}
          type={type}
          step={type !== "text" ? incrementBy : undefined}
          name={name}
          value={value}
          className={`p-2.5 shadow-sm block w-full sm:text-sm rounded-md bg-gray-100 border ${
            disabled ? "opacity-50" : ""
          } ${error ? " border-red-400" : " border-gray-300"}`}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  incrementBy: PropTypes.string,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
