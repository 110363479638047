import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { Dialog, Menu, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { DocumentSearchIcon, DocumentTextIcon, LinkIcon, PlusCircleIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
import { DataGridPro, GridRowsProp, GridColDef, GridToolbarContainer, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { MenuItem, Popover } from '@mui/material';
import { GiCheckMark, GiChecklist, GiCrossMark } from "react-icons/gi";

const AreaTasksSettingsView = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [showArchived, setShowArchived] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [area, setArea] = useState([]);
  const [stepLinks, setStepLinks] = useState([]);
  const [stepAutomation, setStepAutomation] = useState([]);
  const [stepLinksModal, setStepModal] = useState(null);
  const [automationModal, setAutomationModal] = useState(null);
  const [stepLinksModalTitle, setStepModalTitle] = useState("");
  const [stepLinksModalBack, setLinksModalBack] = useState(null);
  const [matterCollapsed, setMatterCollapsed] = useState([]);

  const [detailsTask, setDetailsTask] = useState(null);

  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [documentTemplatesTypes, setDocumentTemplatesTypes] = useState([]);
  const [documentTemplatesType, setDocumentTemplatesType] = useState(null);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
    API.settings.area_tasks
      .get(params.id)
      .then((res) => {
        setArea(res);
        setRows(res.tasks
          .filter((e) => (showArchived ? true : !e.archived))
          .sort((a, b) => a.order - b.order)
          .map((t, i) => ({
            ...t,
            id: "Task #" + (i + 1),
            action: t._id,
            // task: t,
            type: t.taskType,
            individual: t.contactDependant,
            due: t.dueDays,
            steps_count: t.steps ? t.steps.length : 0,
            _id: t._id
          })));
        setMatterCollapsed(res.tasks.map((s) => {
          return {
            id: s._id,
            collapsed: true,
          }
        }))
        
      })
        
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError(err.response.data.message);
        } else {
          setGlobalError(
            "There was an unexpected error while trying to retrieve the area tasks from the server."
          );
        }
      })
      .finally(() => {
        setIsInitialLoading(false);
      });

      API.settings.doc_types().list().then((types) => {
        setDocumentTemplatesTypes(types);
        API.settings.doc_templates().list().then((res) => {
          setDocumentTemplates(res);
        });
      });

  };

  useEffect(() => {
    getData();
  }, []);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  if (isInitialLoading) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  const saveArea = () => {
    API.settings.area_tasks
      .update(params.id, area)
      .then((res) => {
        if (res.success) {
          toast.success(`Updated Area successfully`);
          getData();
        } else {
          toast.error(`Error updating Area`);
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError(err.response.data.message);
        } else {
          setGlobalError(
            "There was an unexpected error while trying to update the area."
          );
        }
      });
  };

  const areaAdd = (type, parentInd = null) => {
    let tasks = area.tasks;

    switch (type) {
      case "step":
        let tempTask = tasks.filter(t => t._id === parentInd);

        if(tempTask[0]){
          tempTask[0].steps.push({});
          setArea({
            ...area,
            tasks,
          });
        }

        break;
      case "task":
        area.tasks.push({ steps: [] });
        setArea({
          ...area,
          tasks,
        });
        break;
    }
  };

  const areaRemove = (archived, type, parentInd, stepInd = null) => {
    let tasks = area.tasks;
    switch (type) {
      case "step":

        let tempTask = tasks.filter(t => t._id === parentInd);

        if(tempTask[0]){

          tempTask[0].steps[stepInd].archived = !archived;
          setArea({
            ...area,
            tasks,
          });

        }
        break;
      case "task":
        tasks[parentInd].archived = !archived;
        setArea({
          ...area,
          tasks,
        });
        break;
    }
  };

  let taskTypes = ["Task", "YesNoOption"];
  let stepTypes = [
    "PlainStep",
    "UploadDocument",
    "GenerateDocument",
    // "YesNoOption",
    "SendEmail",
  ];

  const openLinksModal = (step) => {
    setLinksModalBack(detailsTask);
    setDetailsTask(null);
    setStepModal(step._id);
    setStepLinks(step.resourceLinks);
    setStepModalTitle(step.title)
  }

  const openAutomationModal = (task) => {
    setAutomationModal(task._id);
    setStepAutomation(task.automations)
  }

  const renderLinksModal = () => {

    const handleClose = () => {
      setStepModal(null);
      setDetailsTask(stepLinksModalBack);
      setLinksModalBack(null);
    };

    const handleModalSubmit = () => {
      API.settings.area_tasks.updateStepLinks(params.id,stepLinksModal,links).then((res) => {
        if(res.success) {
          setStepModal(null);
          getData();
          toast.success("Step Links updated!")
          setDetailsTask(stepLinksModalBack);
          setLinksModalBack(null);
        }
      })
    };

    let links = stepLinks ? stepLinks : [];

    return (
      <Transition.Root show={stepLinksModal !== null } as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Step ({stepLinksModalTitle}) Links
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`text-lg`}>
                        <table
                          className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Link
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                URL
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                <button
                                      type="button"
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      onClick={() => {
                                        links.push({title: "", url: ""})
                                        setStepLinks([].concat(links))
                                      }}
                                    >
                                  <PlusCircleIcon className="h-4 inline-block"/>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-300">
                            {links.length > 0 ? (
                              links.map((item, index) => (
                                <tr
                                  className={`hover:bg-gray-100 focus:bg-gray-100`}
                                  key={index}
                                >
                                  <td className="px-4 py-4">
                                    <input
                                      value={
                                        item.title
                                      }
                                      onChange={(e) => {
                                        links[index].title = e.target.value;
                                        setStepLinks([].concat(links))
                                      }}
                                      type="text"
                                      name="link"
                                      id="link"
                                      className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                    />
                                  </td>
                                  <td className="px-4 py-4">
                                    <input
                                      value={
                                        item.url
                                      }
                                      onChange={(e) => {
                                        links[index].url = e.target.value;
                                        setStepLinks([].concat(links))
                                      }}
                                      type="text"
                                      name="url"
                                      id="url"
                                      className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                    />
                                  </td>
                                  <td className="px-4 py-4">
                                    <button
                                      type="button"
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      onClick={() => {
                                        links.splice(index, 1);
                                        setStepLinks([].concat(links))
                                      }}
                                    >
                                    <TrashIcon className="h-4 inline-block"/>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className={`text-black `}>
                                <td colSpan={5} className="px-4 py-4">
                                  No Links...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleModalSubmit}
                  >
                   Save Links
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Back
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  const renderAutomationModal = () => {

    const handleClose = () => {
      setAutomationModal(null);
    };

    const handleModalSubmit = () => {
      API.settings.area_tasks.updateTaskAutomations(params.id,automationModal,stepAutomation).then((res) => {
        if(res.success) {
          setAutomationModal(null);
          getData();
          toast.success("Automations updated!")
        }
      })
    };
    

    let task = area.tasks.filter(t => t._id === automationModal)   

    let automations = stepAutomation ? stepAutomation : [];

    let matter_feilds = [
      {value: "display_number", text: "Display Number", type: "alphabetic"},
      {value: "description", text: "Description", type: "alphabetic"},
      {value: "paymentTerms", text: "Payment Terms", type: "numeric"},
      {value: "practice_area", text: "Practice Area", type: "alphabetic"},
      {value: "billing_method", text: "Billing Method", type: "alphabetic"},
      {value: "billing", text: "Billing", type: "alphabetic"},
      {value: "responsible_attorney", text: "Fee Earner", type: "alphabetic"},
      {value: "originating_attorney", text: "Supervisor", type: "alphabetic"},
      {value: "interest_rate", text: "Interest Rate", type: "numeric"},
      {value: "feeRates", text: "Fee Rates", type: "alphabetic"}, //??
      {value: "debtors", text: "Has Debtors", type: "alphabetic"},
    ];

    let contact_feilds = [
      {value: "name", text: "Company Name / Ind. Name", type: "alphabetic"},
      {value: "registrationNo", text: "Registration Number", type: "alphabetic"},
      {value: "clientRef", text: "Client Reference", type: "alphabetic"},
      {value: "addresses", text: "Primary Address", type: "alphabetic"},
      {value: "emails", text: "Primary Email Address", type: "alphabetic"},
      {value: "phone", text: "Primary Phone Number", type: "alphabetic"},
      {value: "website", text: "Primary Website", type: "alphabetic"},
    ];

    return (
      <Transition.Root show={automationModal !== null } as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
              {task[0] && <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Automations for Task: {task[0].title}
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`text-lg`}>
                        <table
                          className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Task Step
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Automation Query
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                <button
                                      type="button"
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      onClick={() => {
                                        automations.push({step: "", query: [],  actions: []})
                                        setStepAutomation([].concat(automations))
                                      }}
                                    >
                                  <PlusCircleIcon className="h-4 inline-block"/>
                                </button>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-300">
                            {automations.length > 0 ? (
                              automations.map((item, index) => 
                              {

                                if(!automations[index].actions){
                                  automations[index].actions = [
                                    {action: "task_completed"}
                                  ];
                                }

                                return(
                                <>
                                <tr
                                  className={`hover:bg-gray-100 focus:bg-gray-100`}
                                  key={`autos_${index}`}
                                >
                                  <td className="px-4 py-4">

                                    <select
                                      id="step"
                                      name="step"
                                      class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                      onChange={(e) => {
                                        automations[index].step = e.target.value;
                                        setStepAutomation([].concat(automations))
                                      }}
                                    >
                                      <option disabled selected={!item.step}>Select Step</option>
                                      {task[0].steps.map((t, ti) => {
                                        return (
                                          <option key={`steps_${ti}`} selected={item.step == t._id} value={t._id}>
                                            {t.title}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td className="px-4 py-4">
                                    {item.step && <>
                                    {automations[index].query.length > 0 ? automations[index].query.map((a, ind) => {

                                      let feild_map = [];
                                      switch(automations[index].query[ind].type){
                                        case "matter":
                                          feild_map = matter_feilds;
                                        break;
                                        case "contact":
                                          feild_map = contact_feilds;
                                        break;
                                      }

                                      return (<span>

                                        {ind > 0 && <select 
                                         onChange={(e) => {
                                          automations[index].query[ind].opperand = e.target.value
                                          setStepAutomation([].concat(automations))
                                        }}
                                        className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                          <option selected={a.operand === "AND"} value="AND">AND</option>
                                          <option selected={a.operand === "OR"} value="OR">OR</option>
                                        </select>}

                                        <select 
                                        onChange={(e) => {
                                          automations[index].query[ind].type = e.target.value
                                          setStepAutomation([].concat(automations))
                                        }}
                                        className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                          <option disabled selected={!a.type}>Select a Type</option>
                                          <option selected={a.type === "matter"} value="matter">Matter</option>
                                          <option selected={a.type === "contact"} value="contact">Contact</option>
                                          <option selected={a.type === "debtor"} value="debtor">Debtor</option>
                                        </select>

                                        <select
                                        onChange={(e) => {
                                          automations[index].query[ind].field = e.target.value
                                          setStepAutomation([].concat(automations))
                                        }}
                                        className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                          <option disabled selected={!a.field}>Select a Field</option>
                                          {feild_map.map((f, fi) => {
                                            return (
                                              <option key={`field_${fi}`} selected={automations[index].query[ind].field == f.value} value={f.value}>
                                                {f.text}
                                              </option>
                                            );
                                          })}
                                        </select>

                                        <select 
                                        onChange={(e) => {
                                          automations[index].query[ind].search = e.target.value
                                          setStepAutomation([].concat(automations))
                                        }}
                                        className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                          <option disabled selected={!a.search}>Select a Search</option>
                                          <option selected={a.search === "exists"} value="exists">Exists</option>
                                          <option selected={a.search === "lessthan"} value="lessthan">&lt;</option>
                                          <option selected={a.search === "lessthanequal"} value="lessthanequal">&lt;=</option>
                                          <option selected={a.search === "equal"} value="equal">=</option>
                                          <option selected={a.search === "greaterthanequal"} value="greaterthanequal">&gt;=</option>
                                          <option selected={a.search === "greaterthan"} value="greaterthan">&gt;</option>
                                        </select>
                                        {(a.search !== "exists" && a.search !== "") &&
                                        <input 
                                        onChange={(e) => {
                                          automations[index].query[ind].search_extra = e.target.value
                                          setStepAutomation([].concat(automations))
                                        }}
                                        type="number" className="inline-block rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm" value={a.search_extra}/>}
                                        <button
                                          type="button"
                                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                          onClick={() => {
                                            automations[index].query.splice(ind, 1);
                                            setStepAutomation([].concat(automations))
                                          }}
                                        >
                                        -
                                        </button>
                                      </span>)
                                    }) : "No Query"}
                                      <button 
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                          automations[index].query.push({
                                            operand: automations[index].query.length > 0 ? "AND" : "", type: "", field: "", search: ""
                                          })
                                          setStepAutomation([].concat(automations))
                                        }}
                                      >+</button>
                                    </>}
                                    
                                    
                                  </td>
                                  <td className="px-4 py-4">
                                    <button
                                      type="button"
                                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                      onClick={() => {
                                        automations.splice(index, 1);
                                        setStepAutomation([].concat(automations))
                                      }}
                                    >
                                    <TrashIcon className="h-4 inline-block"/>
                                    </button>
                                  </td>
                                </tr>
                                <tr
                                  className={`hover:bg-gray-100 focus:bg-gray-100`}
                                  key={`autos_${index}`}
                                >
                                  <td className="px-4 py-4">

                                    Actions for Step 
                                  </td>
                                  <td className="px-4 py-4">
                                    {item.step && <>
                                    {automations[index].actions.length > 0 ? automations[index].actions.map((a, ind) => {

                                      let feild_map = [
                                        {value: "task_completed", text: "Mark Task as Completed"},
                                        {value: "matter_milestone_update", text: "Matter Milestone Update"},
                                      ];

                                      let milestones_map = [
                                        {value: "prepared", text: "Claim Prepared"},
                                        {value: "sent", text: "Claim Sent to Court"},
                                        {value: "issue", text: "Date of Issue"},
                                        {value: "judgement", text: "Date of Judgment"},
                                        {value: "judgement_received", text: "Judgment Received On"},
                                        {value: "closed", text: "Case Closed"},
                                      ];

                                      return (<span style={{display: "block"}}>
                                        <select
                                          onChange={(e) => {
                                            automations[index].actions[ind].action = e.target.value
                                            setStepAutomation([].concat(automations))
                                          }}
                                          className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                            <option disabled selected={!a.field}>Select action</option>
                                            {feild_map.map((f, fi) => {
                                              return (
                                                <option key={`action_${fi}`} selected={automations[index].actions[ind].action == f.value} value={f.value}>
                                                  {f.text}
                                                </option>
                                              );
                                            })}
                                        </select>

                                        {automations[index].actions[ind].action === "matter_milestone_update" && 
                                        
                                          <select
                                            onChange={(e) => {
                                              automations[index].actions[ind].option = e.target.value
                                              setStepAutomation([].concat(automations))
                                            }}
                                            className="inline-block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm">
                                              <option disabled selected={!a.field}>Select Milestone</option>
                                              {milestones_map.map((f, fi) => {
                                                return (
                                                  <option key={`milestone_${fi}`} selected={automations[index].actions[ind].option == f.value} value={f.value}>
                                                    {f.text}
                                                  </option>
                                                );
                                              })}
                                          </select>

                                        }

                                        <button
                                          type="button"
                                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                          onClick={() => {
                                            automations[index].actions.splice(ind, 1);
                                            setStepAutomation([].concat(automations))
                                          }}
                                        >
                                        -
                                        </button>
                                      </span>)
                                    }) : "No Actions selected, action will Mark Completed only!"}
                                      <button 
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => {
                                          automations[index].actions.push({
                                            type: "", field: "", search: ""
                                          })
                                          setStepAutomation([].concat(automations))
                                        }}
                                      >+</button>
                                    </>}
                                    
                                    
                                  </td>
                                  <td className="px-4 py-4">
                                  </td>
                                </tr>
                                </>
                                );
                              })
                              
                            ) : (
                              <tr className={`text-black `}>
                                <td colSpan={5} className="px-4 py-4">
                                  No Automations...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleModalSubmit}
                  >
                   Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  const renderTaskModal = () => {

    const handleClose = () => {
      setDetailsTask(null);
    };

    let selected = area.tasks.filter(a => a._id === detailsTask)

    let task = selected[0] ? selected[0] : null;

    const handleChange = (e) => {
      let tempTasks = area.tasks;
      let tempT = tempTasks.filter(t => t._id === task._id);

      if(tempT[0]){
        tempT[0][e.target.name] =
          e.target.value;
        setArea({
          ...area,
          tempTasks,
        });
      }
    }
    const handleStepChange = (e, ind) => {

      let tempTasks = area.tasks;
      let tempT = tempTasks.filter(t => t._id === task._id);

      if(tempT[0]){
        tempT[0].steps[ind][e.target.name] =
          e.target.value;
        setArea({
          ...area,
          tempTasks,
        });
      }
    }

    let rows = task ? task.steps
    .filter((e) => (showArchived ? true : !e.archived))
    .map((step, index) => ({
      id: index,
      ...step,
    })) : [];

    const handleRowOrderChange = (params) => {
      const { oldIndex, targetIndex } = params;
      
      const newRows = [...rows];
      const [movedRow] = newRows.splice(oldIndex, 1);
      newRows.splice(targetIndex, 0, movedRow);
    
      const updatedSteps = newRows.map((row, index) => ({ ...row, order: index + 1 }));
    
      const updatedTasks = area.tasks.map((t) => {
        if (t._id === detailsTask) {
          return {
            ...t,
            steps: updatedSteps,
          };
        }
        return t;
      });
    
      setArea({ ...area, tasks: updatedTasks });
    };
      

    const columns = [
      {
        field: 'title',
        headerName: 'Title',
        flex: 1,
        headerClassName: 'center-header', // Center-align header
        sortable: false, // Disable sorting
        renderCell: (params) => (
          <>
            {params.row.archived && "(Archived)"}
            <input
              disabled={params.row.archived}
              onChange={(e) => {handleStepChange(e, params.row.id)}}
              value={params.row.title}
              type="text"
              name="title"
              id="step_title"
              className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
            />
          </>
        ),
      },
      {
        field: 'dueDays',
        headerName: 'Step Due Days',
        flex: 1,
        type: 'number',
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <input
            type="number"
            name="dueDays"
            value={params.row.dueDays}
            onChange={(e) => handleStepChange(e, params.row.id)}
            disabled={params.row.archived}
            className="block flex-1 rounded-none border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
          />
        ),
      },
      {
        field: 'sendAccounts',
        headerName: 'Send to Accounts',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <input
            type="checkbox"
            checked={params.row.sendAccounts}
            onChange={(e) => {

              let tempTasks = area.tasks;
              let tempT = tempTasks.filter(t => t._id === task._id);
        
              if(tempT[0]){
                tempT[0].steps[params.row.id].sendAccounts =
                  !params.row.sendAccounts;
                setArea({
                  ...area,
                  tempTasks,
                });
              }
            }}
            id="sendAccounts"
            name="sendAccounts"
            disabled={params.row.archived}
            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
          />
        ),
      },
      {
        field: 'selectCourt',
        headerName: 'Select Court',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <input
            type="checkbox"
            checked={params.row.selectCourt}
            
            onChange={(e) => {

              let tempTasks = area.tasks;
              let tempT = tempTasks.filter(t => t._id === task._id);
        
              if(tempT[0]){
                tempT[0].steps[params.row.id].selectCourt =
                  !params.row.selectCourt;
                setArea({
                  ...area,
                  tempTasks,
                });
              }
            }}
            id="selectCourt"
            name="selectCourt"
            // onChange={() => handleCheckboxChange(params.rowIndex, 'selectCourt')}
            disabled={params.row.archived}
            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
          />
        ),
      },
      {
        field: 'contactDependant',
        headerName: 'Individual',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <input
            type="checkbox"
            id="contactDependant"
            name="contactDependant" 
            checked={params.row.contactDependant}
            onChange={(e) => {

              let tempTasks = area.tasks;
              let tempT = tempTasks.filter(t => t._id === task._id);
        
              if(tempT[0]){
                tempT[0].steps[params.row.id].contactDependant =
                  !params.row.contactDependant;
                setArea({
                  ...area,
                  tempTasks,
                });
              }
            }}
            disabled={params.row.archived}
            className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
          />
        ),
      },
      {
        field: 'stepType',
        headerName: 'Type',
        flex: 1,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => <StepTypeCell params={params} />,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        width: 150,
        headerClassName: 'center-header',
        sortable: false,
        renderCell: (params) => (
          <>
           <Button
              colour={"red"}
              label={
                <TrashIcon className="h-4" />
              }
              onClick={() =>
                areaRemove(
                  params.row.archived,
                  "step", task._id, params.row.id
                )
              }
              type="submit"
              className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            />
            <button
            onClick={() =>
              openLinksModal(params.row)
            }
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          ><LinkIcon className="h-4" /></button>
          </>
        )
      },
    ];

    const StepTypeCell = ({ params }) => {
      const [anchorEl, setAnchorEl] = React.useState(null);
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const defaultDoc = documentTemplates.filter(e => e._id === params.row.defaultTemplate);
    
      return (
        <div>
          <select
            value={params.row.stepType}
            name="stepType"
            onChange={(e) => handleStepChange(e, params.row.id)}
            disabled={params.row.archived}
            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
          >
            {stepTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
    
          {(params.row.stepType === 'UploadDocument' || params.row.stepType === 'GenerateDocument') && (
            <>
              {documentTemplatesType ? (
                <div className="flex px-3">
                  <Button
                    aria-describedby={open ? 'template-popover' : undefined}
                    onClick={handleClick}
                    variant="outlined"
                    icon={DocumentTextIcon}
                    label={`${documentTemplatesType} Template:`}
                  />
                  <Popover
                    id="template-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    label="Select"
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <div className="py-1">
                      {documentTemplatesFiltered.map((template) => (
                        <MenuItem
                          key={template._id}
                          onClick={() => {
                            let e = {
                              target: {
                                name: 'defaultTemplate',
                                value: template._id,
                              },
                            };
                            handleStepChange(e, params.row.id);
                            handleClose();
                          }}
                        >
                          {template.name}
                        </MenuItem>
                      ))}
                    </div>
                  </Popover>
                </div>
              ) : (
                'Select Document Category above first to Select from list of Templates!'
              )}
            </>
          )}
    
          <div>{params.row.defaultTemplate && defaultDoc && `Default Template: ${defaultDoc[0]?.name}`}</div>
        </div>
      );
    };
    

    return (
      <Transition.Root show={detailsTask !== null } as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Task Details: {task && task.title}
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`text-lg`}>
                      {task && <>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Order
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={task.order}
                                      onChange={handleChange}
                                      type="number"
                                      name="order"
                                      id="order"
                                      className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                    />
                                  </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                  <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                    Task Type <QuestionMarkCircleIcon className="h-4 inline-block" />
                                  <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                    Types:
                                    <ul>
                                      <li>Task: Ordinary Task with its own steps</li>
                                      <li>YesNoOption: Task with Yes/No option which will show the task and steps of Yes or No result</li>
                                    </ul>
                                  </span>
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    <select
                                      id="taskType"
                                      name="taskType"
                                      class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                      onChange={handleChange}
                                    >
                                      {taskTypes.map((t) => {
                                        return (
                                          <option selected={task.taskType == t}>
                                            {t}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </dd>
                                </div>
                                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none ">
                                  <dt className="text-sm font-medium text-gray-500 has-tooltip">
                                    Individual <QuestionMarkCircleIcon className="h-4 inline-block" />
                                    <span class="tooltip rounded shadow-lg p-1 bg-gray-100 -mt-8">
                                      Individual: Show step for Individual?
                                    </span>
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <input
                                    checked={
                                      task.contactDependant
                                    }
                                    onChange={(e) => {
                                      let tempTasks = area.tasks;
                                      let tempT = tempTasks.filter(t => t._id === task._id);

                                      if(tempT[0]){
                                        tempT[0][e.target.name] =
                                          !task.contactDependant
                                        setArea({
                                          ...area,
                                          tempTasks,
                                        });
                                      }
                                    }}
                                    id="contactDependant"
                                    name="contactDependant"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                  />
                                  </dd>
                                </div>
                                {task.taskType == "YesNoOption" ? (
                                  <>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Tasks
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <div class="mt-8 flex flex-col">
                                          <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table class="min-w-full divide-y divide-gray-300">
                                                  <thead class="bg-gray-50">
                                                    <tr>
                                                      <th
                                                        scope="col"
                                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                                      >
                                                        Option
                                                      </th>
                                                      <th
                                                        scope="col"
                                                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                      >
                                                        Task
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody class="divide-y divide-gray-200 bg-white">
                                                    {["Yes", "No"].map((o) => {
                                                      return (
                                                        <tr>
                                                          <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {o}
                                                          </td>
                                                          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <select
                                                              id="YesNoOption"
                                                              name={`YesNoOption_${o}`}
                                                              class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
                                                              onChange={handleChange}
                                                            >
                                                              {area.tasks
                                                                .filter((e) =>
                                                                  showArchived
                                                                    ? true
                                                                    : !e.archived &&
                                                                      e._id !==
                                                                        task._id
                                                                )
                                                                .sort((a, b) => (a.order > b.order ? 1 : -1))
                                                                .map((t) => {
                                                                  return (
                                                                    <option value={t._id} selected={t._id == task[`YesNoOption_${o}`]}>
                                                                      ({t.order}) {t.title}
                                                                    </option>
                                                                  );
                                                                })}
                                                            </select>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </dd>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Task Due Days
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <input
                                          value={task.dueDays}
                                          onChange={handleChange}
                                          type="number"
                                          name="dueDays"
                                          id="task_dueDays"
                                          className="block flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                                        />
                                      </dd>
                                    </div>
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6 border-none">
                                      <dt className="text-sm font-medium text-gray-500">
                                        Steps (
                                        {
                                          task.steps.filter((e) =>
                                            showArchived ? true : !e.archived
                                          ).length
                                        }
                                        )
                                        {!task.archived && (
                                          <button
                                            onClick={() =>
                                              // areaAdd("step", index)
                                              areaAdd("step", task._id)
                                            }
                                            type="submit"
                                            className="inline-block justify-center rounded-md border border-transparent ml-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                          >
                                            Add
                                          </button>
                                        )}

                                            <Menu
                                              as="div"
                                              className="relative inline-block text-left w-full mt-3"
                                            >
                                              <Menu.Button className="flex justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                                                <DocumentSearchIcon
                                                  className="-ml-1 mr-2 h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                                Document Category{documentTemplatesType ? " : "+documentTemplatesType : ""}
                                              </Menu.Button>

                                              <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                              >
                                                <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                  <div className="py-1">
                                                    {documentTemplatesTypes.map((template) => {
                                                      return <Menu.Item>
                                                      <div
                                                        onClick={() => {
                                                          setDocumentTemplatesType(template.name)}}
                                                        className={
                                                          "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                                        }
                                                      >
                                                        {template.name} ({template.shortcode})
                                                      </div>
                                                    </Menu.Item>
                                                    })}
                                                  </div>
                                                </Menu.Items>
                                              </Transition>
                                            </Menu>
                                      </dt>
                                      </div>
                                    <div className="mb-2">
                                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                        <div class="mt-8 flex flex-col">
                                          <div class="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                                            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                              <div class=" shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                                                <DataGridPro
                                                  rows={rows}
                                                  columns={columns}
                                                  disableColumnMenu
                                                  onProcessRowUpdate={(newRow) => {
                                                    setRows((prevRows) =>
                                                      prevRows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row))
                                                    );
                                                    handleStepChange(newRow);
                                                    return newRow;
                                                  }}
                                                  rowHeight={120}
                                                  rowReordering
                                                  onRowOrderChange={handleRowOrderChange}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                      </dd>
                                    </div>
                                  </>
                                )}
                                </>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-orange-600 text-base font-medium text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      saveArea();
                      handleClose();
                    }}
                  >
                   Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  // let documentTemplatesFiltered = documentTemplates.filter(e => e.recipient && e.recipient.name == documentTemplatesType);

  let documentTemplatesFiltered = documentTemplates.filter(e => 
    e.recipient && 
    Array.isArray(e.recipient) &&
    e.recipient.some(recipient => recipient.label === documentTemplatesType)
  );

  /*
  let rows: GridRowsProp = area.tasks
  .filter((e) => (showArchived ? true : !e.archived)).sort((a,b) => a.order > b.order ? 1 : -1).map((t, i) => {
    return {
      id: "Task #"+(i+1), 
      title: t.title, 
      action: t._id, 
      task: t, 
      order: t.order,
      type: t.taskType,
      individual: t.contactDependant,
      due: t.dueDays,
      steps: t.steps ? t.steps.length : 0,
    }
  });*/

  const updateRowPosition = async (oldIndex, targetIndex, rows) => {
    // Swap the rows in the array
    const newRows = [...rows];
    const [movedRow] = newRows.splice(oldIndex, 1);
    newRows.splice(targetIndex, 0, movedRow);
  
    // Update the order property of each row based on their position in the array
    newRows.forEach((row, index) => {
      row.order = index + 1; // Update the order starting from 1
    });
  
    return newRows;
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    setRows(newRows);
    console.log("🚀 ~ handleRowOrderChange ~ newRows:", newRows)
    API.settings.area_tasks.update(area.area._id, {tasks: newRows}).then(() => {
      setLoading(false);
      toast.success("Area Task Re-ordered successfully");
    })
    
  };
    

  const columns: GridColDef[] = [
    { headerName: "Order", field: "order", width: 150, pinnable: false }, 
    { headerName: "Task", field: "id", width: 150, pinnable: false }, 
    { headerName: "Steps", field: "steps_count", width: 150, pinnable: false }, 
    { headerName: "Title", field: "title", width: 450, pinnable: false }, 
    { headerName: "Type", field: "type", width: 150, pinnable: false }, 
    { headerName: "Individual", field: "individual", width: 150, pinnable: false, renderCell: (params) => (params.row.individual) ? <GiCheckMark /> : <GiCrossMark />}, 
    { headerName: "Due Days", field: "due", width: 150, pinnable: false }, 
    {
      field: 'actions',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        const id = params.row.action;
        const task = params.row.task;
        const steps = params.row.steps;
        return (
          <>
          <button
                onClick={() =>
                  setDetailsTask(id)
                }
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
              {"View Details"}
            </button>
            {steps > 0 &&
          <button
              onClick={() =>
                openAutomationModal(task)
              }
              type="button"
              className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
            ><GiChecklist className="h-4" /></button>}
          </>
          
        );
      },
    }
  ];

  return (
    <Segment>
      {renderLinksModal()}
      {renderAutomationModal()}
      {renderTaskModal()}
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Area of Law, Task Builder
              </h3>
              <p className="mt-1 text-sm text-gray-600">Build out the task's workflow for the area of law selected.</p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="area"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        onChange={(e) => {
                          setArea({
                            ...area,
                            area: {
                              ...area.area,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        value={area && area.area && area.area.area}
                        type="text"
                        name="area"
                        id="area"
                        className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="area"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Practice Area
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        onChange={(e) => {
                          setArea({
                            ...area,
                            area: {
                              ...area.area,
                              [e.target.name]: e.target.value,
                            },
                          });
                        }}
                        disabled
                        value={area && area.area && area.area.practiceArea}
                        type="text"
                        name="practiceArea"
                        id="practiceArea"
                        className="block w-full flex-1 rounded-none rounded-r-md border-gray-300 focus:border-primary focus:ring-primary sm:text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-2 md:gap-6">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-1 gap-6">
                  <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">

                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Tasks (
                        {area &&
                          area.tasks.filter((e) =>
                            showArchived ? true : !e.archived
                          ).length}
                        )
                        <button
                          onClick={() => areaAdd("task")}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent ml-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Add Task
                        </button>
                        <Switch.Group
                          as="div"
                          className="flex items-center float-right"
                        >
                          <Switch
                            checked={showArchived}
                            onChange={setShowArchived}
                            className={classNames(
                              showArchived ? "bg-primary" : "bg-gray-200",
                              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                showArchived
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              Show Archived
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </h3>

                      <DataGridPro 
                        rows={rows} 
                        columns={columns} 
                        initialState={{ pinnedColumns: { left: ['order'], right: ['actions'] } }}
                        rowReordering
                        loading={loading}
                        onRowOrderChange={handleRowOrderChange}
                      />

                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                        <button
                          onClick={() => areaAdd("task")}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent mr-3 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        >
                          Add Task
                        </button>
                        <button
                          onClick={saveArea}
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          Save
                        </button>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Segment>
  );

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Area Tasks
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Areas with Tasks and Steps within
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Area
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {area &&
                        area.length > 0 &&
                        area.map((a, index) => (
                          <tr key={"area_" + index}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                              {a.area}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href={"/settings/area_tasks/" + a._id}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                View<span className="sr-only">, {a.area}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
export default AreaTasksSettingsView;
