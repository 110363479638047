import React from "react";
import PropTypes from "prop-types";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export default function PageScroller({
  totalPages = 0,
  pageNum = 0,
  updatePageNumber = () => {},
}) {
  return totalPages !== undefined &&
    pageNum !== undefined &&
    updatePageNumber !== undefined ? (
    <div className="flex-1 flex items-center">
      <div className="ml-auto">
        <nav
          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
          aria-label="Pagination"
        >
          <div
            onClick={() => {
              if (pageNum > 1) {
                updatePageNumber(pageNum - 1);
              }
            }}
            className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md bg-gray-200 text-sm font-medium text-primary hover:bg-gray-300"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          {totalPages && totalPages <= 10 ? (
            Array.from(Array(totalPages).keys()).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => updatePageNumber(item + 1)}
                  aria-current="page"
                  className={`z-10 cursor-pointer ${
                    item + 1 === pageNum ? "bg-gray-300" : "bg-gray-200"
                  } text-primary relative inline-flex items-center px-4 py-2 text-sm font-medium hover:bg-gray-300`}
                >
                  {item + 1}
                </div>
              );
            })
          ) : totalPages && totalPages > 10 ? (
            <>
              {pageNum > 1 && (
                <>
                  <div
                    onClick={() => updatePageNumber(1)}
                    aria-current="page"
                    className={`z-10 cursor-pointer ${
                      1 === pageNum ? "bg-gray-300" : "bg-gray-200"
                    } text-primary relative inline-flex items-center px-4 py-2 text-sm font-medium hover:bg-gray-300`}
                  >
                    {1}
                  </div>
                  <span className="relative inline-flex items-center px-4 py-2 bg-gray-200 text-sm font-medium text-primary hover:bg-gray-300">
                    ...
                  </span>
                </>
              )}
              {Array.from(Array(totalPages).keys())
                .slice(
                  pageNum < totalPages - 5 ? pageNum - 1 : totalPages - 6,
                  pageNum < totalPages - 5 ? pageNum + 5 : totalPages
                )
                .map((item) => {
                  return (
                    <div
                      onClick={() => updatePageNumber(item + 1)}
                      aria-current="page"
                      className={`z-10 cursor-pointer ${
                        item + 1 === pageNum ? "bg-gray-300" : "bg-gray-200"
                      } text-primary relative inline-flex items-center px-4 py-2  text-sm font-medium hover:bg-gray-300`}
                    >
                      {item + 1}
                    </div>
                  );
                })}
              {pageNum < totalPages - 5 && (
                <>
                  <span className="relative inline-flex items-center px-4 py-2  bg-gray-200 text-sm font-medium text-primary hover:bg-gray-300">
                    ...
                  </span>
                  <div
                    onClick={() => updatePageNumber(totalPages)}
                    aria-current="page"
                    className={`z-10 cursor-pointer ${
                      totalPages === pageNum ? "bg-gray-300" : "bg-gray-200"
                    } text-primary relative inline-flex items-center px-4 py-2 text-sm font-medium hover:bg-gray-300`}
                  >
                    {totalPages}
                  </div>
                </>
              )}
            </>
          ) : (
            <></>
          )}

          <div
            onClick={() => {
              if (pageNum < totalPages) {
                updatePageNumber(pageNum + 1);
              }
            }}
            className="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md bg-gray-200 text-sm font-medium text-primary hover:bg-gray-300"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </div>
        </nav>
      </div>
    </div>
  ) : (
    <></>
  );
}

PageScroller.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pageNum: PropTypes.number.isRequired,
  updatePageNumber: PropTypes.func.isRequired,
};
