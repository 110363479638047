import React, {useEffect, useState} from "react";
import Segment from "../../../../components/Segment";
import Table from "../../../settings/courts/_components/table";
import Loader from "../../../../components/Loader";
import API from "../../../../api";
import FeesNavbar from "../../../settings/_components/FeesNavbar";
import { useParams } from "react-router-dom";

const CourtsSettings = ({history, match}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [data, setData] = useState([]);

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
      API.settings.courts.get(params.org_id).then(res => {
        setData(res);
    }).catch(err => {
      if(err.response !== undefined){
        setGlobalError(err.response.data.message);
      } else {
        setGlobalError("There was an unexpected error while trying to retrieve the base rates from the server.");
      }
    }).finally(() => {
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onCreate = data => {
    return API.settings.courts.create({...data, org_id: params.org_id}).finally(() => {
      getData();
    });
  };
  const onUpdate = (rateId, data) => {
    return API.settings.courts.update(rateId, data).finally(() => {
      getData();
    });
  };
  const onDelete = rateId => {
    return API.settings.courts.delete(rateId).then(() => {
      getData();
    })
  };

  if(isInitialLoading){
    return (
      <Loader>Retrieving data from server...</Loader>
    );
  }
  if(globalError){
    return (
      <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>
    );
  }

  return (
    <React.Fragment>
      <FeesNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <div className="sm:grid grid-cols-1">
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Courts</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          <Table data={data} onCreate={onCreate} onUpdate={onUpdate} onDelete={onDelete} />
        </Segment>
      </div>
    </React.Fragment>
  );
}
export default CourtsSettings;