import React from "react";

const Loader = props => {
    return (
        <div style={{padding: "1rem", textAlign: "center"}}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <span style={{display: "block"}}>{props.children}</span>
        </div>
    );
};
export default Loader;