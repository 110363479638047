import React, {useEffect, useState} from "react";
import Segment from "../../../../components/Segment";
import BaseRatesTable from "../../../settings/base_rates/_components/baseRatesTable";
import CompensationTable from "../../../settings/base_rates/_components/compensationTable";
import Loader from "../../../../components/Loader";
import API from "../../../../api";
import FeesNavbar from "../../../settings/_components/FeesNavbar";
import { useParams } from "react-router-dom";

const BaseRatesSettings = ({history, match}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [baseRates, setBaseRates] = useState([]);
  const [compensationRates, setCompensationRates] = useState([]);
  const [latePaymentsBaseRates, setLatePaymentsBaseRates] = useState([]);
  const [statutoryRate, setStatutoryRate] = useState([]);

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
    Promise.all([
      API.settings.base_rates.normal.get(params.org_id),
      API.settings.base_rates.late_payments.get(params.org_id),
      API.settings.base_rates.statutory.get(params.org_id),
      API.settings.base_rates.compensation.get(params.org_id)
    ]).then(res => {
      setBaseRates(res[0]);
      setLatePaymentsBaseRates(res[1]);
      setStatutoryRate(res[2]);
      setCompensationRates(res[3]);
    }).catch(err => {
      if(err.response !== undefined){
        setGlobalError(err.response.data.message);
      } else {
        setGlobalError("There was an unexpected error while trying to retrieve the base rates from the server.");
      }
    }).finally(() => {
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const onRateCreate = data => {
    return API.settings.base_rates.normal.create({...data, org_id: params.org_id}).finally(() => {
      getData();
    });
  };
  const onRateUpdate = (rateId, data) => {
    return API.settings.base_rates.normal.update(rateId, data).finally(() => {
      getData();
    });
  };
  const onRateDelete = rateId => {
    return API.settings.base_rates.normal.delete(rateId).then(() => {
      getData();
    })
  };

  const onStatutoryRateCreate = data => {
    return API.settings.base_rates.statutory.create({...data, org_id: params.org_id}).finally(() => {
      getData();
    });
  };
  const onStatutoryRateUpdate = (rateId, data) => {
    return API.settings.base_rates.statutory.update(rateId,data).finally(() => {
      getData();
    });
  };
  const onStatutoryRateDelete = rateId => {
    return API.settings.base_rates.statutory.delete(rateId).then(() => {
      getData();
    })
  };
  const onCompensationRateCreate = data => {
    return API.settings.base_rates.compensation.create({...data, org_id: params.org_id}).finally(() => {
      getData();
    });
  };
  const onCompensationRateUpdate = (rateId, data) => {
    return API.settings.base_rates.compensation.update(rateId, data).finally(() => {
      getData();
    });
  };
  const onCompensationRateDelete = rateId => {
    return API.settings.base_rates.compensation.delete(rateId).then(() => {
      getData();
    })
  };
  const onLatePaymentCreate = data => {
    return API.settings.base_rates.late_payments.create({...data, org_id: params.org_id}).finally(() => {
      getData();
    });
  };
  const onLatePaymentUpdate = (rateId, data) => {
    return API.settings.base_rates.late_payments.update(rateId, data).finally(() => {
      getData();
    });
  };
  const onLatePaymentDelete = rateId => {
    return API.settings.base_rates.late_payments.delete(rateId).then(() => {
      getData();
    })
  };

  if(isInitialLoading){
    return (
      <Loader>Retrieving data from server...</Loader>
    );
  }
  if(globalError){
    return (
      <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>
    );
  }

  return (
    <React.Fragment>
      <FeesNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <div className="sm:grid grid-cols-2">
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Bank of England Base Rates</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          <BaseRatesTable rates={baseRates} onCreate={onRateCreate} onUpdate={onRateUpdate} onDelete={onRateDelete} />
        </Segment>
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Late Pay [LPCD(I) Act] Interest (8% + base)</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          <BaseRatesTable rates={latePaymentsBaseRates} onCreate={onLatePaymentCreate} onUpdate={onLatePaymentUpdate} onDelete={onLatePaymentDelete} />
        </Segment>
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Statutory Interest</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {/* Todo: Statutary table */}
          {/* <div className="mt-2 sm:flex sm:items-start sm:justify-between"> */}

          <BaseRatesTable rates={statutoryRate} onCreate={onStatutoryRateCreate} onUpdate={onStatutoryRateUpdate} onDelete={onStatutoryRateDelete} />
            
            {/*  
              <input
                type="number"
                name="statutory_interest"
                id="statutory_interest"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  setStatutoryRate(e.target.value)
                }}
                value={statutoryRate}
              />
              {/*  
              <BaseRatesTable rates={latePaymentsBaseRates} onCreate={onLatePaymentCreate} onUpdate={onLatePaymentUpdate} onDelete={onLatePaymentDelete} />*/}
            {/* <div className="text-sm text-gray-500"></div> *-/}
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
              <button
                disabled
                className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                onClick={onStatutoryUpdate}
              >
                Update
              </button>
             </div> */}
          {/* </div> */}
          {/* <BaseRatesTable rates={latePaymentsBaseRates} onCreate={onLatePaymentCreate} onUpdate={onLatePaymentUpdate} onDelete={onLatePaymentDelete} /> */}
        </Segment>
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Compensation Rates</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          <CompensationTable rates={compensationRates} onCreate={onCompensationRateCreate} onUpdate={onCompensationRateUpdate} onDelete={onCompensationRateDelete} />
        </Segment>
      </div>
      {/*  
      <Segment>
        <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
          <span className="my-auto text-xl">Interest Rate Tester</span>
        </h3>
        <div className="w-full border-t border-gray-300 my-2" />

        <BaseRateTester />
      </Segment>*/}
    </React.Fragment>
  );
}
export default BaseRatesSettings;