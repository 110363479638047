import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default class FeeScales {
  async add(data) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/fee_scales/`,
      data
    );
  }

  async edit(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/fee_scales/${id}`,
      data
    );
  }

  /*
  async remove(id) {
    return ApiRequest.delete(
      `${AppConfig.api_server}/api/settings/email_templates/${id}`
    );
  }*/

  async list(org) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/fee_scales/list${org ? `?org_id=${org}` : ""}`
    );
  }
  async get(id) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/fee_scales/${id}`
    );
  }

   async threshold_get(set){ return ApiRequest.get(`${AppConfig.api_server}/api/settings/fee_scales/${set}/thresholds`);}
   async threshold_create(set, data){ return ApiRequest.post(`${AppConfig.api_server}/api/settings/fee_scales/${set}/thresholds`, data)}
   async threshold_update(set, id, data){ return ApiRequest.patch(`${AppConfig.api_server}/api/settings/fee_scales/${set}/thresholds/${id}`, data)}
   async threshold_delete(set, id){ return ApiRequest.delete(`${AppConfig.api_server}/api/settings/fee_scales/${set}/thresholds/${id}`)}

}
