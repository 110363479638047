import React from "react";
import ContactReceiver from "../../contexts/contact_context/Contact_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import Segment from "../../components/Segment";
import Checklist from "../../components/_checklists";
import Services from "../../components/_services";
import API from "../../api";
import RecentMatters from "./components/recentMatters";
import ContactBar from "./components/ContactBar";
import ClientBreadcrumbs from "./components/ClientBreadcrumbs";
import CommonFunctions from "../../CommonFunctions";

function ContactDash({ contact, history }) {
  return (
    <>
    <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/contacts/${contact._id}`, current: true }
      ]} />
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="">
            {/* Page header */}
            <ContactBar history={history} contact={contact} showEdit={true} />
            {/* <div className="mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-primary">
                    {contact.name}
                  </h1>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <UserIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type ? contact.type : "--"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <GiHouse
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.addresses &&
                      contact.addresses.find((item) => item.primary === true)
                        ? `${
                            contact.addresses.find(
                              (item) => item.primary === true
                            ).postal_code
                          } (${
                            contact.addresses.find(
                              (item) => item.primary === true
                            ).name
                          })`
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <OfficeBuildingIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type === "Company"
                        ? contact.companyRegistrationNumber
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <ReceiptTaxIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type === "Company"
                        ? contact.vatRegistrationNumber
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Button
                  onClick={() => history.push(`/contacts/${contact._id}/edit`)}
                  label="Edit Contact"
                  colour="blue"
                />
              </div>
            </div> */}
            {/* Main Content */}
            <div className="mt-4 mx-auto md:grid md:grid-cols-3">
                <div className="col-span-2" >
                  <div className="md:grid md:grid-cols-2">
                    <div className="p-6 col-span-2">
                      <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                        <div className="text-center text-xl py-2 text-primary">
                          Fees
                        </div>
                        <div className="flex justify-around mb-4">
                          <div className=" p-4 rounded-xl text-center">
                            <div className="text-3xl">
                              £{contact.total_owed && CommonFunctions.toFormattedFixed(contact.total_owed)}
                            </div>
                            <div className="text-xl text-primary">Total Owed</div>
                          </div>
                          <div className="  p-4 rounded-xl text-center">
                            <div className="text-3xl">
                              £{contact.total_fees && CommonFunctions.toFormattedFixed(contact.total_fees)}
                            </div>
                            <div className="text-xl text-primary">Total Fees</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                  <div className="md:grid md:grid-cols-2">
                    <Services
                      parentId={contact._id}
                      updateDetails={async (id) =>
                        await Promise.all([
                          API.settings
                            .services()
                            .updateContactService(id, contact._id),
                        ])
                      }
                    />
                    <Checklist
                      type="contact"
                      parentId={contact._id}
                      createTask={async (checklist_id) =>
                        await Promise.all([
                          API.settings
                            .checklist("contact")
                            .createChecklistTask(contact._id, checklist_id),
                        ])
                      }
                    />
                  </div>  
                </div>
              <div>
                <RecentMatters
                  parentId={contact._id}
                  handleClick={(id) => {
                    history.push(`/matters/${id}`);
                  }}
                />
              </div>  
            </div>
          </main>
        </Segment>
      </div>
      <div>
        {/* <Segment>
          <div className="flex">
            <DataField
              label={"Contact Name"}
              data={
                contact.type === "Company"
                  ? contact.name
                  : `${contact.first_name}${
                      contact.middle_name ? ` ${contact.middle_name}` : ""
                    } ${contact.last_name}`
              }
            />

            <DataField
              label={"Company Num"}
              data={
                contact.type === "Company"
                  ? contact.companyRegistrationNumber
                  : "N/A"
              }
            />
            <DataField label={"VAT Num"} data={contact.vatRegistrationNumber} />
            <DataField label={"Contact Type"} data={contact.type} />
          </div>
          <div>
          <DataField
            label={"Primary Contact Methods"}
            showOnUndefined={false}
          />
        </div>
        <div className="flex">
          <DataField
            label={"Phone Number"}
            data={
              contact.phone_numbers &&
              contact.phone_numbers.find((item) => item.primary === true)
                ? `${
                    contact.phone_numbers.find((item) => item.primary === true)
                      .number
                  } (${
                    contact.phone_numbers.find((item) => item.primary === true)
                      .name
                  })`
                : "N/A"
            }
          />
          <DataField
            label={"Email"}
            data={
              contact.email_addresses &&
              contact.email_addresses.find((item) => item.primary === true)
                ? `${
                    contact.email_addresses.find(
                      (item) => item.primary === true
                    ).address
                  } (${
                    contact.email_addresses.find(
                      (item) => item.primary === true
                    ).name
                  })`
                : "N/A"
            }
          />
          <DataField
            label={"Address"}
            data={
              contact.addresses &&
              contact.addresses.find((item) => item.primary === true)
                ? `${
                    contact.addresses.find((item) => item.primary === true)
                      .postal_code
                  } (${
                    contact.addresses.find((item) => item.primary === true).name
                  })`
                : "N/A"
            }
          />
          <DataField
            label={"Websites"}
            data={
              contact.web_sites &&
              contact.web_sites.find((item) => item.default_web_site === true)
                ? `${
                    contact.web_sites.find(
                      (item) => item.default_web_site === true
                    ).address
                  } (${
                    contact.web_sites.find(
                      (item) => item.default_web_site === true
                    ).name
                  })`
                : "N/A"
            }
          />
        </div>
        </Segment> */}
      </div>
    </>
  );
}

export default ChainedContext(ContactDash, [
  [
    ContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
