import React from "react";
import ContactForm from "./forms/contactForm";
import ChainedContext from "../../contexts/chainedContext";
import ContactReceiver from "../../contexts/contact_context/Contact_Receiver";
import API from "../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../CommonFunctions";

const EditContact = ({ contact, history }) => {
  const handleSubmit = (data) => {
    API.contacts
      .update(contact._id, data)
      .then(() => {
        toast.success("Contact updated..");
        history.push("/contacts/" + contact._id);
        contact._refresh();
      })
      .catch((e) => {
        console.log(e);
        CommonFunctions.handleError(e);
      });
  };

  return (
    <div className="pb-8">
      <ContactForm
        contact={contact}
        onCancel={() => history.push(`/contacts/${contact._id}`)}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
export default ChainedContext(EditContact, [[ContactReceiver, "contact"]]);
