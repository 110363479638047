import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import DataField from "../../../components/DataField";
import Checklist from "../../../components/_checklists";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
function TheDebt() {
  const params = useParams();
  const [data, setData] = useState({});
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      // API.matters.debtors
      //   .get(params.matter_id, params.debtor_id)
      //   .then((res) => setData(res))
      //   .catch((e) => CommonFunctions.handleError(e))
      //   .finally(() => setMountLoading(false));
    }
  }, [params, mountLoading]);

  return (
    <div>
      <div className="md:grid md:grid-cols-2">
        <Segment>
          <div className="leading-6 font-medium text-xl text-gray-900 border-b pb-1 border-gray-300 my-2">
            The Debt
          </div>
        </Segment>
      </div>
    </div>
  );
}
export default ChainedContext(TheDebt, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
