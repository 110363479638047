import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button } from "../../../components/Forms";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Segment from "../../../components/Segment";
import { useParams } from "react-router-dom";

export default function EmailManagement({ history }) {
  const [listData, setListData] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (mount) {
      setMount(false);
      setLoading(true);

      API.settings
        .email_templates()
        .list(params.org_id)
        .then((res) => setListData(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const renderTable = (header, body) => (
    <div className={`text-lg`}>
      <Button
        icon={PlusIcon}
        colour="green"
        label="Add"
        style={{ float: "right" }}
        onClick={() => {
          history.push(params.org_id ? `/admin/organisations/${params.org_id}/email_templates/add` : "/settings/email_templates/add");
        }}
      />
      <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
        <span className="my-auto text-xl">Email Templates</span>
      </h3>

      <br />

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {header.map((header_item, i) => {
                      return (
                        <th
                          key={"th_" + i}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header_item.text}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {body.length > 0 ? (
                    body.map((body_item, body_index) => (
                      <tr key={body_index}>
                        {header.map((header_item, header_index) => (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {body_item[header_item.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan={header.length}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                    >
                      No Data...
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/*  
      <ConfirmModal
        open={modalOpen !== ""}
        handleClose={() => setModalOpen("")}
        handleSubmit={() => {
          API.settings
            .email_templates()
            .remove(modalOpen)
            .then(() => {
              setMount(true);
              setModalOpen("");
            })
            .catch((e) => CommonFunctions.handleError(e));
        }}
        header="Are you sure?"
        content={
          "Deleting is an irreversible action, but you can re-add an item with the same name."
        }
      />*/}
      <Segment>
        {loading ? (
          <Loader />
        ) : (
          renderTable(
            [
              { text: "Template Name", value: "name" },
              { text: "Subject", value: "subject" },
              { text: "Actions", value: "action_btn" },
            ],
            listData.map((item) => ({
              ...item,
              action_btn: (
                <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                  <Button
                    icon={PencilIcon}
                    colour="blue"
                    className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                    onClick={() => {
                      history.push(
                        params.org_id ? `/admin/organisations/${params.org_id}/email_templates/edit/${item._id}` :
                        "/settings/email_templates/edit/" + item._id
                      );
                    }}
                  />
                  {/*  
                  <Button
                    icon={TrashIcon}
                    colour="red"
                    className="md:mr-auto md:mr-2"
                    onClick={() => {
                      setModalOpen(item._id);
                    }}
                  />*/}
                </div>
              ),
            }))
          )
        )}
      </Segment>
    </>
  );
}
