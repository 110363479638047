import React from "react";

const CreditCheckContext = React.createContext({
  getAllReports: () => null,
  requestReport: () => null,
  getSingleReport: () => null,
  defaultSearchName: "",
  routePrefix: "",
});

const Provider = ({
  children,
  getAllReports,
  requestReport,
  getSingleReport,
  defaultSearchName,
  routePrefix,
}) => {
  return (
    <CreditCheckContext.Provider
      value={{
        getAllReports,
        requestReport,
        defaultSearchName,
        getSingleReport,
        routePrefix,
      }}
    >
      {children}
    </CreditCheckContext.Provider>
  );
};
const Receiver = (Compo, mapContext) => {
  const joinProps = (a, b) => {
    return {
      ...a,
      ...b,
    };
  };
  return (props) => {
    return (
      <CreditCheckContext.Consumer>
        {(context) => <Compo {...joinProps(props, mapContext(context))} />}
      </CreditCheckContext.Consumer>
    );
  };
};
export { Provider, Receiver };
