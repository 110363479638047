import React from "react";
import Settings from "./Settings_Context";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";

class Settings_Receiver extends React.Component {
  state = {
    firstLoad: true,
    settings: {},
  };

  componentDidMount() {
    this._refreshSettings();
  }

  _refreshSettings = () => {
    this.props.api.settings
      .get()
      .then((res) => {
        this.setState({
          settings: res,
          firstLoad: false,
        });
      })
      .finally(() => {});
  };

  render() {
    return (
      <Settings.Provider
        value={{
          _refresh: this._refreshSettings,
          ...this.state.settings,
        }}
      >
        {this.state.firstLoad ? (
          <Loader>Loading Settings</Loader>
        ) : (
          this.props.children
        )}
      </Settings.Provider>
    );
  }
}

export default withRouter(Settings_Receiver);
