import React from "react";
import { useParams } from "react-router-dom";
import { ChecklistManager } from "./_components";

export default function ListManager() {
  const params = useParams();

  return params.type ? (
    <ChecklistManager
      type={params.type}
      org_id={params.org_id}
      header={
        params.type
          ? params.type
              .split("_")
              .map((item) => item[0].toUpperCase() + item.slice(1, item.length))
              .join(" ")
          : ""
      }
    />
  ) : (
    <></>
  );
}
