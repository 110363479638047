import React from "react";
import ContactForm from "./forms/contactForm";
import ChainedContext from "../../contexts/chainedContext";
import ProspectiveContactReceiver from "../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import API from "../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../CommonFunctions";

const EditContact = ({ contact, history }) => {
  const handleSubmit = (data) => {
    API.prospective_contact
      .update(contact._id, data)
      .then(() => {
        toast.success("Prospective contact updated..");
        history.push("/prospective-contacts/" + contact._id);
        contact._refresh();
      })
      .catch((e) => {
        console.log(e);
        CommonFunctions.handleError(e);
      });
  };

  return (
    <div className="pb-8">
      <ContactForm
        contact={contact}
        onCancel={() => history.push(`/prospective-contacts/${contact._id}`)}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ChainedContext(EditContact, [
  [ProspectiveContactReceiver, "contact"],
]);
