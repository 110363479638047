import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { Button, Dropdown} from "../../../../components/Forms";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import Segment from "../../../../components/Segment";

export default function AddLetterHeading({ history }) {

  const params = useParams();

  const [data, setData] = useState({
    name: ""
  });
  // const [itemData, setItemData] = useState(initialState);

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.customFields
        .get(params.id)
        .then((res) => {
          setData(res)

          API.settings.customFieldGroups().list(params.org_id).then((res) => {
            setGroups(
              res.map((a) => {
                return {
                  text: a.name,
                  value: a._id,
                };
              })
              
            );
          });

        }
          
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));

    }else if(loading){
      
      setLoading(true);
      API.settings.customFieldGroups().list(params.org_id).then((res) => {
        setGroups(
          res.map((a) => {
            return {
              text: a.name,
              value: a._id,
            };
          })
          
        );
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => setLoading(false));
    } 
  }, [mount, loading]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Segment>
      <div>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Matter Custom Field
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Details of custom field
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="description"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="shotcode"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Template Shortcode
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                       {"{"}
                      </span>
                        <input
                          type="text"
                          name="shortcode"
                          className="block w-full min-w-0 flex-1 rounded-none border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                          value={data.shortcode}
                          onChange={handleChange}
                        />
                        <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                          {"}"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="shotcode"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Field Type
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                      <Dropdown
                        name="fieldType"
                        value={data.fieldType}
                        onChange={(e) => {

                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        options={[
                          {text: "Checkbox", value: "Checkbox"},
                          //{text: "Client Select", value: "contact"},
                          {text: "Date", value: "Date"},
                          {text: "Email Address", value: "Email"},
                          {text: "Integer", value: "Numeric"},
                          //{text: "Matter Select", value: "matter"},
                          {text: "Money", value: "Currency"},
                          //{text: "Picklist", value: "picklist"},
                          {text: "Text (Multi-Line)", value: "TextArea"},
                          {text: "Text (One-Line)", value: "TextLine"},
                          {text: "Time", value: "Time"},
                          {text: "Web Address", value: "Url"},
                        ]}
                      />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Group
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                      <Dropdown
                        name="group"
                        value={data.group}
                        onChange={(e) => {

                          setData({
                            ...data,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        options={[{text: "None", value: ""}].concat(groups)}
                      />
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                {editing && 
                <Button 
                    className="inline-flex justify-center rounded-md mr-3 border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    icon={TrashIcon}
                    colour={"red"}
                    label={data.archived ? "UnArchive" : "Archive"}
                    onClick={() => API.customFields
                      .archive(params.id, params.org_id)
                      .then(() =>
                        history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields/groups/add` : "/settings/matter_custom_fields")
                      )
                      .catch((e) => CommonFunctions.handleError(e))}
                />}
                  <Button
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    icon={editing ? PencilIcon : PlusIcon}
                    colour={editing ? "amber" : "green"}
                    label={editing ? "Save" : "Add"}
                    onClick={() => {
                      let sendData = {
                        name: data.name,
                        description: data.description,
                        shortcode: data.shortcode,
                        fieldType: data.fieldType,
                        type: "matter",
                        group: data.group,
                        org_id: params.org_id ? params.org_id : undefined,
                      };

                      if (editing) {
                        API.customFields
                          .update(params.id, sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields` :"/settings/matter_custom_fields")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.customFields
                          .add(sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields` :"/settings/matter_custom_fields")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}
