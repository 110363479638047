import React from "react";
import Settings from "./Settings_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
const Receiver = (Compo, mapContext) => {
  return (props) => {
    return (
      <Settings.Consumer>
        {(context) => <Compo {...joinProps(props, mapContext(context))} />}
      </Settings.Consumer>
    );
  };
};
export default Receiver;
