const chained = (Component, ContextChain) => {
    let ComponentNested = Component;

    for (let i in ContextChain) {
        const context = ContextChain[i][0];
        const contextFunc = ContextChain[i][1];

        if(typeof contextFunc === 'string'){
            ComponentNested = context(ComponentNested, data => {
                return {
                    [contextFunc]: data
                }
            });
        } else {
            ComponentNested = context(ComponentNested, contextFunc);
        }
    }
    return ComponentNested;
};
export default chained;