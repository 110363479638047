import React from "react";
import {PaperClipIcon} from "@heroicons/react/solid";
import fileDownloader from "js-file-download";
import toast from "react-hot-toast";
import API from "../../../../api";

const DocumentsListView = ({ files = [], noDocumentsText = "", showDelete = false }) => {

  if(files.length === 0){
    if(noDocumentsText.length){
      return (
        <div className={"p-2 border border-blue-300 rounded bg-blue-600 text-white"}>{noDocumentsText}</div>
      );
    }
    return null;
  }
  return (
    <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
      {files.map((f, i) => {
        return (
          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm" key={`invoice_${i}`}>
            <div className="flex w-0 flex-1 items-center">
              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <span className="ml-2 w-0 flex-1 truncate">{f.filename}</span>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a onClick={() => {
                toast("Starting file download");
                API.clio.documents.downloadFile(f.clioId).then(res => {
                  fileDownloader(res, f.filename);
                })
              }} className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">Download</a>

              {showDelete && <a onClick={() => {
                // setConfirmDeleteOpen(f.file._id);
              }} className="ml-2 font-medium text-red-600 hover:text-red-500 cursor-pointer inline-block">Delete</a>}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
export default DocumentsListView;