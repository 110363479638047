import React from "react";
import { Switch, Route } from "react-router-dom";
import list from "./list";
import add from "./forms/add";
import NotFound from "../../../components/NotFound";

export default function Index() {
  return (
    <div>
      <Switch>
        <Route path={`/contacts/:contact_id/matters/create`} component={add} />
        <Route path={`/contacts/:contact_id/matters`} component={list} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
