import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import API from "../../api";
import Segment from "../Segment";
import Loader from "../Loader";
import { Toggle } from "../Forms";

export default function Index({
  parentId = "",
  updateDetails = async () => {},
}) {
  const [services, setServices] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && parentId) {
      setMount(false);
      setLoading(true);

      API.settings
        .services()
        .runServiceCheck(parentId)
        .then((res) => setServices(res))
        .finally(() => setLoading(false));
    }
  }, [mount, parentId]);

  return (
    <div className="p-6">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="border-b border-gray-200 pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Services
            </h3>
          </div>

          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {services && services.length > 0 ? (
                services.map((item, index) => (
                  <li key={`matter_${index}`}>
                    <a
                      className="block hover:bg-gray-50 cursor-pointer"
                      // onClick={() => handleClick(item._id)}
                    >
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {item.name}
                          </p>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                              {item.description}
                            </p>
                          </div>
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <Toggle
                              enabled={item.enabled}
                              colour="blue"
                              className="mx-auto my-2 w-min"
                              onChange={() => {
                                updateDetails(item._id).then(() => {
                                  setMount(true);
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <p>No Services</p>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

Index.propTypes = {
  parentId: PropTypes.string.isRequired,
  updateDetails: PropTypes.func.isRequired,
};
