import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Toggle,
  Input,
  SelectZero,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { CheckIcon, PlayIcon, StopIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Dialog, Transition } from "@headlessui/react";
import dayjs from "dayjs";
import toast from "react-hot-toast";

import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";

function Add({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    currentRate: 0,
    isBillable: true,
    defaultAdminRate: false,
    category: "",
  });
  const [defaultAdminRate, setDefaultAdminRate] = useState(0);

  const [mountLoading, setMountLoading] = useState(true);

  const [timer, setTimer] = useState(false);
  // 900000 - 15 mins 1500000 - 25 mins
  const [timerClock, setTimerClock] = useState(0);
  
  useEffect(() => {
    let interval = null;
    if (timer) {
      data.start_time = new Date();

      interval = setInterval(
        () => setTimerClock((prevTime) => prevTime + 10),
        10
      );
    } else {
      data.end_time = new Date();
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.settings.get().catch((e) => CommonFunctions.handleError(e)),
        API.settings
          .activity_categories()
          .list()
          .catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        if (res[0]) {
          setUserList(
            res[0].users.map((a) => {
              if(auth && a.email == auth.user.email){
                setData({ ...data, assigned: a._id });
              }
              return {
                text: a.name,
                value: a._id,
                rate: a.rate,
              };
            })
          );
        }

        if (res[1]) {
          setDefaultAdminRate(res[1].adminRate ? res[1].adminRate : 0);
        }
        if (res[2]) {
          setCategoryList(
            res[2].map((a) => {
              return {
                text: a.name,
                value: a._id,
              };
            })
          );
        }
      });

      setMountLoading(false);

    }
  }, [mountLoading]);

  useEffect(() => {
    if(params.category){
      setData({
        ...data,
        category: params.category
      })
    }
  }, [params.category]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Create Activity
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          <Group>
            <Dropdown
              label="Category"
              name="category"
              value={data.category}
              onChange={(e) => {
                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                });
              }}
              options={categoryList}
            />
          </Group>

          <Group className="py-2">
            <Dropdown
              label="Fee Earner"
              name="assigned"
              value={data.assigned}
              onChange={(e) => {
                const user = userList.find(
                  (item) => item.value === e.target.value
                );

                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                  currentRate:
                    data.defaultAdminRate !== true
                      ? user
                        ? user.rate
                        : 0
                      : data.currentRate,
                });
              }}
              options={userList}
            />
            <DateSelect
              label="Due Date"
              name="due_date"
              value={data.due_date}
              onChange={onDateChange}
            />
            <Group className="">
              <Input
                type="number"
                incrementBy="6"
                label="Time"
                name="time"
                value={data.time}
                onChange={onChange}
              />
              <Button
                  className={`mt-5 h-10 inline-block text-center mr-2 ${timer && "animate-pulse"}`}
                  colour={timer ? "blue" : "subtle"}
                  // label={timerClock}
                  label={`${(
                    "0" + Math.floor((timerClock / 3600000) % 24)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 60000) % 60)
                  ).slice(-2)}:${(
                    "0" + Math.floor((timerClock / 1000) % 60)
                  ).slice(-2)}`}
                  onClick={() => {
                    setTimer(!timer);
                    if (timer) {
                      // setTimeModal(true);
                    }
                  }}
                  disabled={false}
                  icon={timer ? StopIcon : PlayIcon}
                />
            </Group>
            <Input
              name="cost"
              label="Cost (£)"
              disabled
              value={(
                data.time * (data.currentRate ? data.currentRate / 60 : 0)
              ).toFixed(2)}
              onChange={onChange}
            />
          </Group>
          <Group>
            <Toggle
              className="my-2"
              label="Charge Default Admin Rate"
              enabled={data.defaultAdminRate ? data.defaultAdminRate : false}
              onChange={() => {
                const user = userList.find(
                  (item) => item.value === data.assigned
                );

                setData({
                  ...data,
                  defaultAdminRate: data.defaultAdminRate
                    ? !data.defaultAdminRate
                    : true,
                  currentRate: (
                    data.defaultAdminRate !== undefined
                      ? !data.defaultAdminRate
                      : true
                  )
                    ? defaultAdminRate
                    : user
                    ? user.rate
                    : 0,
                });
              }}
            />
            <Toggle
              className="my-2"
              label="Is Billable?"
              enabled={data.isBillable ? data.isBillable : false}
              onChange={() =>
                setData({
                  ...data,
                  isBillable: data.isBillable ? !data.isBillable : true,
                })
              }
            />
          </Group>
          <Group className="py-2">
            <TextArea
              label="Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </Group>
          <div className="pt-2 flex justify-between">
            <Button
              colour="red"
              label="Cancel"
              onClick={() =>
                history.push(`/matters/${params.matter_id}/activities`)
              }
              icon={XIcon}
            />
            <Button
              colour="green"
              label="Create"
              onClick={() => {
                API.matters.activities
                  .create(params.matter_id, data)
                  .then((res) => 
                  
                  data.hasTimer ?

                  API.timeEntry.add({
                    description: data.description,
                    nonBillable: !data.isBillable ? true : false,
                    matter: params.matter_id,
                    category: data.category,
                    activity: res,
                  }).then((res) => {
                    history.push(
                      `/matters/${params.matter_id}/activities/${res}`
                    )
                  }) : history.push(
                    `/matters/${params.matter_id}/activities/${res}`
                  )

                    
                  );
              }}
              icon={CheckIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Add), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);