import React from "react";
import ProspectiveContactReceiver from "../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import Segment from "../../components/Segment";
import DataField from "../../components/DataField";
import DashboardTable from "./components/dashboardTable";
import { StarIcon } from "@heroicons/react/solid";
import ContactBar from "./components/ContactBar";
import ProspectsBreadcrumbs from "./components/ProspectsBreadcrumbs";

function ContactDash({ contact, history }) {
  return (
    <div>
      <ProspectsBreadcrumbs panes={[
        { name: `Prospect - #${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/prospective-contacts/${contact._id}`, current: false },
        { name: `Details`, current: true }
      ]} />
      <div className="md:grid md:grid-cols-1">
        <Segment>
          <ContactBar contact={contact} history={history} showEdit={true} />
          <div className="grid sm:grid-cols-1">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Contact Information
                </h3>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Phone Numbers
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {contact.phone_numbers ? (
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          {contact.phone_numbers.map((item, index) => {
                            return (
                              <li
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                key={`phone_${index}`}
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <span className="ml-2 w-0 flex-1">
                                    {item.number}
                                  </span>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                  <span>{item.name}</span>

                                  {item.primary && (
                                    <>
                                      <span
                                        className="text-gray-300"
                                        aria-hidden="true"
                                      >
                                        |
                                      </span>
                                      <StarIcon className="w-6 h-6 text-yellow-500 inline-block" />{" "}
                                      {item.primary && "Primary"}
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        "No Phone Numbers"
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Email Addresses
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {contact.email_addresses ? (
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          {contact.email_addresses.map((item, index) => {
                            return (
                              <li
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                key={`email_${index}`}
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <span className="ml-2 w-0 flex-1">
                                    {item.address}
                                  </span>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                  <span>{item.name}</span>

                                  {item.primary && (
                                    <>
                                      <span
                                        className="text-gray-300"
                                        aria-hidden="true"
                                      >
                                        |
                                      </span>{" "}
                                      <StarIcon className="w-6 h-6 text-yellow-500 inline-block" />
                                      {item.primary && "Primary"}
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        "No Email Addresses"
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Addresses
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {contact.addresses ? (
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          {contact.addresses.map((item, index) => {
                            return (
                              <li
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                key={`address_${index}`}
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <span className="ml-2 w-0 flex-1">
                                    {Object.keys(item)
                                      .filter(
                                        (address_item) =>
                                          address_item === "street" ||
                                          address_item === "city" ||
                                          address_item === "province" ||
                                          address_item === "postal_code" ||
                                          address_item === "country"
                                      )
                                      .map((address_item) => item[address_item])
                                      .join(", ")}
                                  </span>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                  <span>{item.name}</span>

                                  {item.primary && (
                                    <>
                                      <span
                                        className="text-gray-300"
                                        aria-hidden="true"
                                      >
                                        |
                                      </span>{" "}
                                      <StarIcon className="w-6 h-6 text-yellow-500 inline-block" />
                                      {item.primary && "Primary"}
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        "No Addresses"
                      )}
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Websites
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {contact.web_sites ? (
                        <ul
                          role="list"
                          className="divide-y divide-gray-200 rounded-md border border-gray-200"
                        >
                          {contact.web_sites.map((item, index) => {
                            return (
                              <li
                                className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                                key={`website_${index}`}
                              >
                                <div className="flex w-0 flex-1 items-center">
                                  <span className="ml-2 w-0 flex-1">
                                    {item.address}
                                  </span>
                                </div>
                                <div className="ml-4 flex flex-shrink-0 space-x-4">
                                  <span>{item.name}</span>

                                  {item.default_web_site && (
                                    <>
                                      <span
                                        className="text-gray-300"
                                        aria-hidden="true"
                                      >
                                        |
                                      </span>{" "}
                                      <StarIcon className="w-6 h-6 text-yellow-500 inline-block" />
                                      {item.default_web_site && "Primary"}
                                    </>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        "No Websites"
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </Segment>
      </div>
    </div>
  );
}

export default ChainedContext(ContactDash, [
  [
    ProspectiveContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
