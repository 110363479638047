import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import API from "../../api";

function Dashboard({ admin }) {

  const [orgs, setOrgs] = useState([]);
  
  useEffect(() => {
    API.organisations.getAll("", false).then(res => setOrgs(res))
  }, [admin]);

  const calculateDaysTillWeekend = () => {
    const today = dayjs();
    const todayDayOfWeek = today.day(); // Day of the week (0 - Sunday, 6 - Saturday)
    
    // Calculate days till next Saturday
    const daysTillSaturday = 6 - todayDayOfWeek;
    
    // If today is Saturday or Sunday, it means 0 days till the weekend
    if (todayDayOfWeek === 6 || todayDayOfWeek === 0) {
      return 0;
    }
    
    return daysTillSaturday;
  };

  const daysTillWeekend = calculateDaysTillWeekend();

  const stats = [
    { id: 1, name: 'Active Organisations', value: orgs.filter(o => o.isActive === true).length },
    { id: 2, name: 'De-Actived Organisations', value: orgs.filter(o => o.isActive !== true).length },
    { id: 3, name: 'Days till the weekend', value: daysTillWeekend },
  ]

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
          {stats.map((stat) => (
            <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
              <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {stat.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

export default Dashboard;

/*
export default ChainedContext(Dashboard, [
  [
    ContactReceiver,
    (settings) => {
      return { settings };
    },
  ],
]); */
