import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";

function FeesNavbar({ match }) {
  console.log("🚀 ~ FeesNavbar ~ match:", match)
  const navItems = [
    {
      name: "Rates",
      description: "rates",
      href: `${match.url}/base_rates`,
      exact: true,
    },
    {
      name: "Fee Scales",
      description: "fee scales",
      href: `${match.url}/fee_scales`,
      exact: true,
    },
    {
      name: "Area Tasks",
      description: "area tasks",
      href: `${match.url}/area_tasks`,
      exact: true,
    },
    {
      name: "Payment Types",
      description: "payment types",
      href: `${match.url}/payment_types`,
      exact: true,
    },
    {
      name: "Salutations",
      description: "salutations",
      href: `${match.url}/salutations`,
      exact: true,
    },
    {
      name: "Courts",
      description: "Courts",
      href: `${match.url}/courts`,
      exact: true,
    }
  ];

  if(!match.params.org_id){
    navItems.push({
      name: "Clio Matters No Documents",
      description: "custom fields",
      href: `${match.url}/clio_matters`,
      exact: true,
    });
  }

  return (
    <Disclosure as="nav" className="bg-gray-100 sticky-item-sub-nav">
      {({ open }) => (
        <>
          <div className=" mx-auto  px-2 border-b border-primary">
            <div className="flex items-center justify-between h-12">
              <div className="flex items-center">
                <div className="hidden md:block">
                  <div className=" flex items-center justify-center text-center space-x-4">
                    <div
                      // onClick={() =>
                      //   history.push(`/matters/${matter._id}/debtors`)
                      // }
                      className="hidden cursor-pointer lg:flex gap-2 antialiased text-md px-2 py-2 justify-center items-center text-primary tracking-wider"
                    >
                      <span>Tables</span>
                    </div>
                    {navItems
                      .filter((s) => {
                        return s.hide !== true;
                      })
                      .map((item, itemIdx) => (
                        // itemIdx === 0 ? (
                        <Fragment key={itemIdx}>
                          {item.external ? (
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noreferrer"
                              alt={item.description}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <NavLink
                              exact={item.exact}
                              to={item.href}
                              activeClassName="bg-gray-50 "
                              className=" px-2 py-2 rounded-md text-navText  text-md font-medium hover:bg-gray-200 tracking-wider"
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-between p-2 rounded-md text-primary hover:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white">
                  <div className=" flex gap-2">
                    <span className="text-black">Tables</span>
                  </div>
                  <span className="sr-only text-black">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6 text-black" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="block h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navItems
                .filter((s) => {
                  return s.hide !== true;
                })
                .map((item, itemIdx) => (
                  <Fragment key={itemIdx}>
                    <NavLink
                      exact
                      to={item.href}
                      activeClassName="bg-gray-50 "
                      className="text-primary hover:bg-gray-300  block px-3 py-2 rounded-md text-base font-medium"
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </NavLink>
                  </Fragment>
                ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default FeesNavbar;
