import toast from "react-hot-toast";
import dayjs from "dayjs";

class CommonFunctions {
  handleError(error) {
    toast.error(
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An unexpected error has occured.",
      {}
    );
  }

  toFormattedFixed(value, fixed = 2) {
    return value ? value.toLocaleString('en-GB', {
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed
    }) : 0;
  }

  calculateDebt(interestRate, transactionHistory = []) {
    let debt = [];

    for (let index = 0; index < transactionHistory.length; index++) {
      const item = transactionHistory[index];
      const startDate = index === 0 ? dayjs(item.dateStarting).toDate() : dayjs(item.dateStarting).add(1, "day").toDate();
      const endDate = transactionHistory[index + 1] ? dayjs(transactionHistory[index + 1].dateStarting).toDate() : dayjs().subtract(1, "day").startOf("day").toDate();

      let daysAtRate = dayjs(endDate).diff(startDate, "days");
      daysAtRate = daysAtRate + 1;

      const amountPerDay = ((interestRate / 100) * item.amount) / 365;

      debt.push(daysAtRate * amountPerDay);
    }

    return {
      debt,
      totalDebt: debt.length > 0 ? debt.reduce((a, b) => a + b) : 0,
    };
  }

  generateDailyDebtPayments__old(interestRate, transactionHistory) {
    let dailyDebtRepayments = [];

    if (!transactionHistory[0]) {
      return dailyDebtRepayments;
    }

    const startingDate = dayjs(transactionHistory[0].dateStarting);
    const endDate = dayjs();

    let currentPaymentBracket = transactionHistory[transactionHistory.length - 1];
    let nextPaymentBracket = transactionHistory[1] ? transactionHistory[1] : {};

    let paymentBracketIndex = 1;
    let index = 0;

    while (startingDate.add(index, "days").isBefore(endDate, "day")) {
      const day = startingDate.add(index, "days");

      const amount =
        ((interestRate / 100) * currentPaymentBracket.amount) / 365;

      dailyDebtRepayments.push({ day: day.format("DD-MM-YYYY"), amount });

      if (
        nextPaymentBracket &&
        dayjs(nextPaymentBracket.dateStarting).isSame(day, "day")
      ) {
        currentPaymentBracket = nextPaymentBracket;
        nextPaymentBracket = transactionHistory[paymentBracketIndex + 1];

        paymentBracketIndex = paymentBracketIndex + 1;
      }

      index++;
    }

    return dailyDebtRepayments;
  }


  generateDailyDebtPayments(interestRate, transactionHistory, debt) {
    console.log("🚀 generateDailyDebtPayments ~ debt:", debt)
    console.log("🚀 generateDailyDebtPayments ~ transactionHistory:", transactionHistory)
    let dailyDebtRepayments = [];

    if (!transactionHistory[0]) {
        return dailyDebtRepayments;
    }

    const startingDate = dayjs(transactionHistory[0].dateStarting);
    const endDate = dayjs(transactionHistory[0].endDate); // Adjust this as needed

    let outstandingAmount = transactionHistory[0].amount;
    const interestPerDay = (interestRate / 100) / 365;

    let index = 0;

    // Sort transactions by date
    transactionHistory.sort((a, b) => dayjs(a.dateStarting).isAfter(dayjs(b.dateStarting)) ? 1 : -1);

    // Initialize payment index
    let paymentIndex = 1;

    while (startingDate.add(index, "days").isBefore(endDate, "day")) {
        const day = startingDate.add(index, "days");

        // Calculate the interest accrued for the current day
        const interestAccrued = outstandingAmount * interestPerDay;
        dailyDebtRepayments.push({ day: day.format("DD-MM-YYYY"), amount: interestAccrued.toFixed(2), interestPerDay, interestRate, outstandingAmount });

        console.log(`generateDailyDebtPayments | Date: ${day.format("DD-MM-YYYY")}, Outstanding Amount: ${outstandingAmount}, Interest Accrued: ${interestAccrued}`);

        // Check if there's a payment on the current day and adjust the outstanding amount
        while (paymentIndex < transactionHistory.length && day.isSame(dayjs(transactionHistory[paymentIndex].dateStarting), 'day')) {
            // outstandingAmount -= transactionHistory[paymentIndex].amount;
            outstandingAmount = transactionHistory[paymentIndex].amount;
            // Ensure the outstanding amount never goes negative
            if (outstandingAmount < 0) {
                outstandingAmount = 0;
            }
            paymentIndex++;
        }

        index++;
    }

    return dailyDebtRepayments;
  }

}

export default new CommonFunctions();
