import React, { useEffect, useState } from "react";
import DayJS from "dayjs";
import {useSocketIo} from "../../../plugins/socketio-with-auth";
import API from "../../../api";
import {Button} from "../../../components/Forms";
import {RefreshIcon} from "@heroicons/react/solid";
import {toast} from "react-hot-toast";
import CommonFunctions from "../../../CommonFunctions";


const CronOverview = () => {
  const [crons, setCrons] = useState([]);
  const [loginUrl, setLoginUrl] = useState("");
  const [cronAuthStatus, setCronAuthStatus] = useState({isLoading: true});
  const [isClioSyncing, setClioSyncing] = useState(false);
  const [isAutomationsSyncing, setAutomationsSyncing] = useState(false);
  const [clioSyncLog, setClioSyncLog] = useState([]);


  const socketIo = useSocketIo();

  useEffect(() => {
    API.settings.background_tasks.cron_auth_status().then((status) => {
      setCronAuthStatus(status);
    });
    API.settings.background_tasks.list_crons().then(res => {
      setCrons(res);
    });
    API.settings.background_tasks.getAuthUrl().then(res => {
      setLoginUrl(res.goto);
    });
  }, [])

  useEffect(() => {
    if(!socketIo) return;

    const handleCronUpdate = (cron) => {
      setCrons(existing => {
        return existing.map(item => {
          if(item._id !== cron._id) return item;
          return cron;
        })
      })
    };

    const handleCronLog = data => {
      setClioSyncLog(existing => {
        return [data].concat(existing);
      });
    }

    socketIo.on("cron:update", handleCronUpdate);
    socketIo.on("cron:log", handleCronLog);

    return () => {
      socketIo.removeListener("cron:update", handleCronUpdate);
      socketIo.removeListener("cron:log", handleCronLog);
    };
  }, [socketIo]);

  const syncClio = () => {
    setClioSyncing(true);

    API.settings.background_tasks.syncClio().then(() => {
      setClioSyncing(false);
      toast.success("Custom Fields, Matters and tasks synced with Clio");
    }).catch((e) => {
      CommonFunctions.handleError(e);
      setClioSyncing(false);
    });
  };

  const syncAutomations = () => {
    setAutomationsSyncing(true);

    API.settings.background_tasks.automationTasks().then(() => {
      setAutomationsSyncing(false);
      toast.success("Automation tasks running.");
    }).catch((e) => {
      CommonFunctions.handleError(e);
      setAutomationsSyncing(false);
    });
  };

  return (
    <div>
      <h1 className={"text-2xl text-center"}>Background Tasks Overview</h1>


      <h3 className={"text-xl pt-4 pb-2 underline"}>System Crons</h3>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">ID</th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Last Run At</th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Next Run At</th>
          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
        </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">{crons.map(cron => {
          return (
            <tr key={cron._id}>
              <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900">{cron.name}</td>
              <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900">{DayJS(cron.lastRunAt).format("DD-MM-YYYY hh:mm a")}</td>
              <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900">{DayJS(cron.nextRunAt).format("DD-MM-YYYY hh:mm a")}</td>
              <td className="whitespace-nowrap py-4 pl-2 pr-3 text-sm font-medium text-gray-900">
                {cron.lockedAt === null && "Standby"}
                {cron.lockedAt !== null && "Running"}
              </td>
            </tr>
          );
        })}</tbody>
      </table>


      <h3 className={"text-xl pt-4 pb-2 underline"}>Clio Cron Authentication</h3>
      {cronAuthStatus.isLoading !== undefined ? <>
        <div className={"py-2 text-blue-700"}>Checking auth status...</div>
      </> : (cronAuthStatus.success ? <>
        <div>
          Cron authenticated as: <span className={"font-bold"}>{cronAuthStatus.userProfile.name} | {cronAuthStatus.userProfile.email}</span>

          <Button
            colour="blue"
            label="Re-authenticate"
            className="h-fit w-fit"
            onClick={() => setCronAuthStatus({success: false})}
          />
        </div>
      </> : <>
        <a href={loginUrl} className={"text-blue-700 underline"}>Begin Authentication (Right click, select incognito)</a>
      </>)}

      <h3 className={"text-xl pt-4 pb-2 underline"}>Clio Sync</h3>
      <p>Running the Clio Sync will add new contacts, new matters & new tasks from Clio into bridge. Existing items on Bridge will be updated to match the data within Clio.</p>
      
      <Button
        className="mt-2 text-center"
        colour={isClioSyncing ? "blue" : "subtle"}
        label={isClioSyncing ? "Sync in progress" : "Start Clio Sync"}
        onClick={syncClio}
        disabled={isClioSyncing}
        icon={RefreshIcon}
      />

      <h3 className={"text-xl pt-4 pb-2 underline"}>Automations Cron</h3>
      <p>Running the Automations Cron manually will run through all the tasks with automations and if all conditions met the task will be marked as completed. Check Automations from the Area Tasks screen.</p>

      <Button
        className="mt-2 text-center"
        colour={isAutomationsSyncing ? "blue" : "subtle"}
        label={isAutomationsSyncing ? "Task in progress" : "Start Automation Task"}
        onClick={syncAutomations}
        disabled={isAutomationsSyncing}
        icon={RefreshIcon}
      />

      <h3 className={"text-xl pt-4 pb-2 underline"}>Cron Logs</h3>
      <div className={"p-1 mt-4 h-64 bg-gray-200 border border-black rounded overflow-y-auto"}>{clioSyncLog.map((log, i) => {
        return (
          <div key={i} className={"border border-b-gray-400 pb-1"}>
            <span className={"text-gray-700 pr-1 text-xs"}>{DayJS(log.createdAt).format("DD-MM-YYYY HH:mm:ss")} [{log.service}]</span>[{log.level}] {log.message}
          </div>
        );
      })}
      </div>

    </div>
  );
}
export default CronOverview;