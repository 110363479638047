import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import Segment from "../../../../components/Segment";
import { getList } from "../../../../components/widgets/editorShortcodes/shortcodes";
import PaginateWrapper from "../../../../components/PaginationWrapper";
import { ThemeTable } from "../../../../components/Tables";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";

export default function ShortcodesList({ history }) {
  const [shortcodes, setShortcodes] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(async() => {
    if (mount) {
      setMount(false);
      setLoading(true);
      setShortcodes(await getList());
      setLoading(false);
    }
  }, [mount]);

  const renderTable = (header, body) => { 
    
    return(
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-white">
          <tr>
            {header.map((header_item, i) => {
              return (
                <th
                  key={"th_" + i}
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {header_item.text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {body.map((body_item, body_index) => (
                <tr key={body_index}>
                {header.map((header_item, header_index) => (
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {body_item[header_item.value]}
                    </td>
                ))}
                </tr>
            ))}      
        </tbody>
      </table>
    )
  };

  const getData = (pageNum, pageSize, search, filters) => {
    return API.customFields
      .listPaged({
        pageNum,
        pageSize,
        search,
        filters,
      })
      .catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <>
      <Segment>
        {loading ? (
          <Loader />
        ) : (

          <div className={`text-lg`}>
            
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">Shortcodes List</span>
            </h3>

            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              
                <br/>
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                  <PaginateWrapper
                    hidePageSelect={true}
                    dataQuery={getData}
                    search={true}
                    // archived_toggle={{ title: "Archived", field: "archived" }}
                    render={(items) => {
                      return (
                        <ThemeTable
                          header={[
                            { text: "Shortcode", value: "shortcode" },
                          ]}
                          body={items.map((item) => {
                            return {
                              shortcode: item.value
                            }})}
                          noDataMessage="No Shortcodes"
                        />
                      );
                    }}
                  />

                    {/*renderTable([{ text: "Shortcode", value: "value" },],shortcodes)*/}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Segment>
    </>
  );
}
