import React from "react";
import CreditChecks from "../../components/creditChecks";
import ChainedContext from "../../contexts/chainedContext";
import ContactReceiver from "../../contexts/contact_context/Contact_Receiver";
import API from "../../api";

const CreditCheck = ({contact}) => {
    const getAllReports = async () => {
        return API.contacts.credit_checks.all(contact._id);
    };
    const getSingleReport = async (reportId) => {
        return API.contacts.credit_checks.report(contact._id, reportId);
    };
    const requestReport = async (type, resultItem) => {
        return API.contacts.credit_checks.newReport(contact._id, type, resultItem).then(() => {
            // Do nothing
        });
    };
    return (
        <CreditChecks
            routePrefix={"/contacts/:contact_id"}
            getAllReports={getAllReports}
            requestReport={requestReport}
            getSingleReport={getSingleReport}
            defaultSearchName={contact.name}
        />
    );
}
export default ChainedContext(CreditCheck, [
    [ContactReceiver, "contact"]
]);
