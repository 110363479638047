import React, {useCallback, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { PencilIcon, PlusIcon, TrashIcon, UploadIcon } from "@heroicons/react/solid";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Segment from "../../../components/Segment";
import HtmlEditor from "../../../components/widgets/HtmlEditor";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button, SelectTwo, FileUploader, Input } from "../../../components/Forms";
import { ConfirmModal } from "../../../components/Modals";
import Select from "react-tailwindcss-select";
import { BiUpload } from "react-icons/bi";
import toast from "react-hot-toast";
const RenderSection = ({ title, description, data, setData, templates }) => {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-600">{description}</p>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                {/*
                 <div class="grid grid-cols-3 gap-6">
                 <div class="col-span-3 sm:col-span-2">
                 <label
                 for="company-website"
                 class="block text-sm font-medium text-gray-700"
                 >
                 Load From Template:
                 </label>
                 <div class="mt-1 flex rounded-md shadow-sm">
                 <SelectTwo
                 placeholder="Please Select"
                 field="letter_header"
                 options={letterHeaders.map((a) => {
                 return {
                 text: a.name,
                 value: a._id,
                 };
                 })}
                 className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                 // value={data.letter_header}
                 onChange={(e) => {
                 let header = letterHeaders.find(a => a._id == e.target.value);
                 console.log("🚀 ~ file: add.js:271 ~ AddDocumentTemplate ~ header:", header)

                 setData(header.content)

                 }}
                 />
                 </div>
                 </div>
                 </div>*/}
                <div className="grid grid-cols-1 gap-6">
                  <div className="col-span-12 sm:col-span-3">
                    <div className="mt-1">
                      <HtmlEditor
                        content={data}
                        templates={templates}
                        options={{
                          height: 400,
                        }}
                        onContentUpdate={(d) => setData(d)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const SectionDivider = () => {
  return (
    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200"></div>
      </div>
    </div>
  );
}

export default function AddDocumentTemplate({ history }) {
  const params = useParams();

  const [data, setData] = useState({
    name: "",
    archived: false,
    description: "",
    global_template: false,
    recipient: [],
    fileName: "",
    header_data: "",
    body_data: "",
    footer_data: "",
    document_type: "",
    document_fields: [],
    document_fields_map: [],
    file: null
  });
  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const [archiveModal, setArchiveModal] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
      .doc_templates()
      .get(params.id)
      .then((res) => {
        setData({
          name: res.name,
          global_template: res.global_template,
          description: res.description,
          recipient: res.recipient,
          letter_header: res.letter_header,
          letter_footer: res.letter_footer,
          document_type: res.document_type,
          document_form: res.document_form,
          fileName: res.fileName,
          document_fields: res.document_fields,
          document_fields_map: res.document_fields_map,
          body_data: res.content,
          header_data: res.header_content,
          footer_data: res.footer_content,
          archived: res.archived,
        })
      }
      )
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => setLoading(false));

    }

    API.settings.doc_types().list(params.org_id).then((res) => {
      setRecipients(
        res.filter(r => r.archived !== true).map((a) => {
          return {
            label: a.name,
            value: a._id,
          };
        })
      );
    });
  }, [mount, loading]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    
  }

  const headerTemplates = useCallback(() => {
    return API.settings.letter_headers().list();
  }, []);

  const footerTemplates = useCallback(() => {
    return API.settings.letter_headers().list(); // TODO bring back just the templates for foorer
  }, []);

  const handleSaveData = (data, field) => {
    setData(a => {
      return {
        ...a,
        [field]: data
      }
    })
  }

  return (
    <Segment>
      <div>
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Template Settings
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Enter Name of template and description
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Template
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Global Template
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="checkbox"
                          name="global_template"
                          className="mt-1 block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          checked={data.global_template}
                          onChange={() => {
                            setData({ ...data, global_template: !data.global_template});
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {!data.global_template &&
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Template Categories
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">

                      <Select 
                          isMultiple
                          name='recipient'
                          size='normal'
                          options={recipients}
                          placeholder= 'Select Recipients'
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          value={Array.isArray(data.recipient) ? data.recipient : []}
                          onChange={(value) => setData({ ...data, recipient: value })}
                          />

{/*  
                        <SelectTwo
                          placeholder="Please Select Recipient"
                          field="recipient"
                          options={recipients}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          value={data.recipient}
                          onChange={handleChange}
                        />*/}
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <SectionDivider />

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Template Type
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Choose type of Template, Simple or Form. Simple is a template where bridge will generate a brand new template with content provided whereas Form is a document PDF file uploaded with fields to customise on Genration.
              </p>
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-3 sm:col-span-2">
                      <label
                        for="document_type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <div className="mt-1 mb-20 flex rounded-md shadow-sm">
                        <SelectTwo
                          placeholder="Please Select Letter Header"
                          field="document_type"
                          options={[
                            {text: "Simple",value: "simple" },
                            {text: "Form",value: "form" },
                          ]}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          value={data.document_type}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {data.document_type === "simple" && <>
        <SectionDivider />
        <RenderSection title={"Header Content"} description={"Content for header of the template"} data={data.header_data} setData={a => handleSaveData(a, "header_data")} templates={headerTemplates} />
        <SectionDivider />
        <RenderSection title={"Body Content"} description={"Content to go into the body"} data={data.body_data} setData={a => handleSaveData(a, "body_data")} />
        <SectionDivider />
        <RenderSection title={"Footer Content"} description={"Content for footer of the template"} data={data.footer_data} setData={a => handleSaveData(a, "footer_data")} templates={footerTemplates} />
      </>}

      {(data.document_type === "form") && <>
        <div class="hidden sm:block" aria-hidden="true">
          <div class="py-5">
            <div class="border-t border-gray-200"></div>
          </div>
        </div>
        <div class="mt-10 sm:mt-0">
          <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
              <div class="px-4 sm:px-0">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  Form Document
                </h3>
                <p class="mt-1 text-sm text-gray-600">
                  Upload Form Document <br/><i>*PDF documents only!</i>
                  <br/><br/>
                  {data.document_form ? `Current Document: ${data.fileName}` : ""}
                </p>
              </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
              <form action="#" method="POST">
                <div class="overflow-hidden shadow sm:rounded-md">
                  <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                      <div class="col-span-3 sm:col-span-2">
                        <label
                          for="document_type"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Upload file
                        </label>
                        <div class="mt-1 flex rounded-md shadow-sm">
                          <FileUploader onFileToUpload={(d) => setData({...data, file: d})} />
                        </div>
                        Selected File: {data.file ? data.file.name : `None Selected!`}
                        <br/>

                          {data.file && editing &&
                            <Button
                              className={`inline-flex justify-center rounded-md border bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                              colour={"amber"}
                              icon={UploadIcon}
                              label={"Save & Upload new file"}
                              onClick={() => {
                                let formData = new FormData();
                                let sendData = {
                                  name: data.name,
                                  description: data.description,
                                  recipient: data.recipient,
                                  letter_header: data.letter_header,
                                  letter_footer: data.letter_footer,
                                  document_type: data.document_type,
                                  archived: false,
                                  content: data.body_data ,
                                  global_template: data.global_template ,
                                  header_content: data.header_data,
                                  footer_content: data.footer_data ,
                                  document_fields_map: data.document_fields_map,
                                  replace_document: true
                                };
                                formData.append("data", JSON.stringify(sendData));
                                formData.append("file", data.file);

                                API.settings
                                  .doc_templates()
                                  .edit(params.id, formData)
                                  .then((res) =>
                                    setMount(true)
                                  )
                                  .catch((e) => CommonFunctions.handleError(e));
                              }}
                            />}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>}

      {(data.document_type === "form" && data.document_form) && <>
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Form Fields
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Adjust what data will be entered into the form fields
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white px-4 py-5 sm:p-6">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Field Type
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Field Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Content
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.document_fields.length ? (
                        data.document_fields.map((field, index) => {
                          let fields = data.document_fields_map;
                          let map = fields.find(f => f.field === field.name)

                          return (
                            <tr key={"field_" + index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {field.type}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500" style={{maxWidth: "100px"}}>
                                {field.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {field.type === "PDFTextField" && <Input type="text" value={map?.content || ""} onChange={
                                (d) =>  {
                                  handleSaveData(data.document_fields_map.map(a => {
                                    if(a.field !== field.name) return a;
                                    return {
                                      ...a,
                                      content: d.target.value
                                    }
                                  }), "document_fields_map");
                                }
                              } />}
                                {field.type === "PDFTextField-OLD" &&
                                <HtmlEditor
                                  content={map?.content || ""}
                                  options={{
                                    height: 100,
                                  }}
                                  onContentUpdate={(d) =>  {
                                    handleSaveData(data.document_fields_map.map(a => {
                                      if(a.field !== field.name) return a;
                                      return {
                                        ...a,
                                        content: d
                                      }
                                    }), "document_fields_map");
                                  }} />}
                              </td>
                            </tr>
                          );
                        }
                        )
                        
                      ) : (
                        <tr>
                          <td
                            colSpan={3}
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                          >
                            There are no fields to display.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>}

      <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0" />
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">

                  {editing && <>
                  <ConfirmModal
                    open={archiveModal}
                    handleClose={() => setArchiveModal(false)}
                    handleSubmit={() => {
                      let formData = new FormData();
                      formData.append("data", JSON.stringify({
                        archive: !data.archived
                      }));

                      API.settings
                        .doc_templates()
                        .edit(params.id, formData)
                        .then((res) =>
                          history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_templates` : "/settings/doc_templates")
                        )
                        .catch((e) => CommonFunctions.handleError(e));
                    }}
                    header={data.archived ? "UnArchive Template" : "Archive Template"}
                    content={
                      `Are you sure you wish to ${data.archived ? "un-" : ""}archive this document template?`
                    }
                  />

                  <Button
                    className={`inline-flex mr-2 justify-center rounded-md border ${data.archived ? "bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500" : "bg-red-600 hover:bg-red-700 focus:ring-red-500"} border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    colour={"amber"}
                    icon={data.archived ? BiUpload : TrashIcon}
                    label={data.archived ? "UnArchive" : "Archive"}
                    onClick={() => setArchiveModal(true)}
                  /></>}



                  <Button
                    className={`inline-flex justify-center rounded-md border ${editing ? "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500"} border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    colour={editing ? "amber" : "green"}
                    icon={editing ? PencilIcon : PlusIcon}
                    label={editing ? "Save" : "Add"}
                    onClick={() => {

                      console.log(data.recipient);

                      if(!data.global_template && (!data.recipient || !data.recipient.length > 0)){
                        toast.error("Template requires either a Category or to be set as a Global Template!");
                        return;
                      }
                      
                      let formData = new FormData();
                      let sendData = {
                        name: data.name,
                        global_template: data.global_template,
                        description: data.description,
                        recipient: data.recipient,
                        letter_header: data.letter_header,
                        letter_footer: data.letter_footer,
                        document_type: data.document_type,
                        archived: false,
                        content: data.body_data ,
                        header_content: data.header_data,
                        footer_content: data.footer_data ,
                        document_fields_map: data.document_fields_map,
                        org_id: params.org_id ? params.org_id : undefined
                      };
                      formData.append("data", JSON.stringify(sendData));
                      formData.append("file", data.file);

                      if (editing) {
                        API.settings
                          .doc_templates()
                          .edit(params.id, formData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_templates` : "/settings/doc_templates")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.settings
                          .doc_templates()
                          .add(formData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_templates` : "/settings/doc_templates")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}