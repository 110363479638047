import React from "react";
import { Switch, Route } from "react-router-dom";
import Dash from "../../../settings/fee_scales/dash";
import AddTemplate from "../../../settings/fee_scales/add";
import FeesNavbar from "../../../settings/_components/FeesNavbar";

const routePrefix = "/admin/organisations/:org_id/fee_scales";

export default function Index({history, match}) {
  return (
    <>
      <FeesNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={Dash} />
      </Switch>
    </>
  );
}
