import React from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import DebtorsForm from "./_components/debtorsForm";
import API from "../../../api";

const EditDebtors = ({ matter, debtor, history }) => {
  const handleSubmit = (data) => {
    API.matters.debtors
      .update(matter._id, data._id, data)
      .then(() => history.push(`/matters/${matter._id}/debtors`))
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        debtor._refresh();
      });
  };
  return (
    <div className="mt-2">
      <Segment>
        <div>
          <h3 className="text-xl leading-6 font-medium text-primary">
            <span className="my-auto ">Edit {debtor.debtor_name}</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
        </div>
        <DebtorsForm
          debtor={debtor}
          onSubmit={handleSubmit}
          onCancel={() => history.push(`/matters/${matter._id}/debtors`)}
        />
      </Segment>
    </div>
  );
};
export default ChainedContext(EditDebtors, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
