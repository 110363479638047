import React from "react";
import GlobalSearch from "../../components/widgets/global_search";
import Header from "./_components/Header";

const SearchPage = ({ location, history }) => {
  return (
    <>
      <Header />
      <GlobalSearch.FullSearch
        history={history}
        initialSearch={
          location.state.query !== undefined ? location.state.query : ""
        }
      />
    </>
  );
};

export default SearchPage;
