import React, { useState, useEffect, Fragment } from "react";
import CommonFunctions from "../../CommonFunctions";
import Header from "../../components/Header";
import API from "../../api";
import { CheckCircleIcon, CheckIcon, GlobeAltIcon, GlobeIcon, MailIcon, MailOpenIcon,PlusCircleIcon,PlusIcon,PrinterIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import { Button } from "../../components/Forms";
import { GiMagnifyingGlass, GiMailbox, GiWorld } from "react-icons/gi";
import dayjs from "dayjs";
import { Dialog, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { Authentication } from "../../plugins/clio_auth";
import Loader from "../../components/Loader";

export default function Home({ history }) {
  const [
    isContactSyncing,
    // setContactSyncing
  ] = useState(false);

  const [notifications, setNotifications] = useState([]);
  const [openModal, setModalOpen] = useState(false);
  const [newNotification, setNewNotification] = useState({
    title: "New Notification",
    active: true,
    type: "message",
    content: "Text here...",
    user: null
  });
  const [users, setUsers] = useState([]);
  const [loadingNotifications, setLoadingNotificiations] = useState(true);

  const getData = () => {
    Promise.all([
      API.users.getAll()
    ]).then(res => {
      setUsers(res[0]);
    }).catch(err => {
      if(err.response !== undefined){
        console.log(err.response.data.message);
      } else {
        console.log("There was an unexpected error while trying to retrieve the users from the server.");
      }
    });
  };
    

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (isContactSyncing === false) {
      Promise.all([
        API.notifications.getAll().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        console.log("🚀 ~ file: list.js:23 ~ ]).then ~ res:", res)
        setNotifications(res[0]);
      }).finally(() => setLoadingNotificiations(false));
    }
  }, [isContactSyncing]);

  /*
  const emails = [
    { 
      _id: 1,
      title: 'LBA Task',
      client: "Eliot Gannon (egannon@liquid-computing.co.uk)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: null,
      readAt: null,
      read: false,
      completed: false,
    },
    { 
      _id: 1,
      title: 'CCBC Claim Followup',
      client: "Tim Nolan (tnolan@liquid-computing.co.uk)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: null,
      readAt: "DD/MM/YYYY HH:mm:ss",
      read: true,
      completed: false,
    },
    { 
      _id: 1,
      title: 'CCBC Claim Followup',
      client: "John Smith (jsmith@test123.com)",
      createdAt: "DD/MM/YYYY HH:mm:ss",
      completedAt: "DD/MM/YYYY HH:mm:ss",
      readAt: "DD/MM/YYYY HH:mm:ss",
      read: true,
      completed: true,
    },
  ]; */

  const renderModal = () => {

    const closeModal = () => {
      setModalOpen(false);
    }

    const form = () => {
      return (
        <form>
          <div className="space-y-12 sm:space-y-16">    
            <div>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                 Fill out relevent details for the notification
              </p>

              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Title
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="title"
                      id="title"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={newNotification.title}
                      onChange={(e) => setNewNotification({...newNotification, title: e.target.value}) }
                    />
                  </div>
                </div>

              {Authentication.can("notifications.global") &&
                <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
                    <div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                      Notification Type
                    </div>
                    
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <div className="max-w-lg">
                        <p className="text-sm leading-6 text-gray-600">Default is Message but admins see this option of either message or Global. Global notifications show for all users and only when the marked as Active and not Archived.</p>
                        <div className="mt-6 space-y-6">
                          <div className="flex items-center gap-x-3">
                            <input
                              id="type_message"
                              name="type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                              checked={newNotification.type == "message"}
                              onChange={() => setNewNotification({...newNotification, type: "message"}) }
                            />
                            <label htmlFor="type_message" className="block text-sm font-medium leading-6 text-gray-900">
                              Message
                            </label>
                          </div>
                          <div className="flex items-center gap-x-3">
                            <input
                              id="type_global"
                              name="type"
                              type="radio"
                              className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                              checked={newNotification.type == "global"}
                              onChange={() => setNewNotification({...newNotification, type: "global"}) }
                            />
                            <label htmlFor="type_global" className="block text-sm font-medium leading-6 text-gray-900">
                              Global Notification
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }

              {newNotification.type === "global" &&
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-6">
                <div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  &nbsp;
                </div>
                <div className="mt-4 sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg space-y-6">
                    <div className="relative flex gap-x-3">
                      <div className="flex h-6 items-center">
                        <input
                          id="active"
                          name="active"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                          checked={newNotification.active}
                          onChange={() => setNewNotification({...newNotification, active: !newNotification.active}) }
                        />
                      </div>
                      <div className="text-sm leading-6">
                        <label htmlFor="active" className="font-medium text-gray-900">
                          Active
                        </label>
                        <p className="mt-1 text-gray-600">Global Notification active to been seen, only will show when active.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              {newNotification.type == "message" &&
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    User
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <select
                      id="user"
                      name="user"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={newNotification.user}
                      onChange={(e) => setNewNotification({...newNotification, user: e.target.value}) }
                    >
                      {users.map(user => <option value={user._id}>{user.name}</option>)}
                    </select>
                  </div>
                </div>}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                  <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    Message
                  </label>
                  <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <textarea
                      id="content"
                      name="content"
                      rows={7}
                      className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={newNotification.content}
                      onChange={(e) => setNewNotification({...newNotification, content: e.target.value}) }
                    />
                  </div>
                </div>
    
              </div>
            </div>
    
          </div>
        </form>
      )
    }

    return (
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => closeModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Create Notification Message
                      </Dialog.Title>
                      <div className="mt-2">
                        {form()}
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() => API.notifications.add(newNotification).then((res) => {
                        if(newNotification.type == "global" && newNotification.active){
                          toast.success("Global message live and visible to all users!");
                        }
                        toast.success("Notification added successfully");
                        closeModal();
                        getData();
                      })}
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }

  return (
    <div className="w-full h-full">
      {renderModal()}
      <main className="flex-1 pb-8">
        {/* <div className="grid sm:grid-cols-3 gap-4 p-4"> */}
        {/* <div className="col-span-2"> */}
        <Header
          title={"Notifications"}
          body={
            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium capitalize sm:mr-2 sm:ml-0 sm:mt-0">
                <GiMailbox
                  className="flex-shrink-0 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                {notifications.length > 0 ? notifications.filter(e => e.read !== true).length : 0} Unread Notification(s)
              </dd>
              {/*  
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium sm:ml-0 sm:mt-0">
                <MailOpenIcon
                  className="flex-shrink-0 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.read === true && e.completed !== true && e.status !== "Cancelled").length : 0} Read & Unapproved Email(s)
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium sm:ml-0 sm:mt-0">
                <CheckIcon
                  className="flex-shrink-0 h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.completed === true && e.status !== "Cancelled").length : 0} Approved Email(s)
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium sm:ml-0 sm:mt-0">
                <XIcon
                  className="flex-shrink-0 h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
                {emails.length > 0 ? emails.filter(e => e.status === "Cancelled").length : 0} Cancelled Email(s)
              </dd>*/}
            </dl>
          }
        />

        {loadingNotifications ? <Loader /> : 

        <div className="px-4 sm:px-6 lg:px-8">
          <div className="float-right">
          <button className="relative flex items-center justify-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            setModalOpen(true);
          }}>
            <PlusCircleIcon className="flex-shrink-0 h-5 w-5 text-white"
                        aria-hidden="true"/> New Notification
          </button>
          </div>
          <h2 className="mt-4 text-lg font-semibold">Notifications</h2>
          
          <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    To
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Notification Title
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    From
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date Created
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {!notifications.filter(e => e.completed !== true && e.status !== "Cancelled").length > 0 && <tr>
                  <td colSpan={6} className="px-3 py-4 text-sm text-gray-500 text-center">
                    No Notifications
                  </td>
                </tr>}
                {notifications.filter(e => e.completed !== true && e.status !== "Cancelled").map((item) => {
                    let actionedBy = users.find((u) => u._id == item.actionedBy);
                    let actionedFor = users.find((u) => u._id == item.actionedFor);
                    return (
                      <tr key={item._id}>
                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                          {item.type === "global" && <GiWorld className="flex-shrink-0 h-5 w-5 inline-block"
                          aria-hidden="true" />}
                          {item.type === "global" ? " - Visible to All -" : actionedFor && actionedFor.name}
                          {/*
                          item.postbox_type == "print" ? <PrinterIcon className="flex-shrink-0 h-5 w-5"
                          aria-hidden="true" /> :
                          item.read ?
                          <MailOpenIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-300"
                            aria-hidden="true"
                          /> : 
                          <MailIcon 
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                      />*/}
                          
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                          {item.title}
                            <dl className="font-normal lg:hidden">
                              <dt className="sr-only">From</dt>
                              <dd className="mt-1 truncate text-gray-700">{actionedBy && actionedBy.name}</dd>
                              <dt className="sr-only sm:hidden">Date</dt>
                              <dd className="mt-1 truncate text-gray-500 sm:hidden">{item.createdAt}</dd>
                            </dl>
                        </td>
                        <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{actionedBy && actionedBy.name}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.createdAt}</td>
                        <td className="px-3 py-4 text-sm text-gray-500">{item.type === "global" ? "Global Message" : item.read ? `Read (${item.readAt})` : "Unread"}</td>
                        <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Button
                            colour="blue"
                            label="View"
                            onClick={() => history.push(`/notifications/${item._id}`)}
                            className="ml-4 text-sm"
                            icon={GiMagnifyingGlass}
                          />
                        </td>
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </div>
        </div> }

        {/* </div> */}
      </main>
    </div>
  );
}
