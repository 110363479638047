import React, { useState, useEffect, Fragment } from "react";
import { BookOpenIcon, PlusIcon, XIcon, DocumentTextIcon } from "@heroicons/react/solid";
import Segment from "../../../components/Segment";
import { Button, SelectTwo, SelectZero } from "../../../components/Forms";
import AddressList from "./_fields/addressList";
import EmailList from "./_fields/emailList";
import PhoneList from "./_fields/phoneList";
import WebsiteList from "./_fields/websiteList";
import { Dialog, Switch, Transition } from "@headlessui/react";
import API from "../../../api";
import ProspectsBreadcrumbs from "../components/ProspectsBreadcrumbs";
import toast from "react-hot-toast";

const contactTypes = [
  { key: 0, text: "Person", value: "Person" },
  { key: 1, text: "Company", value: "Company" },
];

const companySuffixes = [
  { key: 0, text: "-", value: " " },
  { key: 1, text: "Ltd", value: "Ltd" },
  { key: 2, text: "LLP", value: "LLP" },
  { key: 3, text: "plc", value: "plc" },
  { key: 4, text: "sole trader", value: "sole trader" },
  { key: 5, text: "Charity", value: "Charity" },
];

const ContactForm = ({ contact = {}, onSubmit, onCancel, add = false }) => {
  const [salutations, setSalutations] = useState([]);
  const [data, setData] = useState({
    type: "Company",
    name: "",
    prefix: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    addresses: [],
    email_addresses: [],
    phone_numbers: [],
    web_sites: [],
    companyRegistrationNumber: "",
  });
  const [creditReports, setCreditReports] = useState([]);
  const [activeReport, setActiveReport] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [salutationsLoaded, setSalutationsLoaded] = useState(false);

  const [creditLoading, setCreditLoading] = useState(false);

  const [creditSearchIsOpen, setCreditSearchIsOpen] = useState(false);
  const [creditSearchResult, setCreditSearchResult] = useState({
      totalSize: 0,
      companies: [],
    });

  useEffect(() => {

    if(!salutationsLoaded){
      setSalutationsLoaded(true)
      API.settings.salutations.get().then(res => {
        setSalutations(res.map(s => {
          return {
            text: s.title,
            value: s.title
          }
        }))
      })
    }

    if (contact._id) { 
      setData(contact);
    }
  }, [contact]);

  useEffect(() => {
    if (modalOpen === true) {
      setCreditLoading(true);
      API.external_providers.credit_safe
        .searchCompanies({
          countries: "GB",
          name: data.name,
        })
        .then((res) => {
          setCreditReports(res.companies ? res.companies : []);
          setCreditLoading(false);
        });
    }
  }, [modalOpen]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // TODO form validation

    onSubmit(data);
  };

  const handleStartCreditCheck = () => {
    API.external_providers.credit_safe
      .searchCompanies({
        countries: "GB",
        name: data.name,
      })
      .then((res) => {
        setCreditSearchIsOpen(true);
        setCreditSearchResult(res);
      });
  };

  const companySearchDialog = () => {
    return (
      <Transition
        show={creditSearchIsOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={creditSearchIsOpen}
          onClose={() => setCreditSearchIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-7xl p-4 my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="pt-2l">
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-medium text-gray-900 border-b border-gray-400 py-2"
                >
                  Companies
                </Dialog.Title>
              </div>
              <div>{renderCreditCheckSearchResults()}</div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const handleGetCreditReport = (type, resultItem) => () => {

    API.prospective_contact.credit_checks.newReport(contact._id, type,resultItem.id)
      .then(() => {
        toast.success("Credit Report ran and linked to contact");
        setCreditSearchIsOpen(false);
        setCreditSearchResult({
          totalSize: 0,
          companies: [],
        });
      });
  };

  const renderCreditCheckSearchResults = () => {
    if (creditSearchResult.companies.length) {
      return (
        <div>
          <h2>
            Company Search Results for "{data.name}"
          </h2>

          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-900">
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Reg Num</th>
                <th>Vat Num</th>
                <th>Address</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-black divide-y divide-gray-200">
              {creditSearchResult.companies.map((item) => {
                return (
                  <tr key={item.id} className="bg-white dark:bg-black">
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.regNo}</td>
                    <td>
                      {item.vatNo !== undefined ? item.vatNo.join(", ") : ""}
                    </td>
                    <td>{item.address.simpleValue}</td>
                    <td>
                      <Button
                        colour="amber"
                        label="Get JSON"
                        onClick={handleGetCreditReport("json", item)}
                        icon={DocumentTextIcon}
                      />
                      {/*<Button*/}
                      {/*    colour="blue"*/}
                      {/*    label="Get PDF"*/}
                      {/*    onClick={handleGetCreditReport("pdf", item)}*/}
                      {/*    icon={DocumentIcon}*/}
                      {/*/>*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  const renderModal = () => {
    const handleClose = () => {
      setModalOpen(false);
    };

    const handleModalSubmit = () => {
      setData({
        ...data,
        name: activeReport.name ? toTitleCase(activeReport.name) : "",
        companyRegistrationNumber: activeReport.regNo,
        client_reference: activeReport.client_reference,
        bank_account: activeReport.bank_account,
        bank_sort: activeReport.bank_sort,
        letter_salutation: activeReport.letter_salutation,
        vatRegistrationNumber: activeReport.vatNo ? activeReport.vatNo[0] : "",
        phone_numbers: activeReport.phoneNumbers
          ? activeReport.phoneNumbers.map((item) => ({
              number: item,
              name: "Work",
              primary: false,
            }))
          : [],
        addresses: activeReport.address
          ? [
              {
                city: activeReport.address.city,
                country: "United Kingdom",
                name: "Work",
                postal_code: activeReport.address.postCode,
                primary: false,
                province: "",
                street: activeReport.address.street,
              },
            ]
          : [],
      });
      toast.success("Data imported successfully");
      setModalOpen(false);
      if(!add){
        handleStartCreditCheck();
      }
    };

    function toTitleCase(str) {
      return str.toLowerCase().split(' ').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    }
    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Credit Lookup For {data.name} (
                      {data.companyRegistrationNumber})
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`overflow-x-auto text-lg`}>
                        <table
                          className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed`}
                        >
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Reg Number
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Vat Number
                              </th>
                              <th
                                scope="col"
                                className="tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left"
                              >
                                Address
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-300">
                            {creditLoading ? <tr>
                              <td colSpan={"5"} className="text-center">
                              <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-black inline-block" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg>

                              Loading...
                              </td>
                            </tr> : creditReports.length > 0 ? (
                              creditReports.map((item, index) => (
                                <tr
                                  className={`hover:bg-gray-100 focus:bg-gray-100 cursor-pointer ${
                                    activeReport.id === item.id
                                      ? "bg-gray-300 hover:bg-gray-400 focus:bg-gray-400"
                                      : ""
                                  }`}
                                  onClick={() => setActiveReport(item)}
                                  key={index}
                                >
                                  <td className="px-4 py-4">{item.name}</td>
                                  <td className="px-4 py-4">{item.status}</td>
                                  <td className="px-4 py-4">{item.regNo}</td>
                                  <td className="px-4 py-4">{item.vatNo}</td>
                                  <td className="px-4 py-4">
                                    {item.address && item.address.simpleValue}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr className={`text-black `}>
                                <td colSpan={5} className="px-4 py-4">
                                  No Credit Reports...
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleModalSubmit}
                  >
                    Import Data
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  let classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <div>
      {renderModal()}
      {companySearchDialog()}
      <ProspectsBreadcrumbs panes={
        add ? [{name: `Add`, current: true}] : [
        { name: `Prospect - ${`${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/prospective-contacts/${contact._id}`, current: false },
        { name: `Details`, current: false, href: `/prospective-contacts/${contact._id}/details` },
        { name: `Edit`, current: true },
      ]} />

      <Segment padding="px-4 mb-5 mt-4">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Contact Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Details for all contact info
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact Type
                      </label>
                      <Switch.Group
                        as="div"
                        className="flex items-center pt-2"
                      >
                        <Switch
                          checked={data.type === "Person"}
                          onChange={() =>  setData({ ...data, type: data.type === "Person" ? "Company" : "Person" })}
                          className={classNames(
                            data.type === "Person" ? "bg-primary" : "bg-gray-200",
                            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classNames(
                              data.type === "Person"
                                ? "translate-x-5"
                                : "translate-x-0",
                              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            )}
                          />
                        </Switch>
                        <Switch.Label as="span" className="ml-3">
                          <span className="text-sm font-medium text-gray-900">
                            {data.type === "Person" ? "Individual" : "Company"}
                          </span>
                        </Switch.Label>
                      </Switch.Group>
                      {/*  
                      <SelectTwo
                        placeholder="Please Select Type"
                        field="type"
                        options={contactTypes}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={data.type}
                        onChange={onChange}
                      />*/}
                    </div>
                  </div>
                  {data.type === "Company" && (
                    <>
                      <div className="grid grid-cols-3 gap-6">
                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={onChange}
                            id="name"
                            autoComplete="name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Suffix
                          </label>
                          <SelectTwo
                            placeholder="Select Suffix"
                            field="suffix"
                            options={companySuffixes}
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={data.suffix}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="companyRegistrationNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Registration Number
                          </label>
                          <input
                            id="companyRegistrationNumber"
                            type="text"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="companyRegistrationNumber"
                            value={data.companyRegistrationNumber}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="vatRegistrationNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            VAT Number
                          </label>
                          <input
                            type="text"
                            id="vatRegistrationNumber"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="vatRegistrationNumber"
                            value={data.vatRegistrationNumber}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="mt-1 flex items-center">
                          <button
                            type="button"
                            onClick={() => {
                              setModalOpen(true);
                            }}
                            disabled={
                              // data.name && data.companyRegistrationNumber
                              data.name 
                                ? false
                                : true
                            }
                            className={`${data.name ? `bg-white hover:bg-green-50` : `bg-gray-400`} ml-5 rounded-md border border-gray-300 py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                          >
                            <BookOpenIcon className="w-6 h-6 inline-block" />{" "}
                            Credit Lookup
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {data.type === "Person" && (
                    <>
                      <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="prefix"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Salutation
                          </label>
                          <SelectZero
                            name="prefix"
                            value={data.prefix}
                            options={salutations}
                            onChange={onChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="first_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            value={data.first_name}
                            onChange={onChange}
                            id="first_name"
                            autoComplete="first name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="middle_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Middle Name
                          </label>
                          <input
                            id="middle_name"
                            type="text"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="middle_name"
                            value={data.middle_name}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="last_name"
                            value={data.last_name}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="grid grid-cols-4 gap-6">
                      <div className="col-span-6 sm:col-span-1">
                        <label
                          htmlFor="bank_account"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bank Account Number
                        </label>
                        <input
                          type="text"
                          name="bank_account"
                          value={data.bank_account}
                          onChange={onChange}
                          id="bank_account"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-1">
                        <label
                          htmlFor="bank_sort"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bank Sort Code
                        </label>
                        <input
                          format="## - ## - ##"
                          type="text"
                          name="bank_sort"
                          value={data.bank_sort}
                          onChange={(e) => {
                            let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                            input = input.slice(0, 6); // Limit to a maximum of 6 characters
                            const formattedInput = input.replace(/^(.{2})(.{2})(.{2})$/, '$1-$2-$3'); // Add dashes
                            setData({ ...data, [e.target.name]: formattedInput });
                          }}
                          id="bank_sort"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-1">
                        <label
                          htmlFor="client_reference"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Client Reference
                        </label>
                        <input
                          type="text"
                          name="client_reference"
                          value={data.client_reference}
                          onChange={onChange}
                          id="client_reference"
                          autoComplete="client_reference"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-1">
                        <label
                          htmlFor="letter_salutation"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Letter Salutation
                        </label>
                        <input
                          type="text"
                          name="letter_salutation"
                          value={data.letter_salutation}
                          onChange={onChange}
                          id="letter_salutation"
                          autoComplete="letter_salutation"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Communications
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Contact details including Email Addresses, Phone Numbers and
                  Websites
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <EmailList
                emails={data.email_addresses || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    email_addresses: a,
                  });
                }}
              />
              <PhoneList
                phoneNumbers={data.phone_numbers || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    phone_numbers: a,
                  });
                }}
              />
              <WebsiteList
                websites={data.web_sites || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    web_sites: a,
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Addresses
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Contact Addresses details
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <AddressList
                addresses={data.addresses || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    addresses: a,
                  });
                }}
                showValidationErrors={false}
              />{" "}
              {/** TODO pass the show validation as needed **/}
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="py-2 flex justify-between">
          <Button
            label="Cancel"
            onClick={() => onCancel()}
            colour="red"
            icon={XIcon}
          />
          <Button
            className="block ml-auto mr-0"
            label={contact._id !== undefined ? "Update" : "Create"}
            onClick={handleSubmit}
            colour="green"
            icon={PlusIcon}
          />
        </div>
      </Segment>
    </div>
  );
};

export default ContactForm;
