import React from "react";
import Context from "./Matter_Context";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";

class Matter_Receiver extends React.Component {
  state = {
    firstLoad: true,
    matter: {},
  };

  componentDidMount() {
    this._refresh();
  }

  _refresh = () => {
    this.props.api.matters
      .get(this.props.match.params.matter_id)
      .then((res) => {
        this.setState({
          matter: res,
          firstLoad: false,
        });
      })
      .finally(() => {});
  };

  render() {
    return (
      <Context.Provider
        value={{
          _refresh: this._refresh,
          ...this.state.matter,
        }}
      >
        {this.state.firstLoad ? (
          <Loader>Loading Matter</Loader>
        ) : (
          this.props.children
        )}
      </Context.Provider>
    );
  }
}

export default withRouter(Matter_Receiver);
