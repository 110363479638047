import React from "react";
import CreditChecks from "./components/creditChecks";
import ChainedContext from "../../contexts/chainedContext";
import ProspectiveContactReceiver from "../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import API from "../../api";

const CreditCheck = ({ contact }) => {
  const getAllReports = async () => {
    return API.prospective_contact.credit_checks.all(contact._id);
  };
  const getSingleReport = async (reportId) => {
    return API.prospective_contact.credit_checks.report(contact._id, reportId);
  };
  const requestReport = async (type, resultItem) => {
    return API.prospective_contact.credit_checks
      .newReport(contact._id, type, resultItem)
      .then(() => {
        // Do nothing
      });
  };

  return (
    <CreditChecks
      routePrefix={"/prospective-contacts/:contact_id"}
      getAllReports={getAllReports}
      requestReport={requestReport}
      getSingleReport={getSingleReport}
      defaultSearchName={contact.name}
    />
  );
};
export default ChainedContext(CreditCheck, [
  [ProspectiveContactReceiver, "contact"],
]);
