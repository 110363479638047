import React, { useState, useEffect } from "react";
import Segment from "../../../components/Segment";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../api";
import MatterBar from "../_components/MatterBar";
import toast from "react-hot-toast";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import CommonFunctions from "../../../CommonFunctions";
import Add_Payment_Form from "../debtors/debt/_forms/Add_Payment_Form";
import Loader from "../../../components/Loader";
function AddPayment({ matter, history }) {

  const [loading, setLoading] = useState(true);
  const [mountLoading, setMountLoading] = useState(true);
  const [baseRate, setBaseRate] = useState({});
  const [debts, setDebts] = useState([]);

  const [matterDebtors, setMatterDebtors] = useState({});

  useEffect(() => {
    API.matters.debtors
      .getAll(matter._id)
      .then((res) => {
        setMatterDebtors(res);  
        API.matters.debtors.debt.getAll(matter._id, 0, res.debtors ? res.debtors.map(d => d._id) : []).then((r) => {
          setDebts(r);
          setMountLoading(false);
        });      
      })
        
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  useEffect(() => {
    if(loading)
      API.settings.base_rates.normal.get().then((data) => {
        if(data[0]){
          setBaseRate(data[0]);
        }
        setLoading(false);
      });
  }, [baseRate]);
  const handleData = (data) => {
    API.matters.debtors.debt.addTransaction(matter._id, null,null, {...data, multiple: true}).then((res) => {
      if(res.success){
        toast.success(`Payment Added Successfully`);
        history.push(`/matters/${matter._id}/debts`);
      }else{
        toast.error(`Payment Error - ${res.message}`);
      }
    }).catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <div className="w-full  ">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Debts", href: `/matters/${matter._id}/debts`,},
        { name: "Add Payment", href: `#`, current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        {mountLoading ? <Loader /> :
        <Add_Payment_Form
          data={handleData}
          debts={debts}
        />}
      </Segment>
    </div>
  );
}
export default ChainedContext(AddPayment, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
