import React, { useEffect, useState } from "react";
import { Button, Group, Input, SelectTwo } from "../../../../components/Forms";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
const defaultData = {
  address: "",
  name: "Work",
};

const WebsiteList = ({ onChange, websites }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState({
    ...defaultData,
  });

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const onTypeChange = (val) => setData({ ...data, name: val.target.value });
  const handleFormSubmit = () => {
    if (editIndex !== null) {
      onChange(
        websites.map((item, index) => {
          if (index !== editIndex) return item;
          return {
            ...item,
            ...data,
          };
        })
      );
      setEditIndex(null);
    } else {
      onChange(
        [
          {
            ...data,
          },
        ].concat(websites)
      );
    }
    setData({
      ...defaultData,
    });
  };
  const handleAddressEditButton = (index) => () => {
    setData(websites[index]);
    setEditIndex(index);
  };
  const handleAddressDeleteButton = (index) => () => {
    onChange(websites.filter((a, i) => i !== index));
  };

  const renderInputFields = () => {
    return (
      <React.Fragment>
        <div className="py-2 flex gap-2 items-end  justify-between">
          <div className="w-full">
            <Input
              label="Web Address"
              name="address"
              value={data.address}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-52">
            <SelectTwo
              label="Type"
              placeholder="Please Select Type"
              field="name"
              value={data.name}
              options={[
                { key: 0, text: "Work", value: "Work" },
                { key: 1, text: "Personal", value: "Personal" },
                { key: 2, text: "Twitter", value: "Twitter" },
                { key: 3, text: "Facebook", value: "Facebook" },
                { key: 4, text: "LinkedIn", value: "LinkedIn" },
                {
                  key: 5,
                  text: "Instant Messenger",
                  value: "Instant Messenger",
                },
                { key: 6, text: "Other", value: "Other" },
              ]}
              onChange={onTypeChange}
            />
          </div>
          <div className="w-20">
            {editIndex !== null && (
              <Button
                colour={"subtle"}
                label={`Cancel`}
                onClick={() => {
                  setData({
                    ...defaultData,
                  });
                  setEditIndex(null);
                }}
                className="mb-1"
              />
            )}
            <Button
              colour={"green"}
              label={`${editIndex !== null ? "Update" : "Add"}`}
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {renderInputFields()}
      {websites.length === 0 ? (
        <div>
          There are no websites to display, create one using the form above.
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 table-auto">
          <thead>
            <tr>
              <th className="text-left">Website</th>
              <th className="text-center ">Type</th>
              <th className="text-center ">{""}</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {websites.map((address, index) => {
              return (
                <tr key={index}>
                  <td className="p-2  w-3/6">{address.address}</td>
                  <td className="p-2 text-center  w-2/6 ">{address.name}</td>
                  <td className=" w-1/6  ">
                    <div className="flex gap-2 p-2  justify-end">
                      <Button
                        colour={"red"}
                        label={<TrashIcon className="h-4" />}
                        onClick={handleAddressDeleteButton(index)}
                      />
                      <Button
                        colour={"blue"}
                        label={<PencilIcon className="h-4" />}
                        onClick={handleAddressEditButton(index)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default WebsiteList;
