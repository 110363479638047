import React from "react";
import ProspectiveContactReceiver from "../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import Segment from "../../components/Segment";
import { GiHouse } from "react-icons/gi";
import {
  UserIcon,
  OfficeBuildingIcon,
  ReceiptTaxIcon,
  PhoneIcon,
  MailIcon,
} from "@heroicons/react/solid";
import { Button } from "../../components/Forms";
import Checklist from "../../components/_checklists";
import API from "../../api";
import ProspectsBreadcrumbs from "./components/ProspectsBreadcrumbs";

function ContactDash({ contact, history }) {
  return (
    <>
    <ProspectsBreadcrumbs panes={[
        { name: `Prospect - #${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/prospective-contacts/${contact._id}`, current: true }
      ]} />
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="py-2">
            {/* Page header */}
            <div className="mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-primary">
                    {contact.name}{contact.suffix ? " " + contact.suffix : ""}
                  </h1>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <UserIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type ? contact.type : "--"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <GiHouse
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.addresses &&
                      contact.addresses.find((item) => item.primary === true)
                        ? `${
                            contact.addresses.find(
                              (item) => item.primary === true
                            ).postal_code
                          } (${
                            contact.addresses.find(
                              (item) => item.primary === true
                            ).name
                          })`
                        : "No Primary Address"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <OfficeBuildingIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type === "Company"
                        ? contact.companyRegistrationNumber
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <ReceiptTaxIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.type === "Company"
                        ? contact.vatRegistrationNumber
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <PhoneIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                        {contact.phone_numbers &&
                        contact.phone_numbers.find((item) => item.primary === true)
                          ? `${
                              contact.phone_numbers.find(
                                (item) => item.primary === true
                              ).number
                            } (${
                              contact.phone_numbers.find(
                                (item) => item.primary === true
                              ).name
                            })`
                          : "No Primary Number"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <MailIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {contact.email_addresses &&
                        contact.email_addresses.find((item) => item.primary === true)
                          ? `${
                              contact.email_addresses.find(
                                (item) => item.primary === true
                              ).address
                            } (${
                              contact.email_addresses.find(
                                (item) => item.primary === true
                              ).name
                            })`
                          : "No Primary Number"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Button
                  onClick={() =>
                    history.push(`/prospective-contacts/${contact._id}/edit`)
                  }
                  label="Edit Prospective"
                  colour="blue"
                />
              </div>
            </div>
            {/* Main Content */}
            <div className="mt-4 mx-auto md:grid md:grid-cols-2">
              <Checklist
                type="prospective_contact"
                parentId={contact._id}
                handleClick={(checklist_id) => {
                  history.push(`/prospective-contacts/${contact._id}/activities`)
                }}
                createTask={async (checklist_id) =>
                  await Promise.all([
                    API.settings
                      .checklist("prospective_contact")
                      .createChecklistTask(contact._id, checklist_id),
                  ])
                }
              />
            </div>
          </main>
        </Segment>
      </div>
      <div>
        {/* <Segment>
        <div className="flex">
          <DataField
            label={"Contact Name"}
            data={
              contact.type === "Company"
                ? contact.name
                : `${contact.first_name}${
                    contact.middle_name ? ` ${contact.middle_name}` : ""
                  } ${contact.last_name}`
            }
          />

          <DataField
            label={"Company Num"}
            data={
              contact.type === "Company"
                ? contact.companyRegistrationNumber
                : "N/A"
            }
          />
          <DataField label={"VAT Num"} data={contact.vatRegistrationNumber} />
          <DataField label={"Contact Type"} data={contact.type} />
        </div>
        <div>
        <DataField
          label={"Primary Contact Methods"}
          showOnUndefined={false}
        />
      </div>
      <div className="flex">
        <DataField
          label={"Phone Number"}
          data={
            contact.phone_numbers &&
            contact.phone_numbers.find((item) => item.primary === true)
              ? `${
                  contact.phone_numbers.find((item) => item.primary === true)
                    .number
                } (${
                  contact.phone_numbers.find((item) => item.primary === true)
                    .name
                })`
              : "N/A"
          }
        />
        <DataField
          label={"Email"}
          data={
            contact.email_addresses &&
            contact.email_addresses.find((item) => item.primary === true)
              ? `${
                  contact.email_addresses.find(
                    (item) => item.primary === true
                  ).address
                } (${
                  contact.email_addresses.find(
                    (item) => item.primary === true
                  ).name
                })`
              : "N/A"
          }
        />
        <DataField
          label={"Address"}
          data={
            contact.addresses &&
            contact.addresses.find((item) => item.primary === true)
              ? `${
                  contact.addresses.find((item) => item.primary === true)
                    .postal_code
                } (${
                  contact.addresses.find((item) => item.primary === true).name
                })`
              : "N/A"
          }
        />
        <DataField
          label={"Websites"}
          data={
            contact.web_sites &&
            contact.web_sites.find((item) => item.default_web_site === true)
              ? `${
                  contact.web_sites.find(
                    (item) => item.default_web_site === true
                  ).address
                } (${
                  contact.web_sites.find(
                    (item) => item.default_web_site === true
                  ).name
                })`
              : "N/A"
          }
        />
      </div>
      </Segment> */}
      </div>
    </>
  );
}

export default ChainedContext(ContactDash, [
  [
    ProspectiveContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
