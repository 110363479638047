export default function Segment({ padding = "", component = "", children }) {
  return (
    <div className={`${padding ? `${padding}` : "p-4"} `}>
      <div
        className={`border border-primary bg-white rounded-lg p-4 ${component}`}
      >
        {children}
      </div>
    </div>
  );
}
