import React from "react";
import ContactForm from "./forms/contactForm";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";

const AddContact = ({ history }) => {
  const handleSubmit = (data) => {
    API.contacts
      .create(data)
      .then((res) => {
        history.push("/contacts/" + res._id);
      })
      .catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <div className="pb-8">
      <div
        className={`border-b border-secondary bg-white dark:bg-gray-900 shadow h-20`}
      >
        <div className="w-full rounded-md flex gap-4 items-center">
          <div className="p-4 text-2xl rounded-t-md">Creating New Client</div>
        </div>
      </div>
      <ContactForm
        onCancel={() => history.push("/contacts")}
        onSubmit={handleSubmit}
        add={true}
      />
    </div>
  );
};
export default AddContact;
