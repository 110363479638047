import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { Button, Input, SelectTwo } from "../../../components/Forms";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

const BaseRatesTable = ({ rates, onCreate, onUpdate, onDelete = () => {} }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    fee: "0.01",
    threshold: "0.01",
    over: false,
    type: "fixed"
  });
  const [submitError, setSubmitError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    let errors = {};
    if (parseFloat(formData.fee) < 0.01) {
      errors.fee = "Fee must be grater then 0.01";
    }
    if (formData.fee === "") {
      errors.fee = "Fee can not be empty";
    }

    if (formData.threshold === "") {
      errors.threshold = "Threshold can not be empty";
    }

    setFormErrors(errors);
  }, [formData]);

  const editing = formData._id !== undefined;

  const handleRateOnChange = (e) => {
    if (e.target.value.match(/^\d+(\.\d{0,2})?$/g) || e.target.value === "") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value + "",
      });
    }
  };
  const handleFormSubmit = () => {
    setFormIsSubmitting(true);
    setSubmitError(null);
    let apiAction;
    if (formData._id === undefined) {
      // Create new
      apiAction = onCreate(formData);
    } else {
      // Editing
      apiAction = onUpdate(formData._id, formData);
    }

    setFormData({
      fee: "0.01",
      threshold: "0.01",
      over: false,
      type: "fixed"
    });

    setFormIsSubmitting(false);

  };
  const handleDeleteSubmit = () => {
    setShowDelete(false);
    setFormIsSubmitting(true);
    setSubmitError(null);
    onDelete(formData._id);

    setFormData({
      fee: "0.01",
      threshold: "0.01",
      over: false,
      type: "fixed"
    }); // Reset the form

    setFormIsSubmitting(false);
  };

  return (
    <div>
      {submitError && (
        <div
          className={"p-2 border rounded border-red-800 bg-red-700 text-white"}
        >
          {submitError}
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={true}
          header={"Are you sure?"}
          content={
            "Are you sure you like to remove this Threshold, this action can't be undone."
          }
          handleSubmit={handleDeleteSubmit}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Threshold
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Over Threshold
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Fee
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {formData.over ? "Over" : "Up To"}: £
                      <Input
                        name="threshold"
                        value={formData.threshold}
                        onChange={handleRateOnChange}
                        error={formErrors.threshold}
                        disabled={formIsSubmitting}
                      />
                      {formErrors.threshold && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.threshold}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <input
                        id={"over"}
                        aria-describedby="comments-description"
                        name={"over"}
                        type="checkbox"
                        onChange={() => setFormData({...formData, over: formData.over ? !formData.over : true })}
                        checked={formData.over}
                        className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                      />
                      {formErrors.fee && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.fee}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <Input
                        name="fee"
                        value={formData.fee}
                        onChange={handleRateOnChange}
                        error={formErrors.fee}
                        disabled={formIsSubmitting}
                      />
                      {formErrors.fee && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.fee}
                        </span>
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <select
                        name="type"
                        value={formData.type}
                        disabled={formIsSubmitting}
                        error={formErrors.type}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                        placeholder={"Select"}
                        onChange={(e) => setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })}
                      >
                        <option value={"fixed"}>
                            Fixed
                        </option>
                        <option value={"percent"}>
                            %
                        </option>
                      </select>
                       
                      {formErrors.type && (
                        <span className={"p-1 text-red-500"}>
                          {formErrors.type}
                        </span>
                      )}
                    </th>
                    <th scope="col" className="py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="isolate inline-flex rounded-md shadow-sm">
                        {editing && (
                          <Button
                            className="mr-4"
                            label={""}
                            colour={"red"}
                            icon={TrashIcon}
                            disabled={
                              Object.keys(formErrors).length > 0 ||
                              formIsSubmitting
                            }
                            onClick={() => {
                              setShowDelete(true);
                            }}
                          />
                        )}
                        <Button
                          label={""}
                          colour={editing ? "blue" : "green"}
                          icon={editing ? PencilIcon : PlusIcon}
                          className={"ml-auto"}
                          disabled={
                            Object.keys(formErrors).length > 0 ||
                            formIsSubmitting
                          }
                          onClick={handleFormSubmit}
                        />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {rates.length ? (
                    rates.map((rate, index) => (
                      <tr key={"rate_" + index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                         {rate.over_threshold ? "Over" : "Up to"}: £{rate.threshold.toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          &nbsp;
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {rate.type == "fixed" && "£"}{rate.fee.toLocaleString()}{rate.type == "percent" && "%"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {rate.type == "fixed" ? "Fixed Fee" : "%"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Button
                            label={""}
                            colour={"blue"}
                            icon={PencilIcon}
                            className={"ml-auto"}
                            onClick={() => {
                              setFormData({
                                _id: rate._id,
                                fee: rate.fee,
                                threshold: rate.threshold,
                                over: rate.over_threshold,
                                type: rate.type
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        There are no items to display.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BaseRatesTable;
