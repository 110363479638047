import Axios from "axios";
import Bridge from "./bridge";
import Authentication from "./authentication";

const applyHeaders   = (extra = {}) => {
  return {
    ...extra,
    headers: {
      ...(extra.headers !== undefined ? extra.headers : {}),
      Authorization: "Bearer " + Authentication.getAccessToken(),
    },
  };
};
const getAuthBaseUrl = () => {
  return Bridge.getConfig("applicationServer") + "/../oauth/clio_auth";
};

const API = {
  loginUrl: redirectUrl => Axios.get(getAuthBaseUrl() + "/login_url?redirectUrl=" + redirectUrl).then(res => res.data),
  codeExchange: (code, redirectUrl) => Axios.post(getAuthBaseUrl() + "/code_exchange", {code, redirectUrl}).then(res => res.data),
  logout: () => Axios.delete(getAuthBaseUrl() + "/token", applyHeaders()).then(res => res.data),
  userDetails: accessToken => Axios.get(getAuthBaseUrl() + "/user_details", {
    headers: {
      Authorization: "Bearer " + accessToken
    }
  }).then(res => res.data),
  heartbeat: accessToken => Axios.get(getAuthBaseUrl() + "/heartbeat", {
    headers: {
      Authorization: "Bearer " + accessToken
    }
  }).then(res => res.data)
};
export default API;