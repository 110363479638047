import React, { useEffect, useState } from "react";
import { Button, Input } from "../../components/Forms";
import Segment from "../../components/Segment";
import ContactReceiver from "../../contexts/settings_context/Settings_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import { ClipboardIcon, PlusIcon, SaveAsIcon, SaveIcon } from "@heroicons/react/solid";
import API from "../../api";
import CronOverview from "./_components/CronOverview.js";
import { GiSave } from "react-icons/gi";

function Dashboard({ settings }) {
  const [localSettings, setLocalSettings] = useState({});
  const [loadingUpdatePractice, setLoadingUpdatePractice] = useState(false);

  useEffect(() => {
    setLocalSettings(settings);
  }, [settings]);

  const updateSettings = () => {
    API.settings.edit(localSettings).then(() => settings._refresh());
  };

  const syncPracticeAreas = () => {
    setLoadingUpdatePractice(true);
    API.settings.syncPracticeAreas().then(() => {
      settings._refresh()
      setLoadingUpdatePractice(false);
    });
  }

  return (
    <div className="sm:grid grid-cols-2">
      <Segment>
        <div className="flex">
          <Input
            type="number"
            label="Default Admin Rate (£/hr)"
            name={"adminRate"}
            value={localSettings.adminRate}
            onChange={(e) =>
              setLocalSettings({
                ...localSettings,
                [e.target.name]: e.target.value,
              })
            }
            className="w-9/12 md:w-10/12"
          />
          <Button
            icon={GiSave}
            colour="green"
            label="Update"
            className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
            onClick={() => updateSettings()}
          />
        </div>
        <br/>
        <div className="flex">
          <div class="w-9/12 md:w-10/12">
            <label class="block text-sm font-medium text-gray-700">Sync Practice Areas</label>
            <i>Last Updated: {localSettings.practiceAreasSync ? localSettings.practiceAreasSync : "N/A"}</i>
          </div>          
          <Button
            disabled={loadingUpdatePractice}
            icon={ClipboardIcon}
            colour="amber"
            label="Update"
            className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
            onClick={() => syncPracticeAreas()}
          />
        </div>
      </Segment>
      <Segment>
        <CronOverview />
      </Segment>
    </div>
  );
}

export default ChainedContext(Dashboard, [
  [
    ContactReceiver,
    (settings) => {
      return { settings };
    },
  ],
]);
