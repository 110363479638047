import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getList } from "./shortcodes";

export default function ShortcodeDropdown({ onChange, editorState }) {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
      let isMounted = true;

      getList(false, {
        defendants: [
            {
                email_addresses: [],
                phone_numbers: [],
                addresses: [],
                title: "Example",
                name: "Example",
                first_name: "Example",
                last_name: "Example",
                email: "Example",
            }
        ],
        courts: [
            {
                email_addresses: [],
                phone_numbers: [],
                addresses: [],
                title: "Example",
                name: "Example",
                first_name: "Example",
                last_name: "Example",
                email: "Example",
            }
        ],
        claiments: [
            {
                email_addresses: [],
                phone_numbers: [],
                addresses: [],
                title: "Example",
                name: "Example",
                first_name: "Example",
                last_name: "Example",
                email: "Example",
            }
          ],
      }).then((resultList) => {
          if (isMounted) {
              setList(resultList);
          }
      });

      return () => {
          isMounted = false; // Cleanup function to prevent state update on unmounted component
      };
  }, []);

    const openPlaceholderDropdown = () => {
        setOpen(!open);
    };

    const addPlaceholder = (placeholder) => {
        onChange(placeholder);
    };

    return (
        <div
            onClick={openPlaceholderDropdown}
            className="rdw-block-wrapper"
            aria-label="rdw-block-control"
        >
            <div
                className="rdw-dropdown-wrapper rdw-block-dropdown"
                aria-label="rdw-dropdown"
                style={{ minWidth: "350px" }}
            >
                <div className="rdw-dropdown-selectedtext" title="Shortcodes">
                    <span>Shortcodes </span>
                    <div
                        className={`rdw-dropdown-caretto${open ? "close" : "open"}`}
                    ></div>
                </div>
                <ul
                    className={`rdw-dropdown-optionwrapper ${open ? "" : "hidden"}`}
                >
                    {list.map((item) => (
                        <li
                            onClick={() => addPlaceholder(item.value)}
                            key={item.key}
                            className="rdw-dropdownoption-default placeholder-li"
                        >
                            {item.text}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

ShortcodeDropdown.propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
};
