import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "debounce";
import { SearchIcon } from "@heroicons/react/solid";
import API from "../../../api";
import ResultsContainer from "./_components/resultsContainer";
import CommonFunctions from "../../../CommonFunctions";
import Segment from "../../Segment";

const initialBlankResults = {
  contacts: [],
  companies: [],
};

const GlobalSearch_FullPage = ({ history, initialSearch = "" }) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(initialSearch);
  const [results, setResults] = useState(initialBlankResults);

  const runSearch = (term) => {
    setSearchLoading(true);
    API.search
      .global(term)
      .then((res) => {
        setSearchLoading(false);
        setResults(res);
      })
      .catch((err) => CommonFunctions.handleError(err));
  };
  const searchDebounceHandler = useCallback(
    () =>
      debounce((data) => {
        runSearch(data);
      }, 500),
    []
  );

  useEffect(() => {
    if (searchTerm.length === 0) {
      setResults({ ...initialBlankResults });
      return;
    }
    const func = searchDebounceHandler();
    func(searchTerm);
  }, [searchTerm, searchDebounceHandler]);

  return (
    <>
      <Segment>
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative text-gray-400 focus-within:text-gray-600">
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </div>
          <input
            id="search"
            className="h-9 block w-full bg-gray-200 pl-10 sm:text-sm border-gray-300 rounded-md border-transparent"
            placeholder="Search"
            name="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {searchTerm.length !== 0 && (
          <div className="mt-3">
            <ResultsContainer
              query={searchTerm}
              isLoading={searchLoading}
              results={results}
              history={history}
            />
          </div>
        )}
      </Segment>
    </>
  );
};
export default GlobalSearch_FullPage;
