import React from "react";
import PropTypes from "prop-types";

export default function Button({
  label = "",
  colour = "",
  disabled = false,
  onClick = () => {},
  className = "",
  style = {},
  icon,
}) {
  const colourList = {
    subtle: `bg-gray-200 hover:bg-gray-300 text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:bg-gray-500 disabled:opacity-50`,
    green: `bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-green-500 disabled:opacity-50 text-white`,
    red: `bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 disabled:bg-red-500 disabled:opacity-50 text-white`,
    grey: "bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:bg-gray-500 disabled:opacity-50 text-white",
    blue: "bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 disabled:bg-blue-500 disabled:opacity-50 text-white",
    amber:
      "bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-600 disabled:bg-amber-500 disabled:opacity-50 text-white",
  };

  const Icon = icon;

  return (
    <button
      type="button"
      className={`px-2.5 py-1.5 border border-transparent font-medium rounded shadow-sm flex items-center ${
        colour && colourList[colour.toLowerCase()]
          ? colourList[colour.toLowerCase()]
          : "bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 disabled:bg-gray-400 disabled:opacity-50 text-gray-500"
      } ${className ? className : ""}`}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      {icon !== undefined ? (
        <Icon
          className={` h-5 w-5 ${label ? "mr-1" : ""}`}
          aria-hidden="true"
        />
      ) : (
        <></>
      )}
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.func,
};
