import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import {
  SearchIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { debounce } from "debounce";
import API from "../../../api";
import Header from "../../../components/Header";
import { Button } from "../../../components/Forms";
import Segment from "../../../components/Segment";

export default function ContactList({ history }) {
  const [orgs, setOrgs] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterName, setFilterName] = useState("all");

  const getData = (data) => {
    API.organisations
      .getAll(data.searchTerm, true)
      .then((res) => {
        let final = {};
        Object.keys(res).forEach((a) => {
          if (res[a].length >= 1) {
            let organisations = res[a].filter((c) => {
              let allowed = [];
              switch(filterName){
                
                case "A-G":
                  allowed = ["A", "B", "C", "D", "E", "F", "G"];
                break;
                case "H-P":
                  allowed = ["H", "I", "J", "K", "L", "M", "N", "O", "P"];
                break;
                case "Q-Z":
                  allowed = ["Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                break;
              }

              let firstLetter = c.name ? c.name[0] : c.first_name[0];

              if(allowed.includes(firstLetter) || filterName === "all"){

                return c;

              }
            });

            if(organisations.length > 0){
              final[a] = organisations;
            }
          }
        });

        setOrgs(final);
      });
  };

  useEffect(() => {
    getData({ searchTerm });
  }, [filterName])

  const debounceHandler = useCallback(
    () =>
      debounce((data) => {
        getData(data);
      }, 500),
    []
  );

  useEffect(() => {
    const theDebounceFunc = debounceHandler();
    theDebounceFunc({ searchTerm });
  }, [searchTerm, debounceHandler]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      {/* <div
        className={`sm:order-first sm:flex sm:flex-col flex-shrink-0 w-full border-r border-gray-300`}
      > */}
      {/* <div className="bg-gray-100 px-4 border-b border-primary h-12 flex items-center justify-between text-xl text-primary tracking-wider ">
        Contacts Directory
      </div> */}
      <Header
        title={"Organisations Directory"}
        /*
        body={
          <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
            <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium capitalize sm:mr-2 sm:ml-0 sm:mt-0">
              <OfficeBuildingIcon
                className="flex-shrink-0 h-5 w-5 text-white"
                aria-hidden="true"
              />
              {totalCompanies}{" "}
              {totalCompanies && (totalCompanies > 1 || totalCompanies < 1)
                ? "Companies"
                : "Company"}
            </dd>
            <dd className="mt-3 -ml-0.5 flex items-center text-sm text-white font-medium sm:ml-0 sm:mt-0">
              <UserIcon
                className="flex-shrink-0 h-5 w-5 text-white"
                aria-hidden="true"
              />
              {totalIndividuals}{" "}
              {totalIndividuals &&
              (totalIndividuals > 1 || totalIndividuals < 1)
                ? "Individuals"
                : "Individual"}
            </dd>
          </dl>
        } */
      />

      <div className="">
        <Segment>
          <form className="flex space-x-4" action="#">
            <div className="flex-1 min-w-0">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  name="search"
                  className="h-9 block w-full bg-gray-200 pl-10 sm:text-sm border-gray-300 rounded-md border-transparent"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {/* <Button
                label={showClientsOnly ? "Show All" : "Show Only Clients"}
                onClick={() => setShowClientsOnly(!showClientsOnly)}
                colour="blue"
                className="text-sm"
              /> */}
            <Button
              label="New Organisation"
              onClick={() => history.push(`/admin/organisations/add`)}
              colour="green"
              className="text-sm"
              icon={PlusIcon}
            />
          </form>
          <nav className="mt-4 flex-1 min-h-0" aria-label="Directory">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <br/>
                  <span className="isolate inline-flex rounded-md shadow-sm mb-2">
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "all" && "bg-primary")}
                      onClick={() => setFilterName("all")}
                    >
                      View All
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "A-G" && "bg-primary")}
                      onClick={() => setFilterName("A-G")}
                    >
                      A-G
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "H-P" && "bg-primary")}
                      onClick={() => setFilterName("H-P")}
                    >
                      H-P
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:borderprimary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "Q-Z" && "bg-primary")}
                      onClick={() => setFilterName("Q-Z")}
                    >
                      Q-Z
                    </button>
                  </span>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full">
                        <thead className="bg-white">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Contact
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Status
                            </th>
                            {/*  
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Type
                            </th>*/}
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {Object.keys(orgs).map((letter, letter_index) => (
                            <Fragment key={letter_index}>
                              <tr className="border-t border-gray-200">
                                <th
                                  colSpan={4}
                                  scope="colgroup"
                                  className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                >
                                  {letter}
                                </th>
                              </tr>
                              {orgs[letter].sort((a, b) => {
                                  // Check if 'name' property exists in both 'a' and 'b'
                                  if (a.name && b.name) {
                                    return a.name.localeCompare(b.name);
                                  }
                                  
                                  // If 'name' property is missing in one of them, use 'first_name'
                                  const aName = a.name || '';
                                  const bName = b.name || '';

                                  return aName.localeCompare(bName);
                                }).map(
                                (org, index) => (
                                  <tr
                                    key={org._id}
                                    onClick={() => {
                                      history.push(`/admin/organisations/${org._id}`);
                                    }}
                                    className={classNames(
                                      index === 0
                                        ? "border-gray-300"
                                        : "border-gray-200",
                                      "border-t",
                                      "cursor-pointer",
                                      "hover:bg-gray-100"
                                    )}
                                  >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {org.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {org.contact}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {org.isActive ? "Active" : "De-activated"}
                                    </td>
                                    {/*  
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      c
                                    </td>*/}
                                  </tr>
                                )
                              )}
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </Segment>
      </div>
      {/* </div> */}
      {/* <div className="flex w-full">
        {profile.name && showCard ? (
          <CompanyCard profile={profile} hide={() => setShowCard(false)} />
        ) : (
          <div className="text-center h-full items-center  w-full p-8 text-gray-400 ">
            <div className="flex flex-col gap-8 justify-center border-4 border-dashed border-gray-300 rounded-xl h-full w-full">
              <div className="text-3xl tracking-wider">
                Active Companies: xxx
              </div>
              <div className="text-3xl tracking-wider">
                Total Companies: xxx
              </div>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
}
