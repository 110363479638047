import React from "react";
import ContactForm from "./forms/contactForm";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";

const AddContact = ({ history }) => {
  const handleSubmit = (data) => {
    API.prospective_contact
      .create(data)
      .then((res) => {
        history.push("/prospective-contacts/" + res._id);
      })
      .catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <div className="pb-8">
      <div
        className={`bg-gray-100 px-4 border-b border-primary flex items-center justify-between tracking-wider shadow h-12`}
      >
        <div className="text-xl text-primary">Creating New Prospect</div>
      </div>
      <ContactForm
        onCancel={() => history.push("/prospective-contacts")}
        onSubmit={handleSubmit}
        add={true}
      />
    </div>
  );
};

export default AddContact;
