import React from "react";
import { Switch, Route } from "react-router-dom";
import Dash from "../../../settings/area_tasks/dash";
import View from "../../../settings/area_tasks/view";
import FeesNavbar from "../../../settings/_components/FeesNavbar";

export default function AreaTasksSettings({history, match}) {
  const routePrefix = `/admin/organisations/:org_id/area_tasks`;

  return (
    <>
      <FeesNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <Switch>
        <Route exact path={`${routePrefix}/:id`} component={View} />
        <Route exact path={`${routePrefix}`} component={Dash} />
      </Switch>
    </>
    
  );
}
