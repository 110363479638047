import React from "react";
import { Switch, Route } from "react-router-dom";
import list from "./list";
import add from "./forms/add";
import edit from "./forms/edit";
import view from "./view";
import NotFound from "../../../../components/NotFound";

const routePrefix = "/matters/:matter_id/debtors/:debtor_id/tasks";

export default function TaskPages() {
  return (
    <Switch>
      <Route exact path={`${routePrefix}/add`} component={add} />
      <Route exact path={`${routePrefix}`} component={list} />
      <Route path={`${routePrefix}/:task_id`} component={ViewTaskRoutes} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function ViewTaskRoutes() {
  return (
    <Switch>
      <Route exact path={`${routePrefix}/:task_id/edit`} component={edit} />
      <Route exact path={`${routePrefix}/:task_id`} component={view} />
    </Switch>
  );
}
