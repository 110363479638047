import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

export default function DateSelect({
  name = "",
  label = "",
  value = "",
  placeholder = "",
  disabled = false,
  onChange = () => {},
  className = "",
  time = false,
  timeOnly = false,
}) {
  return (
    <div className={className ? className : ""}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium text-gray-700`}
      >
        {label}
      </label>
      <div className="mt-1">
        <DatePicker
          disabled={disabled}
          placeholderText={placeholder}
          onChange={onChange}
          selected={value ? new Date(value) : ""}
          showPopperArrow={false}
          shouldCloseOnSelect={true}
          dateFormat={`${!timeOnly ? "dd/MM/yyyy" : ""} ${time || timeOnly ? "h:mm a" : ""}`}
          peekNextMonth
          showTimeSelect={time || timeOnly}
          showTimeSelectOnly={timeOnly}
          className={`p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200`}
        />
      </div>
    </div>
  );
}

DateSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
