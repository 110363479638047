import React, { useEffect, useState } from "react";
import ContactForm from "../admin/organisations/forms/contactForm";
import API from "../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../CommonFunctions";
import ChainedContext from "../../contexts/chainedContext";
import OrganisationReceiver from "../../contexts/organisation_context/Organisation_Receiver";

const Edit = ({ history }) => {

  const [mount, setMount] = useState(true);
  const [org, setOrg] = useState({});

  useEffect(() => {
    API.organisations.getMyOrg().then((organisation) => setOrg(organisation)).finally(() => setMount(false));
  },mount);

  const handleSubmit = (data) => {
    API.organisations
      .update(org._id, data)
      .then(() => {
        toast.success("Account updated..");
        history.push("/settings/account");
      })
      .catch((e) => {
        console.log(e);
        CommonFunctions.handleError(e);
      });
  };

  if(mount){
    return "Loading..";
  }

  return (
    <div className="pb-8">
      <ContactForm
        org={org}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ChainedContext(Edit, [
  [OrganisationReceiver, "org"],
]);

