import React, {useEffect, useState} from "react";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { DocumentIcon, LinkIcon, RefreshIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
import toast from "react-hot-toast";
import FeesNavbar from "../_components/FeesNavbar";

const ClioDocs = ({history, match}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [clioData, setClioData] = useState([]);

  const getData = () => {

    setIsInitialLoading(false);

    API.settings.services().getClioMatters().then(res => {
      setClioData(res);
    }).finally(() => {
      setIsInitialLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  if(isInitialLoading){
    return (
      <Loader>Please Wait, Retrieving data from server... <br/> This might take a while due to loading from Clio with pauses to stop any Rate Limits</Loader>
    );
  }

  const uniqueMatters = {}; // Object to track unique matters

  const filteredData = clioData.filter(data => {
    // If documents length is greater than 0, exclude the object
    if (data.documents.length > 0) {
      return false;
    }
    
    // If matter already exists in uniqueMatters, exclude the object
    if (uniqueMatters[data.matter]) {
      return false;
    }
    
    // Otherwise, mark matter as seen and include the object
    uniqueMatters[data.matter] = true;
    return true;
  });

  return (
    <React.Fragment>
      <FeesNavbar history={history} match={{...match, url: "/settings"}} />
      <div className="sm:grid grid-cols-1">
        <Segment>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Clio Matters & Documents {" "}
            
            <button
              disabled={isSyncing}
              type="button"
              className={`${isSyncing ? "text-gray-700 bg-white " : "text-white bg-primary hover:bg-secondary "} inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500`}
              onClick={() => {
                  setIsSyncing(true);
                API.settings.services().clioMatters().then(r => {
                  if(r.success){
                    toast.success(`Successfully synced, added ${r.added} Matters to database`);
                  }
                  
                }).finally(() => {
                  API.settings.services().getClioMatters().then(res => {
                    setClioData(res);
                  }).finally(() => {
                    setIsSyncing(false);
                  });
                });
              }}
            >
              <RefreshIcon
                className={`-ml-1 mr-2 h-5 w-5 ${isSyncing ? "animate-spin" : "text-white"}`}
                aria-hidden="true"
              />
              <span>{isSyncing ? "Syncing" : "Sync"}</span>
            </button>
            
            </span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />

          <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Matter
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {clioData && clioData.length ? (
                      filteredData.map((data, index) => (
                        <tr key={"matter_" + index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            Matter: {data.matter} | Documents ({data.documents ? data.documents.length : 0})<br/>
                            {data.documents.map(doc => {
                              return <p><DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400 inline-block" />{doc}</p>
                            })}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {/*  
                            <Button
                              label={""}
                              colour={"blue"}
                              icon={LinkIcon}
                              className={"ml-auto"}
                              onClick={() => {
                                console.log("blah");
                              }}
                            />*/}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={3}
                          className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                        >
                          There are no items to display.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
          
        </Segment>
      </div>
      {/*  
      <Segment>
        <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
          <span className="my-auto text-xl">Interest Rate Tester</span>
        </h3>
        <div className="w-full border-t border-gray-300 my-2" />

        <BaseRateTester />
      </Segment>*/}
    </React.Fragment>
  );
}
export default ClioDocs;