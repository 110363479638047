import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter} from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Toggle,
  Input,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import MatterBreadcrumbs from "../../_components/MatterBreadcrumbs";

import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";

function Add({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [matterDebtors, setMatterDebtors] = useState([]);
  const [matter, setMatter] = useState(null);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    isBillable: true,
    additional_task: true,
  });

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      if(!matter){
        API.matters.get(params.matter_id).then((res) => {
          setMatter(res);
        })
      }
      API.matters.debtors
          .getAll(params.matter_id)
          .then((res2) => {
            setMatterDebtors(res2);
          })
          .catch((e2) => CommonFunctions.handleError(e2));
      API.users
        .getAll()
        .then((res) => {
          setUserList(
            res.users.map((a) => {
              if(auth && a.email == auth.user.email){
                setData({ ...data, assigned: a._id });
              }
              return {
                text: a.name,
                value: a._id,
              };
            })
          );
        })
        .catch((e) => CommonFunctions.handleError(e));

      setMountLoading(false);
    }
  }, [mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div>
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Add Manual Task`, href: '#', current: true },
      ]} />}
      <Segment className="mt-4">
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Create Manual Task
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          <Group className="py-2">
            <TextArea
              label="Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </Group>
          <Group className="py-2">
            <Input
              type="number"
              incrementBy="6"
              label="Time"
              name="time"
              value={data.time}
              onChange={onChange}
            />
            <Dropdown
              label="Fee Earner"
              name="assigned"
              value={data.assigned}
              onChange={onChange}
              options={userList}
            />
            <DateSelect
              label="Due Date"
              name="due_date"
              value={data.due_date}
              onChange={onDateChange}
            />
          </Group>
          <Toggle
            className="my-2"
            label="Is Billable?"
            enabled={data.isBillable}
            onChange={() =>
              setData({
                ...data,
                isBillable: data.isBillable ? !data.isBillable : true,
              })
            }
          />
          <div className="pt-2 flex justify-between">
            <Button
              colour="red"
              label="Cancel"
              onClick={() => history.push(`/matters/${params.matter_id}/tasks`)}
              icon={XIcon}
            />
            <Button
              colour="green"
              label="Create"
              onClick={() => {
                API.matters.tasks
                  .create(params.matter_id, data)
                  .then((res) =>
                    history.push(`/matters/${params.matter_id}/tasks/${res}`)
                  );
              }}
              icon={CheckIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Add), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);