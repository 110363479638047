
const InterestTypes = [
  { key: 1, text: "Simply", value: "simply" },
  { key: 2, text: "Late Payment", value: "late_payment" },
  { key: 3, text: "Base Rate", value: "base_rate" },
  { key: 4, text: "Base Rate [Plus]", value: "base_rate_plus" },
  { key: 5, text: "Contractual", value: "contractual" },
  { key: 6, text: "Statutory", value: "statutory" },
  { key: 6, text: "Monthly", value: "monthly" },
];

const CountiesList = [
  { key: 1, text: "Aberdeenshire", value: "Aberdeenshire" },
  { key: 2, text: "Anglesey", value: "Anglesey" },
  { key: 3, text: "Angus", value: "Angus" },
  { key: 4, text: "Argyll and Bute", value: "Argyll and Bute" },
  { key: 5, text: "Bedfordshire", value: "Bedfordshire" },
  { key: 6, text: "Berkshire", value: "Berkshire" },
  { key: 7, text: "Blaenau Gwent", value: "Blaenau Gwent" },
  { key: 8, text: "Bridgend", value: "Bridgend" },
  { key: 9, text: "Buckinghamshire", value: "Buckinghamshire" },
  { key: 10, text: "Caerphilly", value: "Caerphilly" },
  { key: 11, text: "Cambridgeshire", value: "Cambridgeshire" },
  { key: 12, text: "Carmarthenshire", value: "Carmarthenshire" },
  { key: 13, text: "Ceredigion", value: "Ceredigion" },
  { key: 14, text: "Cheshire", value: "Cheshire" },
  { key: 15, text: "Clackmannanshire", value: "Clackmannanshire" },
  { key: 16, text: "Conwy", value: "Conwy" },
  { key: 17, text: "Cornwall", value: "Cornwall" },
  { key: 18, text: "County Antrim", value: "County Antrim" },
  { key: 19, text: "County Armagh", value: "County Armagh" },
  { key: 20, text: "County Down", value: "County Down" },
  { key: 21, text: "County Durham", value: "County Durham" },
  { key: 22, text: "County Fermanagh", value: "County Fermanagh" },
  { key: 23, text: "County Londonderry", value: "County Londonderry" },
  { key: 24, text: "County Tyrone", value: "County Tyrone" },
  { key: 25, text: "Cumbria", value: "Cumbria" },
  { key: 26, text: "Denbighshire", value: "Denbighshire" },
  { key: 27, text: "Derbyshire", value: "Derbyshire" },
  { key: 28, text: "Devon", value: "Devon" },
  { key: 29, text: "Dorset", value: "Dorset" },
  { key: 30, text: "Dumfries and Galloway", value: "Dumfries and Galloway" },
  { key: 31, text: "Dundee City", value: "Dundee City" },
  { key: 32, text: "East Ayrshire", value: "East Ayrshire" },
  { key: 33, text: "East Dunbartonshire", value: "East Dunbartonshire" },
  { key: 34, text: "East Lothian", value: "East Lothian" },
  { key: 35, text: "East Renfrewshire", value: "East Renfrewshire" },
  { key: 36, text: "East Riding of Yorkshire", value: "East Riding of Yorkshire" },
  { key: 37, text: "East Sussex", value: "East Sussex" },
  { key: 38, text: "Edinburgh", value: "Edinburgh" },
  { key: 39, text: "Essex", value: "Essex" },
  { key: 40, text: "Falkirk", value: "Falkirk" },
  { key: 41, text: "Fife", value: "Fife" },
  { key: 42, text: "Flintshire", value: "Flintshire" },
  { key: 43, text: "Glasgow City", value: "Glasgow City" },
  { key: 44, text: "Gloucestershire", value: "Gloucestershire" },
  { key: 45, text: "Gwynedd", value: "Gwynedd" },
  { key: 46, text: "Hampshire", value: "Hampshire" },
  { key: 47, text: "Herefordshire", value: "Herefordshire" },
  { key: 48, text: "Hertfordshire", value: "Hertfordshire" },
  { key: 49, text: "Highland", value: "Highland" },
  { key: 50, text: "Inverclyde", value: "Inverclyde" },
  { key: 51, text: "Isle of Wight", value: "Isle of Wight" },
  { key: 52, text: "Kent", value: "Kent" },
  { key: 53, text: "Lancashire", value: "Lancashire" },
  { key: 54, text: "Leicestershire", value: "Leicestershire" },
  { key: 55, text: "Lincolnshire", value: "Lincolnshire" },
  { key: 56, text: "Merthyr Tydfil", value: "Merthyr Tydfil" },
  { key: 57, text: "Midlothian", value: "Midlothian" },
  { key: 58, text: "Monmouthshire", value: "Monmouthshire" },
  { key: 59, text: "Moray", value: "Moray" },
  { key: 60, text: "Neath Port Talbot", value: "Neath Port Talbot" },
  { key: 61, text: "Newport", value: "Newport" },
  { key: 62, text: "Norfolk", value: "Norfolk" },
  { key: 63, text: "North Ayrshire", value: "North Ayrshire" },
  { key: 64, text: "North Lanarkshire", value: "North Lanarkshire" },
  { key: 65, text: "North Yorkshire", value: "North Yorkshire" },
  { key: 66, text: "Northamptonshire", value: "Northamptonshire" },
  { key: 67, text: "Northumberland", value: "Northumberland" },
  { key: 68, text: "Nottinghamshire", value: "Nottinghamshire" },
  { key: 69, text: "Orkney Islands", value: "Orkney Islands" },
  { key: 70, text: "Oxfordshire", value: "Oxfordshire" },
  { key: 71, text: "Pembrokeshire", value: "Pembrokeshire" },
  { key: 72, text: "Perth and Kinross", value: "Perth and Kinross" },
  { key: 73, text: "Powys", value: "Powys" },
  { key: 74, text: "Renfrewshire", value: "Renfrewshire" },
  { key: 75, text: "Rhondda Cynon Taf", value: "Rhondda Cynon Taf" },
  { key: 76, text: "Rutland", value: "Rutland" },
  { key: 77, text: "Scottish Borders", value: "Scottish Borders" },
  { key: 78, text: "Shetland Islands", value: "Shetland Islands" },
  { key: 79, text: "Shropshire", value: "Shropshire" },
  { key: 80, text: "Somerset", value: "Somerset" },
  { key: 81, text: "South Ayrshire", value: "South Ayrshire" },
  { key: 82, text: "South Lanarkshire", value: "South Lanarkshire" },
  { key: 83, text: "South Yorkshire", value: "South Yorkshire" },
  { key: 84, text: "Staffordshire", value: "Staffordshire" },
  { key: 85, text: "Stirling", value: "Stirling" },
  { key: 86, text: "Suffolk", value: "Suffolk" },
  { key: 87, text: "Surrey", value: "Surrey" },
  { key: 88, text: "Swansea", value: "Swansea" },
  { key: 89, text: "Torfaen", value: "Torfaen" },
  { key: 90, text: "Tyne and Wear", value: "Tyne and Wear" },
  { key: 91, text: "Vale of Glamorgan", value: "Vale of Glamorgan" },
  { key: 92, text: "Warwickshire", value: "Warwickshire" },
  { key: 93, text: "West Dunbartonshire", value: "West Dunbartonshire" },
  { key: 94, text: "West Lothian", value: "West Lothian" },
  { key: 95, text: "West Midlands", value: "West Midlands" },
  { key: 96, text: "West Sussex", value: "West Sussex" },
  { key: 97, text: "West Yorkshire", value: "West Yorkshire" },
  { key: 98, text: "Western Isles", value: "Western Isles" },
  { key: 99, text: "Wiltshire", value: "Wiltshire" },
  { key: 100, text: "Worcestershire", value: "Worcestershire" },
  { key: 101, text: "Wrexham", value: "Wrexham" }
];


export {
  InterestTypes, CountiesList
}