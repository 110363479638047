import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../../components/NotFound";
import ContactCustomFields from "./contactFields";
import AddTemplate from "./add";
import CustomFieldsNavbar from "../../_components/CustomFieldsNavbar"

const routePrefix = "/settings/client_custom_fields";

export default function Index({history, match}) {
  console.log(`${routePrefix}/add`);
  return (
    <>
      <CustomFieldsNavbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={ContactCustomFields} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
