import React, { Fragment, useState } from "react";

import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/solid";
import ProspectiveContactReceiver from "../../../contexts/prospective_contact_context/Prospective_Contact_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import { Button } from "../../../components/Forms";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { ConfirmModal } from "../../../components/Modals";

function ContactNavbar({ contact, match, history }) {
  const [convertLoading, setConvertLoading] = useState(false);
  const navItems = [
    {
      name: "Dash",
      description: "dash",
      href: `${match.url}`,
      exact: true,
    },
    {
      name: "Details",
      description: "details",
      href: `${match.url}/details`,
      exact: true,
    },
    // {
    //   name: "Edit",
    //   description: "edit",
    //   href: `${match.url}/edit`,
    //   exact: true,
    // },
    {
      name: "Activities",
      description: "activities",
      href: `${match.url}/activities`,
      exact: false,
    },
    {
      name: "Credit Check",
      description: "credit_checks",
      href: `${match.url}/credit_checks`,
      hide: contact.type === "Company" ? false : true,
      exact: true,
    },
    {
      name: "Settings",
      description: "settings",
      href: `${match.url}/settings`,
      exact: true,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ConfirmModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSubmit={() => {
          if (convertLoading === false) {
            setConvertLoading(true);
            API.prospective_contact
              .convert(contact._id)
              .then((res) => {
                setConvertLoading(false);
                setModalOpen(false);
                history.push(`/contacts/${res}`);
              })
              .catch((e) => {
                setConvertLoading(false);
                CommonFunctions.handleError(e);
              });
          }
        }}
        header="Are you sure?"
        content={
          "Converting will archive this prospective record and create a contact with the current data."
        }
      />
      <Disclosure as="nav" className="bg-primary sticky-item-sub-nav">
        {({ open }) => (
          <>
            <div className=" mx-auto  px-2 border-b border-gray-300">
              <div className="flex items-center justify-between h-12">
                <div className="flex items-center">
                  <div className="hidden md:block">
                    <div className=" flex items-center justify-center text-center space-x-4">
                      <div className="hidden cursor-pointer font-medium lg:flex gap-2 antialiased text-md px-2 py-2 justify-center items-center text-white tracking-wider">
                        <span>Prospectives Menu</span>
                      </div>
                      {navItems
                        .filter((s) => {
                          return s.hide !== true;
                        })
                        .map((item, itemIdx) => (
                          <Fragment key={itemIdx}>
                            {item.external ? (
                              <a
                                href={item.href}
                                target="_blank"
                                rel="noreferrer"
                                alt={item.description}
                              >
                                {item.name}
                              </a>
                            ) : (
                              <NavLink
                                exact={item.exact}
                                to={item.href}
                                activeClassName="bg-secondary underline text-black"
                                className="px-2 py-2 text-gray-800 text-lg hover:bg-secondary hover:text-black tracking-wider"
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </NavLink>
                            )}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                </div>
                {contact.archived === false ? (
                  <Button
                    className="float-right text-sm"
                    label="Convert To Client"
                    icon={RefreshIcon}
                    colour="red"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    disabled={convertLoading}
                  />
                ) : (
                  <></>
                )}
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-between p-2 rounded-md text-primary hover:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white">
                    <div className=" flex gap-2">
                      <span className="text-black">Prospective Contact: </span>
                    </div>
                    <span className="sr-only text-black">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6 text-black" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon
                        className="block h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navItems
                  .filter((s) => {
                    return s.hide !== true;
                  })
                  .map((item, itemIdx) => (
                    <Fragment key={itemIdx}>
                      <NavLink
                        exact
                        to={item.href}
                        activeClassName="bg-gray-50 "
                        className="text-black hover:bg-gray-300  block px-3 py-2 rounded-md text-base font-medium"
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    </Fragment>
                  ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default ChainedContext(ContactNavbar, [
  [
    ProspectiveContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
