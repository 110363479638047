import { v4 as uuid } from "uuid";
import React, { useEffect, useRef, useState, useMemo } from "react";
import ChainedContext from "../../../../../contexts/chainedContext";
import MatterReceiver from "../../../../../contexts/matter_context/Matter_Receiver";
import { FileUploader } from "../../../../../components/Forms";
import API from "../../../../../api";

const TransactionImporter = ({ title, isUploadingInProgress, onUploadedDocument, matter, existingDocuments = [], debtor, debt }) => {
  const [pendingDocuments, setPendingPendingDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const uploadProgressRef = useRef([]);

  useEffect(() => {
    isUploadingInProgress(pendingDocuments.filter(a => a.status === "uploading").length >= 1);

    const uploadedDocs = pendingDocuments.filter(a => a.status === "uploaded");
    if(uploadedDocs.length){
      onUploadedDocument(uploadedDocs.map(a => {
        return {
          filename: a._file.name,
          type: a._file.type
        }
      }).concat(existingDocuments));
    //   setPendingPendingDocuments(pendingDocuments.filter(a => a.status !== "uploaded"));
      setPendingPendingDocuments(pendingDocuments);
    }
  }, [pendingDocuments]);

  const fileListDisplay = useMemo(() => {
    // return pendingDocuments.filter(a => a.status !== "uploaded").map(a => {
    return pendingDocuments.map(a => {
      return {
        status: a.status,
        filename: a._file.name,
        imported: a.imported,
        import: a.import,
        errors: a.errors,
      }
    }).concat(
      existingDocuments.map(a => {
        return {
          filename: a.filename,
          status: "uploaded",
          imported: a.imported,
          import: a.import,
          errors: a.errors,
        }
      })
    )
  }, [pendingDocuments, existingDocuments]);

  const handleNewUpload = (data) => {
    const doc = {
      id: uuid(),
      status: "uploading",
      errors: [],
      imported: 0,
      import: 0,
      _file: data,
    }
    setPendingPendingDocuments(pendingDocuments.concat([doc]));

    uploadProgressRef.current = uploadProgressRef.current.concat([{
      id: doc.id,
      percentage: 0
    }]);
    
    // return;
    // API.clio.documents.upload(data, {

    let parent = {
        id: matter._clioId,
        type: "Matter"
    };

    API.matters.debtors.debt.importTransactions(matter._id, debtor._id, debt._id, data, event => {
      uploadProgressRef.current = uploadProgressRef.current.map(a => {
        if (a.id !== doc.id) return a;
        return {
          ...a,
          percentage: Math.round((100 * event.loaded) / event.total)
        };
      });
      setUploadProgress(uploadProgressRef.current);
    }).then(res => {
      setPendingPendingDocuments(existingDocuments => existingDocuments.map(a => {
        if (a.id !== doc.id) return a;

        if(res.success){
            return {
                ...a,
                status: "uploaded",
                imported: res.imported,
                import: res.import,
                errors: res.errors
            }
        }else{
            return {
                ...a,
                status: "failed",
                errors: res.errors
            }
        }

        
      }))
    }).catch(e => {
      console.log("🚀 ~ handleNewUpload ~ e:", e.message)
      setPendingPendingDocuments(existingDocuments => existingDocuments.map(a => {
        if (a.id !== doc.id) return a;
        return {
          ...a,
          status: "failed"
        }
      }))
    });
  };

  return (
    <>
      <div className={"px-1"}>
        <label className="block text-sm font-medium text-gray-700 mb-1">{title}</label>
        <FileUploader onFileToUpload={handleNewUpload} uploading={pendingDocuments.filter(a => a.status === "uploading").length >= 1} />

        {fileListDisplay.length !== 0 &&
          <table className="table-auto w-full text-sm">
            <thead className="divide-y divide-gray-300">
            <tr className="divide-x divide-gray-300">
              <th className="uppercase bg-gray-200 p-2">Name</th>
              {/*<th className="uppercase bg-gray-200 p-2">Type</th>*/}
              <th className="uppercase bg-gray-200 p-2">Status</th>
              <th className="uppercase bg-gray-200 p-2">Imported</th>
              <th className="uppercase bg-gray-200 p-2">Errors</th>
            </tr>
            </thead>
            <tbody>
            {fileListDisplay.reverse().map((doc, i) => {
              const progress = uploadProgress.find(a => a.id === doc.id);
              return (
                <tr key={i} className="divide-x divide-gray-200">
                  <td className="p-2">{doc.filename}</td>
                  {/*<td className="p-2 tab">{doc._file.type}</td>*/}
                  <td className="p-2 tab">
                    {doc.status === "uploading" && <>
                      {progress?.percentage === 100 ? <>Finalizing import...</> : <>Importing... {progress?.percentage}%</>}
                    </>}
                    {doc.status === "uploaded" && <>
                      Imported
                    </>}
                    {doc.status === "failed" && <>
                      Import Failed
                    </>}
                  </td>
                  <td className="p-2 tab">
                    {doc.status === "uploaded" && <>
                      {doc.imported} / {doc.import}
                    </>}
                  </td>
                  <td className="p-2 tab">
                      {doc.errors && doc.errors.length > 0 && doc.errors.map((e) => {
                        return <p>{e}</p>
                      })}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>}
      </div>
    </>
  );
}
export default TransactionImporter;
/*
export default ChainedContext(TransactionImporter, [
  [MatterReceiver, "matter"]
]); */