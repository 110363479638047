import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import { Navbar } from "./_components";
import ListManager from "./listManager";

const routePrefix = "/settings/checklists";

export default function Index({ history, match }) {
  return (
    <>
      <Navbar history={history} match={match} />
      <Switch>
        <Route path={`${routePrefix}/:type`} component={ListManager} />
        <Route
          exact
          path={routePrefix}
          children={<Redirect to={`${routePrefix}/contact`} />}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
