import React from "react";
import { Switch, Route } from "react-router-dom";
import EmailManagement from "../../../settings/emailTemplates/emailManagement";
import AddTemplate from "../../../settings/emailTemplates/add";
import TemplateNavbar from "../../../settings/_components/TemplateNavbar";

const routePrefix = "/admin/organisations/:org_id/email_templates";

export default function Index({history, match}) {
  return (
    <>
      <TemplateNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={EmailManagement} />
      </Switch>
    </>
  );
}
