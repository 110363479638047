import React, { useEffect, useState } from "react";
import { Button, Group, Input, SelectTwo } from "../../../../components/Forms";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
const defaultData = {
  address: "",
  name: "Work",
  primary: false,
};

const EmailList = ({ onChange, emails }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState({
    ...defaultData,
  });

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const onTypeChange = (val) => setData({ ...data, name: val.target.value });
  const handleFormSubmit = () => {
    if (editIndex !== null) {
      onChange(
        emails.map((item, index) => {
          if (index !== editIndex) return item;
          return {
            ...item,
            ...data,
          };
        })
      );
      setEditIndex(null);
    } else {
      if(!emails.length > 0){
        data.primary = true;
      }
      onChange(
        [
          {
            ...data,
          },
        ].concat(emails)
      );
    }
    setData({
      ...defaultData,
    });
  };
  const handleAddressEditButton = (index) => () => {
    setData(emails[index]);
    setEditIndex(index);
  };
  const handleAddressDeleteButton = (index) => () => {
    onChange(emails.filter((a, i) => i !== index));
  };
  const handleSetPrimary = (index) => () => {
    onChange(
      emails.map((a, i) => {
        if (i === index) {
          return {
            ...a,
            primary: true,
          };
        }
        return {
          ...a,
          primary: false,
        };
      })
    );
  };

  const renderInputFields = () => {
    return (
      <React.Fragment>
        <div className="py-2 flex gap-2 items-end  justify-between">
          <div className="w-full">
            <Input
              label="Email Address"
              name="address"
              value={data.address}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-52">
            <SelectTwo
              label="Type"
              placeholder="Please Select Type"
              field="name"
              value={data.name}
              options={[
                { key: 0, text: "Work", value: "Work" },
                { key: 1, text: "Home", value: "Home" },
                { key: 2, text: "Other", value: "Other" },
              ]}
              onChange={onTypeChange}
            />
          </div>
          <div className="w-20">
            {" "}
            {editIndex !== null && (
              <Button
                colour={"subtle"}
                label={`Cancel`}
                onClick={() => {
                  setData({
                    ...defaultData,
                  });
                  setEditIndex(null);
                }}
              />
            )}
            <Button
              colour={"green"}
              label={`${editIndex !== null ? "Update" : "Add"}`}
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {renderInputFields()}
      {emails.length === 0 ? (
        <div>
          There are no emails to display, create one using the form above.
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 table-auto">
          <thead>
            <tr>
              <th className="text-left">Email</th>
              <th className="text-center ">Type</th>
              <th className="text-center ">Is Primary</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {emails.map((address, index) => {
              return (
                <tr key={index}>
                  <td className="p-2 w-3/6">{address.address}</td>
                  <td className="p-2 text-center w-1/6 ">{address.name}</td>
                  <td className="p-2  text-center  w-1/4">
                    {address.primary ? (
                      "[Primary]"
                    ) : (
                      <Button
                        colour={"subtle"}
                        label={`Set Primary`}
                        onClick={handleSetPrimary(index)}
                      />
                    )}
                  </td>
                  <td className=" w-1/6  ">
                    <div className="flex gap-2 p-2  justify-end">
                      <Button
                        colour={"red"}
                        label={<TrashIcon className="h-4" />}
                        onClick={handleAddressDeleteButton(index)}
                      />
                      <Button
                        colour={"blue"}
                        label={<PencilIcon className="h-4" />}
                        onClick={handleAddressEditButton(index)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default EmailList;
