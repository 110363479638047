import { v4 as uuid } from "uuid";
import React, { useEffect, useRef, useState, useMemo } from "react";
import {TrashIcon} from "@heroicons/react/solid";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import { FileUploader } from "../../../components/Forms";
import API from "../../../api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

const DocumentsUploader = ({ title, isUploadingInProgress, onUploadedDocument, matter, existingDocuments = [] }) => {
  const [pendingDocuments, setPendingPendingDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const uploadProgressRef = useRef([]);

  useEffect(() => {
    isUploadingInProgress(pendingDocuments.filter(a => a.status === "uploading").length >= 1);

    const uploadedDocs = pendingDocuments.filter(a => a.status === "uploaded");
    if(uploadedDocs.length){
      onUploadedDocument(uploadedDocs.map(a => {
        return {
          id: a.clioId,
          clioId: a.clioId,
          filename: a._file.name,
          type: a._file.type
        }
      }).concat(existingDocuments));
      setPendingPendingDocuments(pendingDocuments.filter(a => a.status !== "uploaded"));
    }
  }, [pendingDocuments]);

  const fileListDisplay = useMemo(() => {
    return pendingDocuments.filter(a => a.status !== "uploaded").map(a => {
      return {
        id: a.id,
        clioId: a.clioId,
        status: a.status,
        filename: a._file.name
      }
    }).concat(
      existingDocuments.map(a => {
        return {
          id: a.clioId,
          clioId: a.clioId,
          filename: a.filename,
          status: "uploaded"
        }
      })
    )
  }, [pendingDocuments, existingDocuments]);

  const handleNewUpload = (data) => {
    const doc = {
      id: uuid(),
      status: "uploading",
      _file: data,
    }
    setPendingPendingDocuments(pendingDocuments.concat([doc]));

    uploadProgressRef.current = uploadProgressRef.current.concat([{
      id: doc.id,
      percentage: 0
    }]);
    API.clio.documents.upload(data, {
      id: matter._clioId,
      type: "Matter"
    }, event => {
      uploadProgressRef.current = uploadProgressRef.current.map(a => {
        if (a.id !== doc.id) return a;
        return {
          ...a,
          percentage: Math.round((100 * event.loaded) / event.total)
        };
      });
      setUploadProgress(uploadProgressRef.current);
    }).then(res => {
      setPendingPendingDocuments(existingDocuments => existingDocuments.map(a => {
        if (a.id !== doc.id) return a;
        return {
          ...a,
          status: "uploaded",
          clioId: res.clioFile.id
        }
      }))
    }).catch(e => {
      setPendingPendingDocuments(existingDocuments => existingDocuments.map(a => {
        if (a.id !== doc.id) return a;
        return {
          ...a,
          status: "failed"
        }
      }))
    });
  };
  const handleDeleteUpload = clioId => {
    API.clio.documents.removeFile(clioId).then(() => {
      onUploadedDocument(pendingDocuments.concat(existingDocuments).filter((a) => a?.id !== clioId).filter((a) => a?.clioId !== clioId));
    })
  }

  return (
    <>
      <ConfirmModal
        open={showDeleteWarning !== false}
        handleClose={() => setShowDeleteWarning(false)}
        handleSubmit={() => {
          handleDeleteUpload(showDeleteWarning);
          setShowDeleteWarning(false);
        }}
        header={"Remove uploaded document in Clio"}
        content={"Are you sure you want to remove the deleted document uploaded to Clio? This action is non-recoverable once complete."}
      />
      <div className={"px-1"}>
        <label className="block text-sm font-medium text-gray-700 mb-1">{title}</label>
        <FileUploader onFileToUpload={handleNewUpload} />

        {fileListDisplay.length !== 0 &&
          <table className="table-auto w-full text-sm">
            <thead className="divide-y divide-gray-300">
            <tr className="divide-x divide-gray-300">
              <th className="uppercase bg-gray-200 p-2">Name</th>
              {/*<th className="uppercase bg-gray-200 p-2">Type</th>*/}
              <th className="uppercase bg-gray-200 p-2">Status</th>
            </tr>
            </thead>
            <tbody>
            {fileListDisplay.map((doc, i) => {
              const progress = uploadProgress.find(a => a.id === doc.id);
              return (
                <tr key={i} className="divide-x divide-gray-200">
                  <td className="p-2">{doc.filename}</td>
                  {/*<td className="p-2 tab">{doc._file.type}</td>*/}
                  <td className="p-2 tab">
                    {doc.status === "uploading" && <>
                      {progress?.percentage === 100 ? <>Finalizing upload...</> : <>Uploading... {progress?.percentage}%</>}
                    </>}
                    {doc.status === "uploaded" && <>
                      Uploaded
                      <button className={`float-right`} onClick={() => setShowDeleteWarning(doc.clioId)}><TrashIcon className={`h-5 w-5`} /></button>
                    </>}
                    {doc.status === "failed" && <>
                      Upload Failed
                      <button className={`float-right`} onClick={() => setPendingPendingDocuments(pendingDocuments.filter((a, b) => b !== i))}><TrashIcon className={`h-5 w-5`} /></button>
                    </>}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>}
      </div>
    </>
  );
}
export default ChainedContext(DocumentsUploader, [
  [MatterReceiver, "matter"]
]);