import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../components/Tables";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import MatterBar from "../_components/MatterBar";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
function ActivityList({ matter }) {
  const params = useParams();
  const history = useHistory();

  const [matterActivities, setMatterActivities] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);
  const [matterDebtors, setMatterDebtors] = useState({});

  useEffect(() => {
    API.matters.activities
      .list(params.matter_id)
      .then((res) => {
        setMatterActivities(res);

        API.matters.debtors
        .getAll(params.matter_id)
        .then((res2) => {
          setMatterDebtors(res2);
        })
        .catch((e2) => CommonFunctions.handleError(e2));

      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setMountLoading(false);
      });

      API.settings
          .activity_categories()
          .list().then((res) => {
            setCategoryList(res);
          })
  }, [params.matter_id]);

  return (
    <>
    <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "History", href: '#', current: true },
      ]} />
      <div className="">
        <Segment>
          <MatterBar matter={matter} history={history} />
          <div className="">
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="text-xl my-auto">History</span>
              
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {mountLoading ? (
            <Loader />
          ) : (
            <>
            {/*  
              <div className="md:grid md:grid-cols-3 md:gap-34 pt-3 pb-4">
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">{matterActivities.length}</div>
                  <div className="text-xl text-primary">Activities</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {matterActivities.length > 0
                      ? matterActivities
                          .map((item) => item.time)
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes</div>
                </div>
                <div className="border border-primary p-4 rounded-xl text-center md:mx-auto md:w-52">
                  <div className="text-3xl">
                    {matterActivities.length > 0
                      ? matterActivities
                          .map((item) =>
                            item.isBillable === true ? item.time : 0
                          )
                          .reduce((a, b) => a + b)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Minutes Billable</div>
                </div>
              </div>*/}
              <ThemeTable
                selectable={false}
                header={[
                  { text: "Category", value: "category" },
                  { text: "Description", value: "description" },
                  { text: "Fee Earner", value: "assigned" },
                  { text: "Due Date", value: "due_date" },
                  { text: "Time", value: "time" },
                  { text: "Cost", value: "cost" },
                  { text: "Billable", value: "isBillable" },
                  { text: "Status", value: "status" },
                ]}
                body={matterActivities
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((item) => ({
                    ...item,
                    description: item.description ? item.description.substring(0, 130) + "..." : "",
                    category: item.category && (
                      <div>
                        <div
                          className="h-5 w-5 mx-auto inline-block"
                          dangerouslySetInnerHTML={{
                            __html: item.category.icon,
                          }}
                        ></div>{" "}
                        {item.category.name}
                      </div>
                    ),
                    source:
                      item.type === "matter"
                        ? "Matter"
                        : item.type === "debtor"
                        ? `Debtor ${
                            item.debtor && item.debtor.debtor_name
                              ? `(${item.debtor.debtor_name})`
                              : ""
                          }`
                        : "",
                    isBillable: item.isBillable === true ? "Yes" : "No",
                    matter_display_number:
                      item.matter && item.matter !== null
                        ? item.matter.display_number
                        : "--",
                    assignee_name: item.assignee ? item.assignee.name : "--",
                    due_date: item.due_date
                      ? dayjs(item.due_date).format("DD/MM/YYYY")
                      : "",
                    status: item.complete ? "Complete" : "In Progress",
                    view_document: item.files && item.files[0] ? item.files[0] : "",
                    // extra_row_css: item.complete ? "text-gray-400" : "text-gray-900",
                    cost: `
                      £${(
                        (item.time ? item.time : 0) *
                        (item.currentRate ? item.currentRate / 60 : 0)
                      ).toFixed(2)}`,
                  }))
                  .sort((a, b) =>
                    a.source < b.source ? 1 : a.source > b.source ? -1 : 0
                  )}
                action={(item) =>
                  history.push(
                    item.type === "debtor"
                      ? "/matters/" +
                          params.matter_id +
                          "/debtors/" +
                          item.debtor._id +
                          "/activities/" +
                          item._id
                      : "/matters/" +
                          params.matter_id +
                          "/activities/" +
                          item._id
                  )
                }
                noDataMessage="No Activities..."
              />
            </>
          )}
        </Segment>
      </div>
    </>
  );
}
export default ChainedContext(ActivityList, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
