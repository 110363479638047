import React from "react";
import PropTypes from "prop-types";

export default function Header({ title, body }) {
  return (
    <div className="bg-primary px-4 border-b border-gray-300 text-white flex items-center justify-between tracking-wider shadow h-12">
      {/* bg-primary px-4 border-b border-gray-300 h-12 flex items-center justify-between text-white text-xl tracking-wider  */}
      <div className="text-xl text-white">{title}</div>
      <div>{body}</div>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.element,
};
