import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import DayJS from "dayjs";
import { Button, Input, SelectTwo } from "../../../components/Forms";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { GiCheckMark } from "react-icons/gi";

const LicenceTable = ({ data, onCreate, onUpdate, onDelete = () => {} }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    user: "user",
    seats: 0,
    price: 0,
    prices: [
      {
        name: "Monthly 1 Seat",
        price: 20,
        frequency: "monthly", 
        archived: false,
      }
    ],
    archived: false
  });
  const [submitError, setSubmitError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  const frequencyOptions = [
    { key: 0, text: "Monthtly", value: "monthly" },
    { key: 1, text: "Quartlery", value: "quarterly" },
    { key: 2, text: "Annually", value: "annually" },
  ];

  useEffect(() => {
    let errors = {};
    if (formData.name === "") {
      errors.name = "Name can not be empty";
    }
    if (!formData.price > 0) {
      errors.price = "Price can not be empty";
    }
    if (!formData.seats > 0) {
      errors.seats = "Seats can not be empty";
    }

    setFormErrors(errors);
  }, [formData]);

  const editing = formData._id !== undefined;
  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handlePriceChange = (index, field, value) => {
    setFormData(prevFormData => {
      const newPrices = [...prevFormData.prices];
      newPrices[index] = {
        ...newPrices[index],
        [field]: value
      };
      return {
        ...prevFormData,
        prices: newPrices
      };
    });
  };

  const handleFormSubmit = () => {
    setFormIsSubmitting(true);
    setSubmitError(null);
    let apiAction;
    if (formData._id === undefined) {
      // Create new
      apiAction = onCreate(formData);
    } else {
      // Editing
      apiAction = onUpdate(formData._id, formData);
    }
    apiAction
      .then(() => {
        setFormData({
          name: "",
          description: "",
          seats: 0,
          price: 0,
          prices: [],
          archived: false,
          user: "user"
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };
  const handleDeleteSubmit = () => {
    setShowDelete(false);
    setFormIsSubmitting(true);
    setSubmitError(null);
    onDelete(formData._id)
      .then(() => {
        setFormData({
          name: "",
          description: "",
          seats: 0,
          price: 0,
          user: "user",
          prices: [],
          archived: false
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };

  return (
    <div>
      {submitError && (
        <div
          className={"p-2 border rounded border-red-800 bg-red-700 text-white"}
        >
          {submitError}
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={true}
          header={"Are you sure?"}
          content={
            "Are you sure you like to remove this Licence, this action can't be undone."
          }
          handleSubmit={handleDeleteSubmit}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

          <div className="border-b border-gray-900/10 pb-12">

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleOnChange}
                  error={formErrors.name}
                  disabled={formIsSubmitting}
                />
                {formErrors.name && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.name}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <Input
                  name="description"
                  value={formData.description}
                  onChange={handleOnChange}
                  error={formErrors.description}
                  disabled={formIsSubmitting}
                />
                {formErrors.description && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.description}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                User Type
              </label>
              <div className="mt-2">
                <SelectTwo
                  field="user"
                  value={formData.user}
                  options={[
                    {key: 0, text: "User", value: "user"},
                    {key: 1, text: "Admin", value: "admin"},
                  ]}
                  onChange={handleOnChange}
                  disabled={formIsSubmitting}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
                Seats
              </label>
              <div className="mt-2">
                  <Input
                    type="number"
                    name="seats"
                    value={formData.seats}
                    onChange={handleOnChange}
                    error={formErrors.seats}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.seats && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.seats}
                    </span>
                  )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="seats" className="block text-sm font-medium leading-6 text-gray-900">
                Price Per Month
              </label>
              <div className="mt-2">
                  <Input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleOnChange}
                    error={formErrors.price}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.price && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.price}
                    </span>
                  )}
              </div>
            </div>



          </div>
          {/*  
            <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Price Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Price
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Frequency
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <Button label="Add" />
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {formData.prices.map((d, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <Input
                          name="name"
                          value={d.name}
                          onChange={(e) => handlePriceChange(index, 'name', e.target.value)}
                          disabled={formIsSubmitting}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Input
                          name="number"
                          value={d.price}
                          // onChange={handleOnChange}
                          disabled={formIsSubmitting}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <SelectTwo
                        placeholder="Please Select Frequency"
                        field="type"
                        options={frequencyOptions}
                        className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        value={d.frequency}
                        // onChange={onChange}
                      />
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        &nbsp;
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>*/}

          <div className="mt-6 flex items-center justify-end gap-x-6">
              {editing && (
                  <Button
                    className="text-sm font-semibold leading-6 text-gray-900"
                    label={formData.archived ? "Un-Archive" : "Archive"}
                    colour={formData.archived ? "green" : "red"}
                    icon={formData.archived ? GiCheckMark : TrashIcon}
                    disabled={
                      Object.keys(formErrors).length > 0 ||
                      formIsSubmitting
                    }
                    onClick={handleDeleteSubmit}
                  />
                )}
          <Button
              label={editing ? "Save" : "Add"}
              colour={editing ? "blue" : "green"}
              icon={editing ? PencilIcon : PlusIcon}
              className={"rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"}
              disabled={
                Object.keys(formErrors).length > 0 ||
                formIsSubmitting || (editing && formData.archived)
              }
              onClick={handleFormSubmit}
            />
        </div>

        </div>

            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Description
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      User Type
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Price p/m
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Seats
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.length ? (
                    data.map((d, index) => (
                      <tr key={"court_" + index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.name + " "}{d.archived && <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                        Archived
                      </span>}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" style={{maxWidth: "150px", whiteSpace: "initial"}}>
                          {d.description}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.user}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.price}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.seats}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Button
                            label={""}
                            colour={"blue"}
                            icon={PencilIcon}
                            className={"ml-auto"}
                            onClick={() => {
                              setFormData({
                                _id: d._id,
                                name: d.name,
                                description: d.description,
                                price: d.price,
                                prices: d.prices,
                                seats: d.seats,
                                archived: d.archived,
                                user: d.user,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        There are no items to display.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LicenceTable;
