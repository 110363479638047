import React, { useState, useEffect, useCallback } from "react";
import { debounce } from "debounce";
import { SearchIcon } from "@heroicons/react/solid";
import API from "../../../api";
import ResultsContainer from "./_components/resultsContainer";
import CommonFunctions from "../../../CommonFunctions";

const initialBlankResults = {
  contacts: [],
  companies: [],
};

const GlobalSearch_HeaderBar = ({ history }) => {
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState(initialBlankResults);

  const runSearch = (term) => {
    setSearchLoading(true);
    API.search
      .global(term, 5)
      .then((res) => {
        setSearchLoading(false);
        setResults(res);
      })
      .catch((err) => CommonFunctions.handleError(err));
  };

  const searchDebounceHandler = useCallback(
    () =>
      debounce((data) => {
        runSearch(data);
      }, 500),
    []
  );

  useEffect(() => {
    if (searchTerm.length === 0) {
      setResults({ ...initialBlankResults });
      return;
    }
    const func = searchDebounceHandler();
    func(searchTerm);
  }, [searchTerm, searchDebounceHandler]);

  return (
    <div className="max-w-lg w-full lg:max-w-xs">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
          <SearchIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          className="block w-full bg-white py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-gray-500 focus:outline-none sm:text-sm"
          placeholder="Search"
          type="search"
          name="search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoComplete="off"
        />
      </div>
      {searchTerm.length !== 0 && (
        <div className="absolute mt-1 right-5 z-50 bg-white ring-2 ring-gray-400 rounded-xl">
          <ResultsContainer
            query={searchTerm}
            isLoading={searchLoading}
            results={results}
            isCompact
            history={history}
          />
        </div>
      )}
    </div>
  );
};
export default GlobalSearch_HeaderBar;
