import React from "react";
import { Switch, Route } from "react-router-dom";
import CreditChecks_Dashboard from "./dashboard";
import ReportView from "./report";
import NotFound from "../NotFound";
import * as Context from "./_context";

const CreditCheckIndex = ({
  routePrefix = "",
  defaultSearchName,
  getAllReports,
  getSingleReport,
  requestReport,
}) => {
  return (
    <Context.Provider
      routePrefix={routePrefix}
      getAllReports={getAllReports}
      defaultSearchName={defaultSearchName}
      requestReport={requestReport}
      getSingleReport={getSingleReport}
    >
      <Switch>
        <Route
          exact
          path={`${routePrefix}/credit_checks`}
          component={CreditChecks_Dashboard}
        />
        <Route
          exact
          path={`${routePrefix}/credit_checks/report/:creditReportId`}
          component={ReportView}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </Context.Provider>
  );
};
export default CreditCheckIndex;
