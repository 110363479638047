import React, { useEffect, useState } from "react";
import Segment from "../../components/Segment";
import API from "../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../CommonFunctions";
import { Button, Input } from "../../components/Forms";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import {
  UserIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import { GiBasket, GiCheckMark, GiHouse } from "react-icons/gi";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Purchase_License({ history }) {
  const [licence, setLicence] = useState({});

  const frequencies = [
    { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
    // { value: 'annually', label: 'Annually', priceSuffix: '/year' },
  ]

  const [frequency, setFrequency] = useState(frequencies[0])

  const [licenceTypes, setLicenceTypes] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [mount, setMount] = useState(true);
  const [org, setOrg] = useState({});

  useEffect(() => {
    API.organisations.getMyOrg().then((organisation) => setOrg(organisation)).finally(() => setMount(false));
  },mount);

  useEffect(() => {
    getData();
  }, []);

  function getData(){
    API.licences.getAll().then(res => {
        let arr = res.map((l, index) => {
          return ({
            key: index, 
            value: l._id, 
            text: l.description,
            name: l.name,
            price: l.price,
            seats: l.seats,
            user: l.user
          })
        });
        setLicenceTypes(arr)
    }
  
  )
  }

  if(mount){
    return "Loading..";
  }

  const purchaseDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Purchase License
                </Dialog.Title>
              </div>
              <div className="px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="">
                <h3
                  className={classNames(
                    'text-gray-900',
                    'text-lg font-semibold leading-8',
                  )}
                >
                  {licence && licence.name}
                </h3>
                <p className="mt-1 text-sm leading-6 text-gray-600">{licence && licence.text}</p>
                <p className="mt-1 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">£{licence && licence.price}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
                  </p>

                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="red"
                  label="Cancel"
                  onClick={() => {setIsOpen(false); setLicence({})}}
                  icon={XIcon}
                />

                <Button
                  colour="amber"
                  label="Purchase"
                  onClick={() => {
                    API.organisations.addLicence(org._id, {licence: licence.value}).then(res => {
                      if(res.success) {
                        toast.success(res.message);
                        history.push("/settings/account")
                      }else{
                        toast.error(res.message);
                      }
                    });
                  }}
                  icon={GiBasket}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      {purchaseDialog()}
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="">
            {/* Page header */}
            <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-primary">{org.name}</h1>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <GiHouse
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.addresses &&
                    org.addresses.find((item) => item.primary === true)
                      ? `${
                          org.addresses.find((item) => item.primary === true)
                            .postal_code
                        } (${
                          org.addresses.find((item) => item.primary === true).name
                        })`
                      : "N/A"}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <PhoneIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.contactNumber}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.contact}
                  </div>
                </div>
              </div>
            </div>
          </div>
            {/* Main Content */}
              <div className="mt-4 mx-auto md:grid md:grid-cols-1">
                  <div className="col" >
                  
                  <div className="bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mx-auto max-w-4xl text-center">
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                          Account Licences
                        </p>
                      </div>
                      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                          Purchase Account Licences for your users to access the system.
                      </p>
                      {/*  
                      <div className="mt-16 flex justify-center">
                        <fieldset aria-label="Payment frequency">
                          <RadioGroup
                            value={frequency}
                            onChange={setFrequency}
                            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
                          >
                            {licenceTypes.map((option) => (
                              <Radio
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                  classNames(
                                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                                    'cursor-pointer rounded-full px-2.5 py-1',
                                  )
                                }
                              >
                                {option.label}
                              </Radio>
                            ))}
                          </RadioGroup>
                        </fieldset>
                      </div>*/}
                      <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                        {licenceTypes && licenceTypes.map((l, index) => (
                          <div
                            key={index}
                            className={classNames(
                              'ring-1 ring-gray-200',
                              'rounded-3xl p-8',
                            )}
                          >
                            <h3
                              className={classNames(
                                'text-gray-900',
                                'text-lg font-semibold leading-8',
                              )}
                            >
                              {l.name}
                            </h3>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{l.text}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                              <span className="text-4xl font-bold tracking-tight text-gray-900">£{l.price}</span>
                              <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
                            </p>
                            <a
                              onClick={() => {
                                setIsOpen(true);
                                setLicence(l)
                              }}
                              className={classNames(
                                'text-primary ring-1 ring-inset ring-primary hover:bg-primary hover:text-white cursor-pointer',
                                'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
                              )}
                            >
                              Buy license
                            </a>
                            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                <li className="flex gap-x-3">
                                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                    License for {l.seats} {l.user}s
                                </li>
                                <li className="flex gap-x-3">
                                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                    Access to Pro Legal Flow System
                                </li>
                                <li className="flex gap-x-3">
                                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                    User Role (Clients, Matters, Tasks, Activities, Postbox ...)
                                </li>
                                <li className="flex gap-x-3">
                                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                    Postbox Authoriser Role (User with Postbox sending ability)
                                </li>
                                {l.user == "admin" && <li className="flex gap-x-3">
                                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                  Admin Role (Settings, Users, Global Postbox)
                                </li>}
                                
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  </div>  
                </div>
              <div>
            </div>  
          </main>
        </Segment>
      </div>
    </>
  );
}

export default Purchase_License;

