import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import DocManagement from "./docManagement";
import AddTemplate from "./add";
import TemplateNavbar from "../_components/TemplateNavbar";

const routePrefix = "/settings/doc_types";

export default function Index({history, match}) {
  console.log(`${routePrefix}/add`);
  return (
    <>
      <TemplateNavbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={DocManagement} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
