import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import Checklist from "../../../components/_checklists";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import DebtorBar from "./_components/DebtorBar";
import { Authentication } from "../../../plugins/clio_auth";
import DocumentsListView from "./_components/documentsListView";

function DebtorView({ matter, debtor, history }) {
  const params = useParams();

  const [data, setData] = useState({});
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.matters.debtors
        .get(params.matter_id, params.debtor_id)
        .then((res) => setData(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setMountLoading(false));
    }
  }, [params, mountLoading]);

  return (
    <div>
      {mountLoading ? (
        <Loader />
      ) : (
        <Segment>
          <DebtorBar
            debtor={debtor}
            contact={matter.contact}
            history={history}
            showEdit
            showArchive={Authentication.can("settings.edit")}
          />
          <div className="md:grid md:grid-cols-2">
            <Segment>
              <div className="leading-6 font-medium text-xl text-gray-900 border-b pb-1 border-gray-300 my-2">
                Debtor Summary
              </div>

              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Debtor
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.debtor_salutation ? data.debtor_salutation + " " : ""}{data.debtor_name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Reg. #
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.companyRegistrationNumber}
                    </dd>
                  </div>
                  {/*  
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Company Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.company_type}
                    </dd>
                  </div>*/}
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Outstanding Balance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {(data.amount_owed && (data.amount_owed).toFixed(2)) + " " + data.currency}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.description}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Other Information
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.other_information}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Invoice Documents
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <DocumentsListView files={data.files} noDocumentsText={"There are no invoices uploaded"} />
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Associated Files
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <DocumentsListView files={data.associated_files} noDocumentsText={"There are no associated files uploaded"} />
                    </dd>
                  </div>
                </dl>
              </div>
            </Segment>
            <Checklist
              type="debtor"
              parentId={params.debtor_id}
              createTask={async (checklist_id) =>
                await Promise.all([
                  API.settings
                    .checklist("debtor")
                    .createChecklistTask(params.debtor_id, checklist_id),
                ])
              }
            />

            <Segment>
              <div className="leading-6 font-medium text-xl text-gray-900 border-b pb-1 border-gray-300 my-2">
                Debtor Address
              </div>

              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Street
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.street}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Address 2
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.address2}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">City</dt>
                    <dd className="mt-1 text-sm text-gray-900">{data.city}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      County
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.county}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Postcode
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.postcode}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Country
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.country}
                    </dd>
                  </div>
                </dl>
              </div>
            </Segment>
            <Segment>
              <div className="leading-6 font-medium text-xl text-gray-900 border-b pb-1 border-gray-300 my-2">
                Debtor Contact
              </div>

              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Debtor
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.contact_title + " " + data.contact_name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.contact_email}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Phone</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.contact_phone}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Letter Salutation</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.letter_salutation}
                    </dd>
                  </div>
                </dl>
              </div>
            </Segment>
          </div>
        </Segment>
      )}
    </div>
  );
}
export default ChainedContext(DebtorView, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
