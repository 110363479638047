import Bridge from "./bridge";

class Authentication {
    constructor(){
        this.userData     = {};
        this.access_token = null;
    }

    setUserData(data){
        this.userData = data;
    }
    getUserData(){
        return this.userData;
    }

    setUserScopes(data){
        this.userScopes = data.map(i => i.toLowerCase());
    }
    getUserScopes(){
        return this.userScopes;
    }

    setAccessToken(token){
        this.access_token = token;
        if(token === null){
            localStorage.removeItem(Bridge.getConfig("localPrefix") + "access_token")
        } else {
            localStorage.setItem(Bridge.getConfig("localPrefix") + "access_token", token);
        }
    }
    getAccessToken(){
        if(this.access_token === null){
            this.access_token = localStorage.getItem(Bridge.getConfig("localPrefix") + "access_token");
        }
        return this.access_token;
    }

    can(scope, context){
        scope = scope.toLowerCase();
        let hasScope = this.getUserScopes().indexOf(scope) !== -1;
        let contextHandlers = Bridge.getContextHandlers();
        if(hasScope && contextHandlers[scope]){
            let func = contextHandlers[scope];
            if(typeof func === "function"){
                return func(this.getUserData(), context);
            }
        }
        return hasScope;
    }
}

export default new Authentication();