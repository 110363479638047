import React from "react";
import Contact from "./Contact_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
const Receiver = (Compo, mapContext) => {
  return (props) => {
    return (
      <Contact.Consumer>
        {(context) => <Compo {...joinProps(props, mapContext(context))} />}
      </Contact.Consumer>
    );
  };
};
export default Receiver;
