import React from "react";

export default function Group(props) {
  const gridColList = [
    "md:grid-cols-1",
    "md:grid-cols-2",
    "md:grid-cols-3",
    "md:grid-cols-4",
    "md:grid-cols-5",
    "md:grid-cols-6",
    "md:grid-cols-7",
    "md:grid-cols-8",
    "md:grid-cols-9",
    "md:grid-cols-10",
    "md:grid-cols-11",
    "md:grid-cols-12",
  ];

  return (
    <div
      className={`grid ${
        Array.isArray(props.children) === false
          ? gridColList[0]
          : props.children
          ? gridColList[
              props.children.length - 1 < 11 ? props.children.length - 1 : 11
            ]
          : ""
      } gap-4 ${props.className ? props.className : ""}`}
    >
      {props.children}
    </div>
  );
}
