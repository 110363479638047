import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";
import Segment from "../../components/Segment";
import { Button } from "../../components/Forms";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import { DocumentIcon, MailIcon, PencilIcon, PlusIcon, PrinterIcon, RefreshIcon, XCircleIcon, XIcon } from "@heroicons/react/solid";
import _appConfig from "../../_appConfig";
import { CheckCircleIcon, PaperClipIcon } from "@heroicons/react/outline";
import { toast } from "react-hot-toast";
import { Authentication } from "../../plugins/clio_auth";
import { Dialog, Menu, Transition } from "@headlessui/react";
import 'suneditor/dist/css/suneditor.min.css';
import HtmlEditor from "../../components/widgets/HtmlEditor"; // Import Sun Editor's CSS File
import { getList } from "../../components/widgets/editorShortcodes/shortcodes";

export default function View() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const [data, setData] = useState({});
  const [vars, setVars] = useState([]);

  const [mountLoading, setMountLoading] = useState(true);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [generatingDocument, setGeneratingDocument] = useState(false);

  const [documentTemplates, setDocumentTemplates] = useState([]);

  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(null);
  const [documentContent, setDocumentContent] = useState("");

  useEffect(() => {
    if (mountLoading) {
      API.postbox
        .get(params.postbox_id)
        .then(async(res) => {
          setData(res);
          setVars(await getList(true, {matter: res.matter, defendants: res.defendants, courts: res.courts, claiments: res.claiments}))
          API.settings.email_templates().list().then((res) => {
            setDocumentTemplates(res);
            setMountLoading(false);
          });
        })
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [mountLoading, params]);

  const handleWYSIWYGUpload = (file) => {
    let formData = new FormData();
      formData.append("file", file);
      formData.append("type", "WYSIWYG");
    
    return new Promise((resolve, reject) => {
      API.settings
        .uploadFile(formData)
        .then((res) => {
          resolve({ data: { link: _appConfig.api_server + res } });
        });
    }); 
  };

  const documentModal = () => { 

    // let template = documentTemplates.filter(e => e._id === openDocumentModal);
    let template = [documentTemplates[0]];

    return(
      <Transition.Root show={openDocumentModal != null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenDocumentModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Document: {template[0] && template[0].name}
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-2">
                          <HtmlEditor
                            content={documentContent}
                            options={{
                              height: 400,
                            }}
                            onContentUpdate={(d) => setDocumentContent(d)}
                          />
                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>
                        <p className="text-sm text-gray-500 sm:col-span-1">
                          Preview:
                          <div style={{maxHeight: "350px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/*  
                  <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Email Document
                  </button>*/}
                  <button
                    disabled={generatingDocument}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setGeneratingDocument(true);
                      API.matters.tasks.documentGenerate(params.matter_id, params.task_id, {
                        template:openDocumentModal,
                        content: documentContent
                      }).then((res) => {
                        setGeneratingDocument(false);
                        if(res.success) {
                          setOpenDocumentModal(null);
                          window.open(
                            _appConfig.api_server + res.file,
                            "_blank",
                            "noopener"
                          );
                          API.matters.tasks.update(params.matter_id, params.task_id, {document: res.file_id}).then(() => { setMountLoading(true); });
                          /*
                          API.matters.tasks
                          .updateStatus(params.matter_id, params.task_id, {document: res.file_id})
                          .then(() => setMountLoading(true))
                          .catch((e) => CommonFunctions.handleError(e))*/
                        }
                      })
                    }}
                  >
                    {generatingDocument ? <>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </> : "Generate Document"}
                    
                  </button>
                  {/*  
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpenDocumentModal(false)
                      window.open(
                        _appConfig.api_server + openDocumentFile,
                        "_blank",
                        "noopener"
                      )
                    }}
                  >
                    Download Document
                  </button>*/}
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  const emailModal = () => { 
    return(
      <Transition.Root show={openEmailModal != null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenEmailModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <MailIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Edit Email
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-2">
                          <HtmlEditor
                            content={documentContent}
                            options={{
                              height: 400,
                            }}
                            onContentUpdate={(d) => setDocumentContent(d)}
                          />

                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>
                        <p className="text-sm text-gray-500 sm:col-span-1">
                          Preview:
                          <div style={{maxHeight: "350px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {

                      API.postbox.update(params.postbox_id, 
                        {
                          email_content: documentContent
                        })
                      .then(() => {setMountLoading(true); setOpenEmailModal(null); toast.success("Postbox successfully updated")})
                      .catch((e) => CommonFunctions.handleError(e))
                    }}
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenEmailModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }


  const previewContent = () => {

    let matter = data.matter;

    let html = documentContent ? documentContent : "";

    // let vars = await getList(true, {matter});

      if(html && matter){

        let variables = vars.reduce((result, v) => {
          result[v.key] = v.value;
          return result;
        }, {});

          const keys = Object.keys(variables);
          for (let i in keys) {
            const key = keys[i];
            html = html.replace(
              new RegExp("\\{" + key + "\\}", "g"),
              variables[key]
            );
          }
      }

    return html;
  }

  const newDocumentModal = () => {

    return <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={setOpen}>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DocumentIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Add Document
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Select
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  onClick={() => setOpen(false)}
                >
                  Deactivate
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
  }

  return (
    <div className="">
      {newDocumentModal()}
      {emailModal()}
      {documentModal()}
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Postbox {data.postbox_type}</span>
            {data.completed && <div>
              {data.status == "Cancelled" || data.status == "Unapproved" ? <XCircleIcon className="h-5 w-5 flex-shrink-0 text-red-400 inline-block" aria-hidden="true" /> :
            <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-400 inline-block" aria-hidden="true" />} {data.status} at {dayjs(data.completedAt).format("DD/MM/YYYY h:mm A")}
            </div>}
            {Authentication.can("postbox.approve") && data.status !== "Cancelled" && !data.completed &&
            <>
            <span className="isolate inline-flex rounded-md shadow-sm">
            <Button
              disabled={sendingEmail}
              colour={"red"}
              label={"Mark Unapproved"}
              onClick={() => {
                setSendingEmail(true);
                API.postbox.unapproveEmail(params.postbox_id).then((res) => {
                  if(res.success){
                    setSendingEmail(false);
                    toast.success("unapproved successfully");
                    setMountLoading(true);
                  }else{
                    toast.error("could not be updated, errors occured!");
                  }
                })
              }
              }
              className="ml-auto text-sm h-10"
              icon={XIcon}
            />
            <div className="flex justify-center px-3">
              <Menu
                  as="div"
                  className="relative inline-block text-left w-full"
                >
                  <Menu.Button className="flex justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                    <PencilIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Edit
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          <div
                            onClick={() => API.postbox.switchPostboxType(params.postbox_id).then((res) => {
                              if(res.success){
                                toast.success("Postbox switched successfully");
                                setMountLoading(true);
                              }else{
                                toast.error("could not be updated, errors occured!");
                              }
                            })}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            <RefreshIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            {data.postbox_type == "print" ? 
                            <MailIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            : <PrinterIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />}
                            
                            Switch to {data.postbox_type == "print" ? "Email" : "Print"}
                          </div>
                        </Menu.Item>
                        {/*  
                        <Menu.Item>
                          <div
                            // onClick={() => {setEditing("document"); setShowEditing(true)}}
                            onClick={() => {
                              // setOpenDocumentModal(true);
                              // setDocumentContent(data.content);
                              setOpen(true);
                            }}
                            className={
                              
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            <PlusIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            <DocumentIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            New Document
                          </div>
                        </Menu.Item>*/}
                        {data.postbox_type !== "print" &&
                        <Menu.Item>
                          <div
                            // onClick={() => {setEditing("email"); setShowEditing(true)}}
                            onClick={() => {
                              setOpenEmailModal(data.emailTemplate ? data.emailTemplate._id : "email")
                              setDocumentContent(data.content);
                            }}
                            className={
                              
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            <PencilIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            <MailIcon
                              className="-ml-1 mr-2 h-5 w-5 inline"
                              aria-hidden="true"
                            />
                            Edit Email
                          </div>
                        </Menu.Item>}
                      </div>
                    </Menu.Items>
                  </Transition>
              </Menu>
            </div>
            <Button
              disabled={sendingEmail}
              colour={sendingEmail ? "amber" : "green"}
              label={sendingEmail ?<>
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
              </> : data.postbox_type == "print" ? "Approve Print" : "Approve Email"}
              onClick={() => {
                setSendingEmail(true);

                if(data.postbox_type == "print"){
                  API.postbox.approveEmail(params.postbox_id).then((res) => {
                    if(res.success){
                      setSendingEmail(false);
                      toast.success("Print marked successfully");
                      setMountLoading(true);

                      API.settings
                      .getFileLink(
                          data.attachment
                      )
                      .then((res) => {
                        window.open(
                          _appConfig.api_server + res,
                          "_blank"
                        );
                      }); 

                    }else{
                      toast.error("could not be sent, errors occured!");
                    }
                  })

                }else{
                  API.postbox.approveEmail(params.postbox_id).then((res) => {
                    if(res.success){
                      setSendingEmail(false);
                      toast.success("Sent successfully");
                      setMountLoading(true);
                    }else{
                      toast.error("could not be sent, errors occured!");
                    }
                  })
                } 
              }
              }
              className="ml-1 text-sm h-10"
              icon={data.postbox_type == "print" ? PrinterIcon : MailIcon}
            />
            </span>
            
            </>
             }
            {/*  
            <Button
              colour="blue"
              label="Edit"
              onClick={() => history.push(`${match.url}/edit`)}
              className="ml-4 text-lg"
              icon={PencilIcon}
            />*/}
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      To
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.send}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Created
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(data.createdAt).format("DD/MM/YYYY")} {data.actionedBy && `by ${data.actionedBy.firstName} ${data.actionedBy.lastName}`}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type}
                    </dd>
                  </div>
                </dl>

                <p>&nbsp;</p>
                
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.status}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Title
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.title}
                    </dd>
                  </div>
                  {data.emailTemplate && data.postbox_type !== "print" &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Email Subject
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.emailTemplate && data.emailTemplate.subject}
                    </dd>
                  </div>}
                </dl>
                <p>&nbsp;</p>

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Attachment
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    {data.attachment &&
                      <div className="sm:col-span-1">
                        <dd className="mt-1 text-sm text-gray-900">
                        <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 w-0 flex-1 truncate">{data.file_name}</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={() => {
                                
                                API.settings
                                .getFileLink(
                                    data.attachment
                                )
                                .then((res) => {
                                  window.open(
                                    _appConfig.api_server + res,
                                    "_blank"
                                  );
                                }); 
                              }}>
                                View
                              </a>
                            </div>
                          </li>
                        </ul>
                        
                        </dd>
                      </div> }
                        </dd>
                  </div>
                </dl>
                <p>&nbsp;</p>

                {data.preview && data.postbox_type !== "print" &&
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Email
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    <div className="mt-5" dangerouslySetInnerHTML={{__html: data.preview}}/>
                    </dd>
                  </div>
                </dl>}
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
