import React from "react";
import PropTypes from "prop-types";

export default function ThemeTable({
  header = [],
  body = [],
  className = "",
  shadow = false,
  selectable = true,
  action = () => {},
  noDataMessage = "No Data...",
}) {
  return (
    <div className={`overflow-x-auto text-lg ${shadow ? " shadow-lg" : ""}`}>
      <table
        className={`min-w-full divide-y divide-gray-300 border border-gray-300 table-fixed ${className}`}
      >
        <thead>
          <tr>
            {header.map((header_item, header_index) => (
              <th
                key={header_index}
                scope="col"
                className={`tracking-widest font-normal text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 px-4 py-3 text-left ${header_item.width}`}
              >
                {header_item.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-300">
          {body.length > 0 ? (
            body.map((body_item, body_index) => (
              <tr
                key={"body_" + body_index}
                className={`${
                  selectable === true
                    ? body_item.archived
                      ? "hover:bg-red-300 focus:bg-red-300 bg-red-200"
                      : "hover:bg-gray-100 focus:bg-gray-100"
                    : ""
                } ${selectable === true ? "cursor-pointer" : ""}`}
                onClick={() => action(body_item, body_index)}
              >
                {header.map((header_item, header_index) => (
                  <td key={"head_" + header_index} className="px-4 py-4">
                    {body_item[header_item.value]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr
              className={`${
                selectable === true
                  ? "hover:bg-gray-100 focus:bg-gray-100 cursor-pointer"
                  : ""
              } text-black `}
            >
              <td colSpan={header.length} className="px-4 py-4">
                {noDataMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

ThemeTable.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  body: PropTypes.array.isRequired,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  selectable: PropTypes.bool,
  action: PropTypes.func,
  noDataMessage: PropTypes.string,
};
