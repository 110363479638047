import React, { Fragment, useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";
import Segment from "../../components/Segment";
import { Button } from "../../components/Forms";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import { DocumentIcon, MailIcon, PencilIcon, XCircleIcon } from "@heroicons/react/solid";
import _appConfig from "../../_appConfig";
import { CheckCircleIcon, PaperClipIcon } from "@heroicons/react/outline";
import { Authentication } from "../../plugins/clio_auth";
import { Dialog, Transition } from "@headlessui/react";

export default function View() {
  const params = useParams();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  const [openEmailModal, setOpenEmailModal] = useState(null);

  useEffect(() => {
    if (mountLoading) {
      API.notifications
        .get(params.notification_id)
        .then((res) => {
          console.log("🚀 ~ file: view.js:36 ~ .then ~ res:", res);
          setData(res);
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setMountLoading(false));
    }
  }, [mountLoading, params]);

  const emailModal = () => { 
    return(
      <Transition.Root show={openEmailModal != null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenEmailModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <MailIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Edit Email
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className="text-sm text-gray-500 sm:col-span-2">
                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>
                        <p className="text-sm text-gray-500 sm:col-span-1 sun-editor-editable">
                          Preview:
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Update
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenEmailModal(null)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  return (
    <div className="">
      {emailModal()}
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Notification {data.postbox_type}</span>
            {data.completed && <div>
              {data.status == "Cancelled" || data.status == "Unapproved" ? <XCircleIcon className="h-5 w-5 flex-shrink-0 text-red-400 inline-block" aria-hidden="true" /> :
            <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-400 inline-block" aria-hidden="true" />} {data.status} at {dayjs(data.completedAt).format("DD/MM/YYYY h:mm A")}
            </div>}
            {Authentication.can("notifications.edit") && data.status !== "Cancelled" && !data.completed &&
              <>
                <span className="isolate inline-flex rounded-md shadow-sm">
                  <Button
                    disabled={true}
                    label="Edit"
                    className="ml-1 text-sm h-10"
                    icon={PencilIcon}
                  />
                </span>
              </>
             }
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      To
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type === "global" ? "All - Global Message" : data.actionedFor && data.actionedFor.name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Created
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {dayjs(data.createdAt).format("DD/MM/YYYY")} {data.actionedBy && `by ${data.actionedBy.firstName} ${data.actionedBy.lastName}`}
                    </dd>
                  </div>
                  {Authentication.can("notifications.global") &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.type}
                    </dd>
                  </div>}
                </dl>

                <p>&nbsp;</p>
                
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Title
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.title}
                    </dd>
                  </div>
                  {data.emailTemplate && data.postbox_type !== "print" &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Email Subject
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.emailTemplate && data.emailTemplate.subject}
                    </dd>
                  </div>}
                </dl>
                <p>&nbsp;</p>

                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Attachment
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    {data.attachment &&
                      <div className="sm:col-span-1">
                        <dd className="mt-1 text-sm text-gray-900">
                        <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <div className="flex w-0 flex-1 items-center">
                              <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <span className="ml-2 w-0 flex-1 truncate">{data.file_name}</span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={() => {
                                
                                API.settings
                                .getFileLink(
                                    data.attachment
                                )
                                .then((res) => {
                                  window.open(
                                    _appConfig.api_server + res,
                                    "_blank"
                                  );
                                }); 
                              }}>
                                View
                              </a>
                            </div>
                          </li>
                        </ul>
                        
                        </dd>
                      </div> }
                        </dd>
                  </div>
                </dl>
                <p>&nbsp;</p>

                {data.preview && data.postbox_type !== "print" &&
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Email
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    <div className="mt-5" dangerouslySetInnerHTML={{__html: data.preview}}/>
                    </dd>
                  </div>
                </dl>}
              </div>
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
