import {Dialog, Transition} from "@headlessui/react";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import React, {Fragment, useEffect, useState} from "react";
import {useIdleTimer} from "react-idle-timer";

const IdleTimeout = ({onHeartbeat, onIdleExpiry, maxIdleTime = 60, preWarningTime = 5}) => {
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const [idleBackground, setIdleBackground]       = useState(true);
  const [countdownText, setCountdownText]         = useState("");
  const onPrompt         = () => {
    setShowInactiveModal(true);
    setIdleBackground(false);
  }
  const onActive         = () => {
    setShowInactiveModal(false);
    setIdleBackground(true);
    onHeartbeat();
  }
  const onIdle = () => {
    onIdleExpiry();
  }

  const IdleTimer = useIdleTimer({
    timeout: 1000 * 60 * maxIdleTime,
    promptBeforeIdle: 1000 * 60 * preWarningTime,
    onIdle,
    onActive,
    onPrompt,
    crossTab: true,
    syncTimers: 60,
    leaderElection: true
  });

  useEffect(() => {
    if (showInactiveModal){
      const finalCountdown = setInterval(() => {
        const secondsLeft  = Math.ceil(IdleTimer.getRemainingTime() / 1000);
        const mins         = Math.floor((secondsLeft % 3600) / 60);
        const secs         = Math.floor(secondsLeft % 60);
        setCountdownText(((mins < 10 ? "0" : "") + mins) + ":" + ((secs < 10 ? "0" : "") + secs));
      }, 1000);
      return () => {
        clearInterval(finalCountdown);
      }
    }
  }, [showInactiveModal]);
  useEffect(() => {
    if (idleBackground){
      const finalCountdown = setInterval(() => onHeartbeat(), 2 * 60 * 1000);
      return () => {
        clearInterval(finalCountdown);
      }
    }
  }, [idleBackground]);


  const handleOnActive = () => {
    setShowInactiveModal(false);
    IdleTimer.activate();
  };

  return (
    <>
      <Transition.Root show={showInactiveModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => { /* Do nothing */ }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true"/>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          Account Inactivity
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            You appear to be inactive. To maintain your account security you will be automatically logged off in:
                            <span className="ml-2 inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">{countdownText}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() => handleOnActive()}
                    >
                      Keep me logged in
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default IdleTimeout;