import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import AppConfig from "./_appConfig";
import Auth from "./plugins/clio_auth";
import reportWebVitals from "./reportWebVitals";
import CaptureBackgroundAuth from "./plugins/captureBackgroundAuth";

import "./index.css";
import {SocketConnection} from "./plugins/socketio-with-auth";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CaptureBackgroundAuth>
        <Auth.LoginWrapper
          config={{
            applicationRoot: AppConfig.applicationRoot, // Clio requires 127.0.0.1 for local development
            applicationServer: AppConfig.api_server, // Required during local development
            localPrefix: AppConfig.localStoragePrefix,
          }}
        >
          <SocketConnection socketServer={AppConfig.api_server}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </SocketConnection>
        </Auth.LoginWrapper>
      </CaptureBackgroundAuth>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
