import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../components/NotFound";
import Navbar from "./_components/Navbar";
import SettingsProvider from "../../contexts/settings_context/Settings_Provider";
import API from "../../api";

import Dash from "./dashboard";
import { Authentication } from "../../plugins/clio_auth";
import Organisations from "./organisations";
import Licences from "./licences";

const routePrefix = "/admin";

export default function Index({ history, match }) {

  if(!Authentication.can("settings.view")){
    return <Switch><Route path="*" component={NotFound} /></Switch>;
  }

  return (
    <div>
      <SettingsProvider api={API}>
        <Navbar history={history} match={match} />
        <Switch>
          <Route
            path={`${routePrefix}/licences`}
            component={Licences}
          />
          <Route
            path={`${routePrefix}/organisations`}
            component={Organisations}
          />
          <Route exact path={`${routePrefix}`} component={Dash} />
          <Route path="*" component={NotFound} />
        </Switch>
      </SettingsProvider>
    </div>
  );
}
