import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import Segment from "../../../../components/Segment";
import { Button } from "../../../../components/Forms";
import Loader from "../../../../components/Loader";
import dayjs from "dayjs";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import DataField from "../../../../components/DataField";

export default function View() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.matters.debtors.tasks
        .get(params.matter_id, params.debtor_id, params.task_id)
        .then((res) => {
          setData(res);
          setMountLoading(false);
        })
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [mountLoading, params]);

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Task:</span>
            <Button
              colour={data.complete ? "red" : "green"}
              label={data.complete ? "Mark Incomplete" : "Mark Completed"}
              onClick={() =>
                API.matters.debtors.tasks
                  .updateStatus(
                    params.contact_id,
                    params.debtor_id,
                    params.task_id
                  )
                  .then(() => setMountLoading(true))
                  .then(() => history.push(`/matters/${params.matter_id}/debtors/${params.debtor_id}/tasks`))
                  .catch((e) => CommonFunctions.handleError(e))
              }
              className="ml-auto text-lg"
              icon={data.complete ? XIcon : CheckIcon}
            />
            <Button
              colour="blue"
              label="Edit"
              onClick={() => history.push(`${match.url}/edit`)}
              className="ml-4 text-lg"
              icon={PencilIcon}
            />
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <div className="grid sm:grid-cols-2">
              <div className="col-span-2">
                <DataField label={"Details"} data={data.description} />
              </div>
              <DataField label={"Fee Earner"} data={data.assigned_name} />
              <DataField
                label={"Due Date"}
                data={dayjs(data.due_date).format("DD/MM/YYYY")}
              />
              <DataField
                label={"Status"}
                data={`${data.complete ? "Complete" : "Incomplete"} ${
                  data.complete
                    ? `(${
                        data.complete_date
                          ? dayjs(data.complete_date).format("DD/MM/YYYY")
                          : ""
                      })`
                    : ""
                }`}
              />
              <DataField
                label={"Time"}
                data={`${data.time} ${data.time === 1 ? "Minute" : "Minutes"} ${
                  data.isBillable ? "(Billable)" : "(Non-billable)"
                }`}
              />
            </div>
          )}
        </div>
      </Segment>
    </div>
  );
}
