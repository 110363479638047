import React from "react";

const DataField = ({
  label,
  data,
  colour,
  left = false,
  textColour,
  horizontal = false,
  onClick,
  showOnUndefined = true,
  pre = "",
  post = "",
}) => {
  return (
    <div
      className={`  border border-gray-300	${colour}	${
        horizontal
          ? "flex pb-0"
          : `${showOnUndefined === true ? "pb-2" : ""} flex-1`
      } `}
    >
      <dt
        className={`text-lg pb-2 pt-2  ${
          horizontal
            ? " w-1/3 border-r text-left pl-4 "
            : `${showOnUndefined === true ? "border-b" : ""} text-center`
        } tracking-widest text-primary bg-gray-150 bg-opacity-60 text-mono border-gray-300 `}
      >
        {label}
      </dt>
      {showOnUndefined === true ? (
        <dd
          className={`mt-1 text-lg ${textColour} sm:mt-0 pt-2 ${
            horizontal && " w-2/3 "
          } ${left ? "text-left" : "text-center"} px-2 items-center ${
            onClick ? "underline cursor-pointer" : ""
          }`}
          onClick={onClick ? onClick : () => {}}
        >
          {pre} {data ? data : "--"} {post}
        </dd>
      ) : (
        <></>
      )}
    </div>
  );
};
export default DataField;
