import { useState } from 'react'

import List from "./list-licences";

import screenshot from "../../../assets/bridge-screenshot.JPG";
import LOGO from "../../../assets/plc-logo.svg";

const navigation = [
//   { name: 'Product', href: '#' },
//   { name: 'Features', href: '#' },
//   { name: 'Marketplace', href: '#' },
//   { name: 'Company', href: '#' },
]

export default function FrontEnd({redirectLogin}) {

  const Page = () => (
    <div>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <img
                className="h-10 w-auto"
                src={LOGO}
                alt=""
              />
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          {/*  
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a onClick={() => redirectLogin()} className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
              Log in via Clio <span aria-hidden="true">&rarr;</span>
            </a>
          </div>*/}
        </nav>
      </header>

      <div className="relative isolate pt-14">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="py-10">
          <div className="mx-auto max-w-7xl px-3">
            <div className="mx-auto max-w-2xl text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Pro Legal Flow
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Welcome to the Pro Legal Flow system by Pro Legal Group, please login via Clio to access the system where you will first be presented with the options of licenses, licenses are listed below.
              </p>
              <div className="mt-6 flex items-center justify-center gap-x-6">
                <a
                  onClick={() => redirectLogin()}
                  className="cursor-pointer rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                >
                  Login via Clio &rarr;
                </a>
              </div>
            </div>
            <div className="mt-10 flow-root">
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  src={screenshot}
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </div>
            <div className="mt-16  text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Licenses
              </h1>
                <List />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div className="h-screen flex bg-gray-150 dark:bg-gray-800 dark:text-gray-200">
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            <div className="absolute inset-0">
              <div className="h-full">
                <Page />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}