import React from "react";
import { Switch, Route } from "react-router-dom";
import ContactCustomFields from "../../../../settings/custom_fields/contactFields/contactFields";
import AddTemplate from "../../../../settings/custom_fields/contactFields/add";
import CustomFieldsNavbar from "./CustomFieldsNavbar"

const routePrefix = "/admin/organisations/:org_id/client_custom_fields";

export default function Index({history, match}) {
  return (
    <>
      <CustomFieldsNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={ContactCustomFields} />
      </Switch>
    </>
  );
}
