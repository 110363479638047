import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import ServiceManagement from "./serviceManagement";

const routePrefix = "/settings/services";

export default function Index() {
  return (
    <>
      <Switch>
        <Route path={`${routePrefix}`} component={ServiceManagement} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
