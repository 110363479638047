import React from "react";
import PropTypes from "prop-types";
import { GiMagnifyingGlass } from "react-icons/gi";
import { DocumentIcon } from "@heroicons/react/solid";
import API from "../../api";
import _appConfig from "../../_appConfig";

export default function ThemeTable({
  header = [],
  body = [],
  className = "",
  shadow = false,
  selectable = true,
  action = () => {},
  noDataMessage = "No Data...",
}) {
  return (
    <div className={`text-lg ${shadow ? " shadow-lg" : ""}`}>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {header.map((header_item, i) => {
                      return (
                        <th
                          key={"th_" + i}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header_item.text}
                        </th>
                      );
                    })}
                    {!selectable && <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Actions</th>}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {body.length > 0 ? (
                    body.map((body_item, body_index) => (
                      <tr
                        key={body_index}
                        className={`${
                          selectable === true
                            ? body_item.archived
                              ? "hover:bg-red-300 focus:bg-red-300 bg-red-200"
                              : "hover:bg-gray-100 focus:bg-gray-100"
                            : ""
                        } ${selectable === true ? "cursor-pointer" : ""}`}
                        onClick={() => selectable === true && action(body_item, body_index)}
                      >
                        {header.map((header_item, header_index) => (
                          <td className={`py-4 pl-4 pr-3 text-sm font-medium ${body_item.extra_row_css ? body_item.extra_row_css : "text-gray-900" } sm:pl-6`}>
                            {body_item[header_item.value]}
                          </td>
                        ))}
                        {!selectable && 
                          <td>
                            <span className="isolate inline-flex rounded-md shadow-sm">
                              <button
                                type="button"
                                className={`relative inline-flex items-center ${body_item.view_document ? "rounded-l-md" : "rounded-md"} bg-blue-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-400 focus:z-10`}
                                onClick={() => action(body_item, body_index)}
                              >
                                <GiMagnifyingGlass className="flex-shrink-0 h-5 w-5" aria-hidden="true" />{" "}View
                              </button>
                              {body_item.view_document &&
                              <button
                                onClick={() => API.settings
                                  .getFileLink(
                                    body_item.view_document
                                  )
                                  .then((res) => {
                                    window.open(
                                      _appConfig.api_server + res,
                                      "_blank"
                                    );
                                  })}
                                type="button"
                                className="relative -ml-px inline-flex items-center rounded-r-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 hover:bg-blue-400 focus:z-10"
                              >
                              <DocumentIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />{" "}Document
                              </button>}
                            </span>
                          </td>
                        }
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan={header.length}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                    >
                      No Data...
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ThemeTable.propTypes = {
  header: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  body: PropTypes.array.isRequired,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  selectable: PropTypes.bool,
  action: PropTypes.func,
  noDataMessage: PropTypes.string,
};
