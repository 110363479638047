import React, { useState, useEffect } from "react";
import {
  HomeIcon,
  MenuIcon,
  UserGroupIcon,
  CogIcon,
  BookOpenIcon,
  SearchCircleIcon,
  MailIcon,
  OfficeBuildingIcon
} from "@heroicons/react/outline";
import { withRouter } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import SideBar from "./components/Navigation/SideBar";
import IndexRoutes from "./pages";

import "react-datepicker/dist/react-datepicker.css";
import API from "./api";
import CommonFunctions from "./CommonFunctions";
import dayjs from "dayjs";
import { ClipboardIcon } from "@heroicons/react/solid";
import { GiMailbox } from "react-icons/gi";

function App({ history }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [latePaymentNotificationLoad, setLatePaymentNotificationLoad] = useState(false);

  const [
    isEmailsLoading,
    isNotificationsLoading,
    // setContactSyncing
  ] = useState(false);
  
  const [emails, setEmails] = useState([]);
  const [notifications, setNotifications] = useState([]);
  
  useEffect(() => {
    if (isEmailsLoading === false) {
      Promise.all([
        API.postbox.getAll().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        setEmails(res[0]);
      });
    }
  }, [isEmailsLoading]);

  useEffect(() => {
    if (isNotificationsLoading === false) {
      Promise.all([
        API.notifications.getAll().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        setNotifications(res[0]);
      });
    }
  }, [isNotificationsLoading]);

  useEffect(() => {
    if (latePaymentNotificationLoad === false) {
      Promise.all([
        API.settings.base_rates.late_payments.get().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        if(res[0]) {

          let previousYear = res[0].filter((d) => {
            if(dayjs(d.date).year() === (dayjs().year() - 1)){
              return d;
            }
          });

          let currentYear = res[0].filter((d) => {
            if(dayjs(d.date).year() === dayjs().year()){
              return d;
            }
          });
          
          let current = dayjs();

          let error = null;

          if(current.month() >= 0 && current.month() < 5){
            // JAN - JUNE >> DEC - JUNE

            let prevDateRange = previousYear.filter((d) => {
              if(dayjs(d.date).month() == 11 && dayjs(d.date).date() > 30){
                return d;
              }
            })

            let dateRange = currentYear.filter((d) => {
              if(dayjs(d.date).month() >= 0 && dayjs(d.date).month() < 5){
                return d;
              }
            })

            if(!dateRange.length > 0 && !prevDateRange.length > 0){
              error = "Alert: No Late Pay Rates for current period (DEC/JAN-JUN)";
            }

          }else{

            let dateRange = currentYear.filter((d) => {

              if(dayjs(d.date).month() >= 5){
                return d;
              }
            })

            if(!dateRange.length > 0){
              error = "Alert: No Late Pay Rates for current period (JUL-DEC)";
            }

          }

          if(error){
            toast.error(error, {position: "top-center"});
          }

        }else{
          toast.error("Alert: No Late Pay Rates", {position: "top-center"} );
        }

        // setLatePaymentNotificationLoad(true);
      });
    }
  }, [latePaymentNotificationLoad])

  const navigation = [
    { name: "Task List", href: "/", icon: ClipboardIcon },
    {
      name: "Prospects",
      href: "/prospective-contacts",
      icon: SearchCircleIcon,
    },
    { name: "Clients", href: "/contacts", icon: UserGroupIcon },
    { name: "Matters", href: "/matters", icon: BookOpenIcon },
    { name: "Settings", href: "/settings", icon: CogIcon, permission: ["god.permissions", "administrator.permissions"] },
    { name: "Postbox", href: "/postbox", target: "_blank", icon: MailIcon, badgeColor: emails.length > 0 && "bg-red-500" , badge: <>
     {emails.length > 0 ? emails.filter(e => (e.read !== true || e.completed !== true) && e.status !== "Cancelled").length : 0}
    </> },
    { name: "Notifications", href: "/notifications", icon: GiMailbox, badgeColor: notifications.length > 0 && "bg-red-500" , badge: <>
    {notifications.length > 0 ? notifications.filter(e => (e.read !== true )).length : 0}
   </> },
   { name: "Admin", href: "/admin", icon: OfficeBuildingIcon, permission: ["admin.view"] },
  ];

  return (
    <div className="h-screen flex bg-gray-150 dark:bg-gray-800 dark:text-gray-200">
      <SideBar
        navigation={navigation}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={() => setSidebarOpen(false)}
      />
      <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between bg-gray-50 border-b border-gray-200 px-4 py-1.5">
            <div
              className="tracking-widest text-gray-500"
              onClick={() => history.push("/")}
            >
              The Debt Bridge
            </div>
            <div>
              <button
                type="button"
                className="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
            <div className="absolute inset-0">
              <div className="h-full">
                <Toaster position="top right" />
                <IndexRoutes />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
export default withRouter(App);
