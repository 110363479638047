import axios          from "axios";
import Authentication from "./authentication";

class ApiRequest {
    _updateConfig(config){
        if(config === undefined){
            config = {};
        }
        if(config.headers === undefined){
            config.headers = {};
        }
        return {
            ...config,
            headers: {
                ...config.headers,
                'Authorization': "Bearer " + Authentication.getAccessToken()
            }
        }
    }

    get(url, config){
        return this.getRAW(url, config).then(res => res.data);
    }
    delete(url, config){
        return this.deleteRAW(url, config).then(res => res.data);
    }
    head(url, config){
        return this.headRAW(url, config).then(res => res.data);
    }
    options(url, config){
        return this.optionsRAW(url, config).then(res => res.data);
    }
    post(url, data, config){
        return this.postRAW(url, data, config).then(res => res.data);
    }
    put(url, data, config){
        return this.putRAW(url, data, config).then(res => res.data);
    }
    patch(url, data, config){
        return this.patchRAW(url, data, config).then(res => res.data);
    }

    getRAW(url, config){
        return new Promise((resolve, reject) => {
            axios.get(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    deleteRAW(url, config){
        return new Promise((resolve, reject) => {
            axios.delete(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    headRAW(url, config){
        return new Promise((resolve, reject) => {
            axios.head(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    optionsRAW(url, config){
        return new Promise((resolve, reject) => {
            axios.options(url, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    postRAW(url, data, config){
        return new Promise((resolve, reject) => {
            axios.post(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    putRAW(url, data, config){
        return new Promise((resolve, reject) => {
            axios.put(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
    patchRAW(url, data, config){
        return new Promise((resolve, reject) => {
            axios.patch(url, data, this._updateConfig(config)).then((res) => {
                resolve(res);
            }).catch((err) => {
                if(err.response !== undefined){
                    if(err.response.status === 401){
                        return Authentication.logout();
                    }
                }
                reject(err);
            });
        });
    }
}
export default new ApiRequest();