import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import API from "../../api";
import Loader from "../Loader";
import { CheckCircleIcon, PlusIcon, XCircleIcon } from "@heroicons/react/solid";

export default function Index({
  type = "",
  parentId = "",
  createTask = async () => {},
  handleClick = async () => {},
}) {
  const [checklist, setChecklist] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && parentId && type) {
      setMount(false);
      setLoading(true);

      API.settings
        .checklist(type)
        .runChecklist(parentId)
        .then((res) => setChecklist(res))
        .finally(() => setLoading(false));
    }
  }, [mount, type, parentId]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div className="p-6">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="border-b border-gray-200 pb-5 mb-2">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Checklist
            </h3>
          </div>

          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {checklist && checklist.length > 0 ? (
                checklist.map((item, index) => (
                  <li key={`checklist_${index}`}>
                    <a
                      className="block hover:bg-gray-50"
                      onClick={() => handleClick(item._id)}
                    >
                      <div className="px-4 py-2 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {item.details}{" "}
                          </p>
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            <span
                              className={classNames(
                                item.complete
                                  ? "bg-green-500"
                                  : item.exists === false
                                  ? "bg-gray-400 hover:bg-blue-500 cursor-pointer"
                                  : "bg-red-500",
                                // checklist.iconBackground,
                                " h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                              )}
                              onClick={() => {
                                !item.complete &&
                                  item.exists === false &&
                                  createTask(item._id).then(() => {
                                    setMount(true);
                                  });
                              }}
                            >
                              {item.complete ? (
                                <CheckCircleIcon
                                  className=" h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              ) : item.exists === false ? (
                                <PlusIcon
                                  className=" h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              ) : (
                                <XCircleIcon
                                  className=" h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <p>No Checklist</p>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

Index.propTypes = {
  type: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  createTask: PropTypes.func.isRequired,
};
