import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Segment from "../../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Input,
  Toggle,
} from "../../../../../components/Forms";
import API from "../../../../../api";
import CommonFunctions from "../../../../../CommonFunctions";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import ChainedContext from "../../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../../plugins/clio_auth/_components/authenticationReceiver";

function Add({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    isBillable: true,
  });

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.users
        .getAll()
        .then((res) => {
          setUserList(
            res.users.map((a) => {
              if(a.email == auth.user.email){
                setData({ ...data, assigned: a.id });
              }

              return {
                text: a.name,
                value: a._id,
              };
            })
          );
        })
        .catch((e) => CommonFunctions.handleError(e));

      setMountLoading(false);
    }
  }, [mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Create Task
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          <Group className="py-2">
            <TextArea
              label="Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </Group>
          <Group className="py-2">
            <Input
              type="number"
              incrementBy="6"
              label="Time"
              name="time"
              value={data.time}
              onChange={onChange}
            />
            <Dropdown
              label="Fee Earner"
              name="assigned"
              value={data.assigned}
              onChange={onChange}
              options={userList}
            />
            <DateSelect
              label="Due Date"
              name="due_date"
              value={data.due_date}
              onChange={onDateChange}
            />
          </Group>
          <Toggle
            className="my-2"
            label="Is Billable?"
            enabled={data.isBillable}
            onChange={() =>
              setData({
                ...data,
                isBillable: data.isBillable ? !data.isBillable : true,
              })
            }
          />
          <div className="pt-2 flex justify-between">
            <Button
              colour="red"
              label="Cancel"
              onClick={() =>
                history.push(
                  `/matters/${params.matter_id}/debtors/${params.debtor_id}/tasks/`
                )
              }
              icon={XIcon}
            />
            <Button
              colour="green"
              label="Create"
              onClick={() => {
                API.matters.debtors.tasks
                  .create(params.matter_id, params.debtor_id, data)
                  .then((res) =>
                    history.push(
                      `/matters/${params.matter_id}/debtors/${params.debtor_id}/tasks/${res}`
                    )
                  );
              }}
              icon={CheckIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Add), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ]
])