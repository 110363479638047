import React from "react";
import AddForm from "./forms/addForm";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";

const AddContact = ({ history }) => {
  const handleSubmit = (data) => {
    if (data.contact) {
      API.contacts.matters
        .create(data.contact, data)
        .then(() => {
          history.push("/contacts/" + data.contact + "/matters");
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => {});
    }
  };

  return (
    <div className="pb-8">
      <div
        className={`border-b border-secondary bg-white dark:bg-gray-900 shadow h-20`}
      >
        <div className="w-full rounded-md flex gap-4 items-center">
          <div className="p-4 text-2xl rounded-t-md">New Matter</div>
        </div>
      </div>
      <AddForm
        onCancel={() => history.push("/contacts")}
        onSubmit={handleSubmit}
      />
    </div>
  );
};
export default AddContact;
