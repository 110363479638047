import React, { useState, useEffect } from "react";
import { GiHouse } from "react-icons/gi";
import {
  UserIcon,
  OfficeBuildingIcon,
  ReceiptTaxIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import { Button } from "../../../components/Forms";
export default function ContactBar({ contact, history, showEdit }) {
  return (
    <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-primary">{contact.name}{contact.suffix ?  " " +contact.suffix : ""}</h1>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <UserIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {contact.type ? contact.type : "--"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <GiHouse
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {contact.addresses &&
              contact.addresses.find((item) => item.primary === true)
                ? `${
                    contact.addresses.find((item) => item.primary === true)
                      .postal_code
                  } (${
                    contact.addresses.find((item) => item.primary === true).name
                  })`
                : "N/A"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <PhoneIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {contact.phone_numbers &&
              contact.phone_numbers.find((item) => item.primary === true)
                ? `${
                    contact.phone_numbers.find((item) => item.primary === true)
                      .number
                  } (${
                    contact.phone_numbers.find((item) => item.primary === true).name
                  })`
                : "N/A"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <OfficeBuildingIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {contact.type === "Company"
                ? contact.companyRegistrationNumber
                : "N/A"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <ReceiptTaxIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {contact.type === "Company"
                ? contact.vatRegistrationNumber
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
      {showEdit && (
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <Button
            onClick={() => history.push(`/contacts/${contact._id}/edit`)}
            label="Edit Client"
            colour="blue"
          />
        </div>
      )}
    </div>
  );
}
