import dayjs from "dayjs";
import { useEffect, useState } from "react";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import Segment from "../../../components/Segment";
import { OfficeBuildingIcon, ClipboardIcon } from "@heroicons/react/solid";

export default function RecentMatters({
  parentId = "",
  handleClick = () => {},
}) {
  const [matters, setMatters] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount) {
      setMount(false);
      setLoading(true);

      API.contacts.matters
        .list(parentId)
        .then((res) => setMatters(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading, matters, parentId]);

  return (
    <div className="p-6">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="border-b border-gray-200 pb-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Active Matters
            </h3>
          </div>

          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {matters && matters.length > 0 ? (
                matters.sort((a, b) => a.display_number - b.display_number).map((item, index) => (
                  <li key={`matter_${index}`}>
                    <a
                      className="block hover:bg-gray-50 cursor-pointer"
                      onClick={() => handleClick(item._id)}
                    >
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="truncate text-sm font-medium text-indigo-600">
                            #{item.display_number !== undefined ? item.display_number : item.client_reference}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="ml-2 flex flex-shrink-0">
                            <p className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                              {item.practice_area && item.practice_area.name}
                            </p>
                            <p className="ml-2 inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                              Amount Due: £{item.total_due && CommonFunctions.toFormattedFixed(item.total_due)} | Fees: {item.fee_percent}% £{item.fee_total && CommonFunctions.toFormattedFixed(item.fee_total)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="ml-2 flex flex-shrink-0">
                            {item.debtors.map((debtor) => 
                              <p className="ml-2 inline-flex rounded-full bg-gray-300 px-2 text-xs font-semibold leading-5 text-gray-800">
                                {debtor}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                              <ClipboardIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {item.description}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                              <OfficeBuildingIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {item.location}
                            </p>
                          </div>
                          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                            {/*  
                          <CalendarIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />*/}
                            <p>
                              Opened on{" "}
                              {dayjs(item.open_date).format("DD/MM/YYYY HH:mm")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <p>No Active matters</p>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
