import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import API from "../../api";
import Segment from "../../components/Segment";
import CommonFunctions from "../../CommonFunctions";
import MatterReceiver from "../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import MatterBar from "./_components/MatterBar";
import MatterBreadcrumbs from "./_components/MatterBreadcrumbs";
import PaginateWrapper from "../../components/PaginationWrapper";
import { ThemeTable } from "../../components/Tables";
import { getList } from "../../components/widgets/editorShortcodes/shortcodes";
import dayjs from "dayjs";

function MatterShortcodes({ history, matter }) {
  const [loading, setLoading] = useState(true);
  const [mount, setMount] = useState(true); 
  const [shortcodes, setShortcodes] = useState([]);

  useEffect(async() => {
    if (mount) {
      console.log("🚀 ~ useEffect ~ matter:", matter)
      setMount(false);
      // setLoading(true);
      setShortcodes(
        await getList(true, 
          {
            matter, 
            defendants: matter.defendant1 ? [matter.defendant1] : [],
            claiments: matter.claiment1 ? [matter.claiment1] : [],
            courts: matter.court1 ? [matter.court1] : [],
            }
          )
        );
      
      setLoading(false);
    }
  }, [mount]);

  const getData = (pageNum, pageSize, search, filters) => {
    return API.customFields
      .listPaged({
        pageNum,
        pageSize,
        search,
        filters,
      })
      .catch((e) => CommonFunctions.handleError(e));
  };

  return (
    <>
    <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
        { name: `Shortcodes`, href: '#', current: true },
      ]} />
    
    <Segment>
      <MatterBar matter={matter} history={history} />

      <div className="md:grid md:grid-cols-1">
        <Segment>
          {loading ? (
            <Loader />
          ) : (
            <>
              <PaginateWrapper
                  hidePageSelect={true}
                  dataQuery={getData}
                  search={true}
                  // archived_toggle={{ title: "Archived", field: "archived" }}
                  render={(items) => {
                    return (
                      <ThemeTable
                        header={[
                          { text: "Shortcode", value: "shortcode" },
                          { text: "Value", value: "value" },
                        ]}
                        body={items.map((item) => {
                          let shortcode = shortcodes.filter(x => x.key === item.key);

                          const isoRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

                          const notGeneratedKeys = [
                            "Matter.Debts.ListInvoices",
                            "Matter.LBA.Due",
                            "Matter.Debts.TotalOutstanding",
                            "Matter.Debts.TotalInterest",
                            "Date",
                          ];

                          return {
                            shortcode: item.value,
                            value: notGeneratedKeys.includes(item.key) ? "! NOT GENERATED !" 
                              : 
                                (shortcode && shortcode[0] ? 
                                  (shortcode[0].value ?
                                     shortcode[0].value !== "null" &&
                                     shortcode[0].value !== "undefined" ?
                                      (isoRegex.test(shortcode[0].value) ? dayjs(shortcode[0].value).format("DD/MM/YYYY HH:mm") : shortcode[0].value) :
                                       "! NO DATA !" :
                                  "! NO DATA !") :
                                "! NO DATA !")
                          }})}
                        noDataMessage="No Shortcodes"
                      />
                    );
                  }}
                />
            </>
          )}
        </Segment>

      </div>
    </Segment>
    </>
    
  );
}
export default ChainedContext(MatterShortcodes, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
