import React from "react";
import ContactForm from "./forms/contactForm";
import API from "../../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../../CommonFunctions";
import ChainedContext from "../../../contexts/chainedContext";
import OrganisationReceiver from "../../../contexts/organisation_context/Organisation_Receiver";

const Edit = ({ org, history }) => {
  const handleSubmit = (data) => {
    API.organisations
      .update(org._id, data)
      .then(() => {
        toast.success("Organisation updated..");
        history.push("/admin/organisations/" + org._id);
        org._refresh();
      })
      .catch((e) => {
        console.log(e);
        CommonFunctions.handleError(e);
      });
  };

  return (
    <div className="pb-8">
      <ContactForm
        org={org}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ChainedContext(Edit, [
  [OrganisationReceiver, "org"],
]);

