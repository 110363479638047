import React from "react";
import Context from "../_context/authentication";

const joinProps = (a, b) => {
    return {
        ...a,
        ...b,
    };
};
const Receiver = (Compo, mapContext) => {
    return (props) => {
        return <Context.Consumer>{(context) => <Compo {...joinProps(props, mapContext(context))} />}</Context.Consumer>;
    };
};
export default Receiver;