import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default class Checklist {
  constructor(groupName) {
    this.groupName = groupName;
  }

  async getChecklistItems(activeOnly = false, org) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}${
        activeOnly ? "?enabled_only=1" : "?not_enabled_only=0"} ${org ? "&org="+org : ""}`
    );
  }
  async runChecklist(id) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}/check/${id}`
    );
  }
  async createChecklist(data) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}`,
      data
    );
  }
  async createChecklistTask(item_id, checklist_id) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}/${item_id}/${checklist_id}`,
      {}
    );
  }
  async updateChecklist(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}/${id}`,
      data
    );
  }
  async orderChecklist(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}/${id}/order`,
      data
    );
  }
  async removeChecklist(id) {
    return ApiRequest.delete(
      `${AppConfig.api_server}/api/settings/checklist/${this.groupName}/${id}`
    );
  }
}
