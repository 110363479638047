import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Segment from "../../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Toggle,
  Input,
} from "../../../../../components/Forms";
import API from "../../../../../api";
import CommonFunctions from "../../../../../CommonFunctions";
import Loader from "../../../../../components/Loader";
import { PencilIcon, XIcon } from "@heroicons/react/solid";

export default function Edit() {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    currentRate: 0,
    isBillable: true,
    defaultAdminRate: false,
  });
  const [defaultAdminRate, setDefaultAdminRate] = useState(0);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.matters.debtors.activities
          .get(params.matter_id, params.debtor_id, params.activity_id)
          .catch((e) => CommonFunctions.handleError(e)),
        API.settings.get().catch((e) => CommonFunctions.handleError(e)),
      ])
        .then((res) => {
          if (res[0]) {
            setUserList(
              res[0].users.map((a) => {
                return {
                  text: a.name,
                  value: a._id,
                  rate: a.rate,
                };
              })
            );
          }

          if (res[1]) {
            setData(res[1]);
          }

          if (res[2]) {
            setDefaultAdminRate(res[2].adminRate ? res[2].adminRate : 0);
          }
        })
        .finally(() => setMountLoading(false));
    }
  }, [params, mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Edit Activity
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <Group className="py-2">
                <TextArea
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                />
              </Group>
              <Group className="py-2">
                <Dropdown
                  label="Fee Earner"
                  name="assigned"
                  value={data.assigned}
                  onChange={(e) => {
                    const user = userList.find(
                      (item) => item.value === e.target.value
                    );

                    setData({
                      ...data,
                      [e.target.name]: e.target.value,
                      currentRate:
                        data.defaultAdminRate !== true
                          ? user
                            ? user.rate
                            : 0
                          : data.currentRate,
                    });
                  }}
                  options={userList}
                />
                <DateSelect
                  label="Due Date"
                  name="due_date"
                  value={data.due_date}
                  onChange={onDateChange}
                />
                <Input
                  type="number"
                  incrementBy="6"
                  label="Time"
                  name="time"
                  value={data.time}
                  onChange={onChange}
                />
                <Input
                  name="cost"
                  label="Cost (£)"
                  disabled
                  value={(
                    data.time * (data.currentRate ? data.currentRate / 60 : 0)
                  ).toFixed(2)}
                  onChange={onChange}
                />
              </Group>
              <Group>
                <Toggle
                  className="my-2"
                  label="Charge Default Admin Rate"
                  enabled={
                    data.defaultAdminRate ? data.defaultAdminRate : false
                  }
                  onChange={() => {
                    const user = userList.find(
                      (item) => item.value === data.assigned
                    );

                    setData({
                      ...data,
                      defaultAdminRate: data.defaultAdminRate
                        ? !data.defaultAdminRate
                        : true,
                      currentRate: (
                        data.defaultAdminRate !== undefined
                          ? !data.defaultAdminRate
                          : true
                      )
                        ? defaultAdminRate
                        : user
                        ? user.rate
                        : 0,
                    });
                  }}
                />
                <Toggle
                  className="my-2"
                  label="Is Billable?"
                  enabled={data.isBillable ? data.isBillable : false}
                  onChange={() =>
                    setData({
                      ...data,
                      isBillable: data.isBillable ? !data.isBillable : true,
                    })
                  }
                />
              </Group>

              <div className="pt-2 flex justify-between">
                <Button
                  colour="red"
                  label="Cancel"
                  onClick={() =>
                    history.push(
                      `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/${params.activity_id}`
                    )
                  }
                  icon={XIcon}
                />
                <Button
                  colour="green"
                  label="Update"
                  onClick={() => {
                    API.matters.debtors.activities
                      .update(
                        params.matter_id,
                        params.debtor_id,
                        params.activity_id,
                        data
                      )
                      .then(() =>
                        history.push(
                          `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/${params.activity_id}`
                        )
                      )
                      .catch((e) => CommonFunctions.handleError(e));
                  }}
                  icon={PencilIcon}
                />
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}
