import React from "react";
import { Switch, Route } from "react-router-dom";
import MatterCustomFields from "../../../../settings/custom_fields/matterFields/matterFields";
import AddTemplate from "../../../../settings/custom_fields/matterFields/add";
import CustomFieldsNavbar from "../contactFields/CustomFieldsNavbar"
import Groups from "../../../../settings/custom_fields/matterFields/groups";
import AddGroup from "../../../../settings/custom_fields/matterFields/group_add";

const routePrefix = "/admin/organisations/:org_id/matter_custom_fields";

export default function Index({history, match}) {
  return (
    <>
      <CustomFieldsNavbar history={history} match={{...match, url: `/admin/organisations/${match.params.org_id}`}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/groups/edit/:id`} component={AddGroup} />
        <Route exact path={`${routePrefix}/groups/add`} component={AddGroup} />
        <Route exact path={`${routePrefix}/groups`} component={Groups} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={MatterCustomFields} />
      </Switch>
    </>
  );
}
