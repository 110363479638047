import { Link, useLocation } from "react-router-dom";

export default function NoMatch() {
  let location = useLocation();

  return (
    <div className="p-4 text-center">
      <div className="text-4xl text-orange-300">404</div>
      <div className="p-4 text-center">
        Oh no! It appears you have tried to access something that doesn't exist.
      </div>
      <div>
        No match for <code>{location.pathname}</code>
      </div>
      <div className="p-4 text-center text-blue-400 underline">
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
}
