import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { PrinterIcon } from "@heroicons/react/outline";
import ChainedContext from "../../contexts/chainedContext";
import ContactReceiver from "../../contexts/contact_context/Contact_Receiver";
import Loader from "../../components/Loader";
import Segment from "../../components/Segment";
import DirectorsList from "./_components/directorsList";
import API from "../../api";
import { Receiver as ContextReceiver } from "./_context";
import CommonFunctions from "../../CommonFunctions";

const ReportView = ({ creditSafeContext, contact }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState({});

  const params = useParams();

  const getData = () => {
    creditSafeContext.getSingleReport(params.creditReportId).then((res) => {
      setReport(res);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, [creditSafeContext.getSingleReport, params.creditReportId]);

  const getScoreColour = (score) => {
    let colour = "bg-red-500 bg-opacity-60 text-red-900";
    if (score === "Negative") {
      return colour;
    }
    switch (true) {
      case score > 50:
        colour = "bg-green-500 bg-opacity-60 text-green-900";
        break;
      case score > 29 && score < 51:
        colour = "bg-yellow-500 bg-opacity-60 text-yellow-900";
        break;
      case score > 20 && score < 30:
        colour = "bg-orange-500 bg-opacity-60 text-orange-900";
        break;

      default:
        break;
    }
    return colour;
  };
  const getRatingColour = (score) => {
    let colour = "bg-red-500 bg-opacity-60 text-red-900";

    switch (true) {
      case score === "A":
        colour = "bg-green-500 bg-opacity-60 text-green-900";
        break;
      case score === "B":
        colour = "bg-green-500 bg-opacity-60 text-green-900";
        break;
      case score === "C":
        colour = "bg-yellow-500 bg-opacity-60 text-yellow-900";
        break;
      case score === "D":
        colour = "bg-orange-500 bg-opacity-60 text-orange-900";
        break;
      case score === "E":
        colour = "bg-red-500 bg-opacity-60 text-red-900";
        break;

      default:
        break;
    }
    return colour;
  };
  const updateContact = (field, value) => {
    API.contacts.update(contact._id, { [field]: value }).then(() => {
      contact._refresh();
      toast.success(`Added ${field} details`);
    });
  };
  const printReport = () => {
    toast.success(`Print report not plumbed in yet`);
  };

  if (isLoading) {
    return <Loader>Retrieving Report...</Loader>;
  }
  if (report.reportFormat === "json") {
    console.log("🚀 ~ ReportView ~ report:", report)
    return (
      <Segment>
        <div className={"text-2xl flex justify-between items-middle px-1 pb-2"}>
          <div>Creditsafe Key Point Report</div>

          <div className="flex gap-2 text-sm italic items-center">
            <button
              onClick={printReport}
              className="text-blue-600 hover:text-gray-600  rounded"
            >
              <PrinterIcon className="h-6 " />
            </button>
            Report Date: {dayjs(report.createdAt).format("DD-MM-YYYY HH:mm")}
          </div>
        </div>

        <div className="overflow-hidden rounded-xl border border-gray-300">
          <table className="w-full table-fixed ">
            <tbody className=" divide-y divide-gray-300">
              <tr>
                <th
                  className="p-2 text-left  bg-gray-200 text-primary "
                  colSpan={4}
                >
                  Company Summary
                </th>
              </tr>
              <tr>
                <th className="p-2 font-medium text-left bg-gray-200">Name</th>
                <td className="p-2  text-black bg-gray-100">
                  {report.reportJson.companySummary.businessName}
                </td>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Company Reg. #
                </th>
                <td className="p-2 text-lg flex gap-2  text-black bg-gray-100">
                  {report.reportJson.companySummary.companyRegistrationNumber}
                  {contact._id !== undefined &&
                    !contact.companyRegistrationNumber && (
                      <button
                        onClick={() =>
                          updateContact(
                            "companyRegistrationNumber",
                            report.reportJson.companySummary
                              .companyRegistrationNumber
                          )
                        }
                        className="px-2 py-1 text-sm bg-gray-200 border border-blue-300 text-blue-700 rounded hover:bg-gray-400 hover:text-white"
                      >
                        add
                      </button>
                    )}
                </td>
              </tr>
              <tr>
                <th className="p-2 text-left font-medium bg-gray-200">
                  Company Status
                </th>
                <td className="p-2 text-md   text-black bg-gray-100">
                  {report.reportJson.companySummary.companyStatus.status}
                </td>
                <th className="p-2 text-left font-medium bg-gray-200">
                  Company Status Desc.
                </th>
                <td className="p-2 text-md   text-black bg-gray-100">
                  {report.reportJson.companySummary.companyStatus.description}
                </td>
              </tr>
              <tr>
                <th className="p-2 text-left font-medium bg-gray-200">
                  Creditsafe Score
                </th>
                <td
                  className={`p-2 text-md   text-black bg-gray-100 ${
                    report.reportJson.companySummary.creditRating.commonValue
                      ? getRatingColour(
                          report.reportJson.companySummary.creditRating
                            .commonValue
                        )
                      : "bg-gray-100"
                  }`}
                >
                  {report.reportJson.companySummary.creditRating.commonValue}
                </td>
                <th className="p-2 text-left font-medium bg-gray-200">
                  Creditsafe Description
                </th>
                <td className="p-2 text-md   text-black bg-gray-100">
                  {
                    report.reportJson.companySummary.creditRating
                      .commonDescription
                  }
                </td>
              </tr>
              <tr>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Credit Limit
                </th>
                <td className="p-2   text-black bg-gray-100">
                  {report.reportJson.companySummary.creditRating.creditLimit
                      .value !== "No limit" && "£"}{
                    CommonFunctions.toFormattedFixed(report.reportJson.companySummary.creditRating.creditLimit
                      .value)
                  }{" "}
                  {/*  
                  {
                    report.reportJson.companySummary.creditRating.creditLimit
                      .currency
                  }*/}
                </td>
                <th className="p-2 font-medium text-left bg-gray-200">
                  VAT Number
                </th>
                <td className="p-2 text-lg flex gap-2 text-black bg-gray-100">
                  {
                    report.reportJson.companyIdentification.basicInformation
                      .vatRegistrationNumber
                  }
                  {contact._id !== undefined && !contact.vatRegistrationNumber && (
                    <button
                      onClick={() =>
                        updateContact(
                          "vatRegistrationNumber",
                          report.reportJson.companyIdentification
                            .basicInformation.vatRegistrationNumber
                        )
                      }
                      className="px-2 py-1 text-sm bg-gray-300 border border-blue-300 text-blue-700 rounded hover:bg-gray-400 hover:text-white"
                    >
                      add
                    </button>
                  )}
                </td>
              </tr>
              <tr>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Local Score
                </th>
                <td
                  className={`p-2   text-black ${
                    report.reportJson.companySummary.creditRating.providerValue
                      .value
                      ? getScoreColour(
                          report.reportJson.companySummary.creditRating
                            .providerValue.value
                        )
                      : "bg-gray-100"
                  }`}
                >
                  {
                    report.reportJson.companySummary.creditRating.providerValue
                      .value
                  }
                </td>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Local Description
                </th>
                <td className="p-2   text-black bg-gray-100">
                  {
                    report.reportJson.companySummary.creditRating
                      .providerDescription
                  }
                </td>
              </tr>
              <tr>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Date of Registration
                </th>
                <td className="p-2   text-black bg-gray-100">
                  {dayjs(
                    report.reportJson.companyIdentification.basicInformation
                      .companyRegistrationDate
                  ).format("DD-MM-YYYY")}
                </td>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Contact Telephone
                </th>
                <td className="p-2   text-black bg-gray-100">
                  {report.reportJson.contactInformation.mainAddress.telephone}
                </td>
              </tr>
              <tr>
                <th className="p-2 font-medium text-left bg-gray-200">
                  Contact Address
                </th>
                <td colSpan={3} className="p-2   text-black bg-gray-100">
                  {report.reportJson.contactInformation.mainAddress.simpleValue}
                  <span className="text-sm px-2 py-1 bg-gray-300 rounded-xl ml-4 shadow text-gray-700">
                    {report.reportJson.contactInformation.mainAddress.type}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={"w-full my-2"}>
          <div className="overflow-hidden rounded-xl border border-gray-300">
            <table className="w-full border border-gray-300">
              <thead className=" divide-y divide-gray-300">
                <tr>
                  <th
                    className="p-2 text-left  bg-gray-200 text-primary "
                    colSpan={4}
                  >
                    Company History
                  </th>
                </tr>
                <tr>
                  <th className="p-2 font-medium text-left bg-gray-200">
                    Date
                  </th>
                  <th className="p-2 font-medium text-left bg-gray-200">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody className=" divide-y divide-gray-300">
                {report.reportJson.additionalInformation.companyHistory &&
                  report.reportJson.additionalInformation.companyHistory
                    .slice(0, 5)
                    .map((h, i) => {
                      return (
                        <tr key={i}>
                          <td className="p-2   text-black bg-gray-100">
                            {dayjs(h.date).format("DD-MM-YY HH:mm")}
                          </td>
                          <td className="p-2   text-black bg-gray-100">
                            {h.description}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>

        <div className={"w-full mt-2"}>
          <div className={"py-2"}>
            <div className="overflow-hidden rounded-xl border border-gray-300">
              <table className="w-full table-fixed">
                <tbody className=" divide-y divide-gray-300">
                  <tr>
                    <th
                      className="p-2 text-left  bg-gray-200 text-primary "
                      colSpan={10}
                    >
                      Negative Information - CCJ Summary
                    </th>
                  </tr>
                  <tr>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Value
                    </th>
                    <td className="p-2  text-black bg-gray-100">
                      £{report.reportJson.negativeInformation.ccjSummary && CommonFunctions.toFormattedFixed(report.reportJson.negativeInformation.ccjSummary.valueOfExact)}{" "}
                      {/* {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.currency}{" "} */}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Exact Registered 
                    </th>
                    <td 
                    className={`p-2 text-md  text-black bg-gray-100 ${
                      report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.numberOfExact > 0
                        ? getRatingColour("E")
                        : "bg-gray-100"
                    }`}
                    colSpan={7}
                    >
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.exactRegistered}{" "}
                    </td>
                  </tr>
                  <tr>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Exact
                    </th>
                    <td className="p-2  text-black bg-gray-100">
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.numberOfExact}{" "}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Possible
                    </th>
                    <td className="p-2 text-lg   text-black bg-gray-100">
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.numberOfPossible}{" "}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Satisfied
                    </th>
                    <td className="p-2  text-black bg-gray-100">
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.numberOfSatisfied}{" "}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Writs
                    </th>
                    <td className="p-2 text-lg   text-black bg-gray-100">
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.numberOfWrits}{" "}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Possible Registered
                    </th>
                    <td className="p-2  text-black bg-gray-100">
                      {report.reportJson.negativeInformation.ccjSummary && report.reportJson.negativeInformation.ccjSummary.possibleRegistered}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        {report.reportJson.negativeInformation && report.reportJson.negativeInformation.countyCourtJudgements && report.reportJson.negativeInformation.countyCourtJudgements.registered && report.reportJson.negativeInformation.countyCourtJudgements.registered.exact && report.reportJson.negativeInformation.countyCourtJudgements.registered.exact.map((ccj, index) => (
          <div className={"w-full mt-2"}>
              <div className={"py-2"}>
                <div className="overflow-hidden rounded-xl border border-gray-300">
                  <table className="w-full table-fixed">
                    <tbody className=" divide-y divide-gray-300">
                      <tr>
                        <th
                          // className="p-2 text-left  bg-gray-200 text-primary "
                          className={`p-2 text-left text-primary bg-gray-200 ${getRatingColour("E")}`}
                          colSpan={6}
                        >
                          Negative Information - CCJ {index + 1}
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Case Number
                        </th>
                        <td className="p-2  text-black bg-gray-100">
                          {ccj.caseNumber}{" "}
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Amount
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                          £{CommonFunctions.toFormattedFixed(ccj.ccjAmount)} {
                          // ccj.currency
                          }
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Status
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                          {ccj.ccjStatus}
                        </td>
                      </tr>
                      <tr>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Date
                        </th>
                        <td className="p-2  text-black bg-gray-100">
                          {dayjs(ccj.ccjDate).format("DD-MM-YY HH:mm")}{" "}
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Court
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                          {ccj.court}
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Incoming Record Details
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                          {ccj.incomingRecordDetails}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        )) }

        {report.reportJson.negativeInformation.insolvencyEvents && report.reportJson.negativeInformation.insolvencyEvents.map((event, index) => (
          <div className={"w-full mt-2"}>
              <div className={"py-2"}>
                <div className="overflow-hidden rounded-xl border border-gray-300">
                  <table className="w-full table-fixed">
                    <tbody className=" divide-y divide-gray-300">
                      <tr>
                        <th
                          // className="p-2 text-left  bg-gray-200 text-primary "
                          className={`p-2 text-left text-primary bg-gray-200 ${getRatingColour("E")}`}
                          colSpan={6}
                        >
                          Negative Information - Insolvency Events {index + 1}
                        </th>
                      </tr>
                      <tr>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Event
                        </th>
                        <td className="p-2  text-black bg-gray-100">
                          {event.eventType.description}{" "}
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Gazette Page
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                          {event.gazettePage}
                        </td>
                      </tr>
                      <tr>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Date Appointed
                        </th>
                        <td className="p-2  text-black bg-gray-100">
                          {dayjs(event.dateAppointed).format("DD-MM-YY HH:mm")}{" "}
                        </td>
                        <th className="p-2 font-medium text-left bg-gray-200">
                          Gazette Entry Date
                        </th>
                        <td className="p-2 text-lg   text-black bg-gray-100">
                        {dayjs(event.gazetteEntryDate).format("DD-MM-YY HH:mm")}{" "}
                        </td>
                      </tr>
                      {event.practitionersDetails && event.practitionersDetails.map(p => (
                        <tr>
                          <th className="p-2 font-medium text-left bg-gray-200">
                            Practitioners Company
                          </th>
                          <td className="p-2  text-black bg-gray-100">
                            {p.companyName}
                          </td>
                          <th className="p-2 font-medium text-left bg-gray-200">
                            Practitioners Contact
                          </th>
                          <td className="p-2 text-lg   text-black bg-gray-100">
                            {p.name}
                          </td>
                          <th className="p-2 font-medium text-left bg-gray-200">
                            Practitioners Address
                          </th>
                          <td className="p-2 text-lg   text-black bg-gray-100">
                            {p.practitionerAddress.simpleValue}
                          </td>
                        </tr>
                      ))}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        )) }
  
        <div className={"w-full mt-2"}>
          <div className={"py-2"}>
            <div className="overflow-hidden rounded-xl border border-gray-300">
              <table className="w-full table-fixed">
                <tbody className=" divide-y divide-gray-300">
                  <tr>
                    <th
                      className="p-2 text-left  bg-gray-200 text-primary "
                      colSpan={4}
                    >
                      Share Capital Structure
                    </th>
                  </tr>
                  <tr>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Issued Share Capital
                    </th>
                    <td className="p-2  text-black bg-gray-100">
                      £{report.reportJson.shareCapitalStructure &&
                        CommonFunctions.toFormattedFixed(report.reportJson.shareCapitalStructure
                          .issuedShareCapital.value)}{" "}
                      {/*report.reportJson.shareCapitalStructure &&
                        report.reportJson.shareCapitalStructure
                        .issuedShareCapital.currency*/}
                    </td>
                    <th className="p-2 font-medium text-left bg-gray-200">
                      Number of Shares Issued
                    </th>
                    <td className="p-2 text-lg   text-black bg-gray-100">
                      {report.reportJson.shareCapitalStructure &&
                        report.reportJson.shareCapitalStructure
                          .numberOfSharesIssued}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={"w-full my-2"}>
          <div className="overflow-hidden rounded-xl border border-gray-300">
            <table className="w-full border border-gray-300">
              <thead className=" divide-y divide-gray-300">
                <tr>
                  <th
                    className="p-2 text-left  bg-gray-200 text-primary "
                    colSpan={5}
                  >
                    Shareholders
                  </th>
                </tr>
                <tr>
                  <th className="p-2 font-medium text-left bg-gray-200">
                    Shareholder
                  </th>
                  <th className="p-2 font-medium text-left bg-gray-200">
                    Share Type
                  </th>
                  <th className="p-2 font-medium text-left bg-gray-200">
                    Shareholder Type
                  </th>
                  <th className="p-2 font-medium text-center bg-gray-200">
                    # Shares Owned
                  </th>
                  <th className="p-2 font-medium text-center bg-gray-200">
                    % Shares Held
                  </th>
                </tr>
              </thead>
              <tbody className=" divide-y divide-gray-300">
                {report.reportJson.shareCapitalStructure &&
                  report.reportJson.shareCapitalStructure.shareHolders.map(
                    (s, i) => {
                      return (
                        <tr key={i}>
                          <td className="p-2   text-black bg-gray-100">
                            {s.name}
                          </td>
                          <td className="p-2   text-black bg-gray-100">
                            {s.shareType}
                          </td>
                          <td className="p-2   text-black bg-gray-100">
                            {s.shareholderType}
                          </td>
                          <td className="p-2  text-center text-black bg-gray-100">
                            {s.totalNumberOfSharesOwned}
                          </td>
                          <td className="p-2  text-center text-black bg-gray-100">
                            {s.percentSharesHeld.toFixed()}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={"w-full mt-2 p-2"}>
          <h1 className={"text-2xl"}>Directors [Current]</h1>
          <div className={"py-2"}>
            <DirectorsList
              directors={report.reportJson.directors.currentDirectors}
            />
          </div>
        </div>
      </Segment>
    );
  }

  return (
    <div className={"p-4"}>
      <div className={"p-2 bg-red-500 text-white"}>
        Unsupported Report Type, unable to display its information.
      </div>
    </div>
  );
};
export default ChainedContext(ReportView, [
  [ContactReceiver, "contact"],
  [ContextReceiver, "creditSafeContext"],
]);
