import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import Dash from "./dash";
import AddTemplate from "./add";

const routePrefix = "/settings/activity_categories";

export default function Index() {
  return (
    <>
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
