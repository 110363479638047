import React, { Fragment, useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { Button } from "../../../../components/Forms";
import { ArrowsExpandIcon, PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Segment from "../../../../components/Segment";
import { useParams } from "react-router-dom";

export default function MatterCustomFields({ history }) {
  const [listData, setListData] = useState([]);
  const [recipients, setRecipients] = useState([]);

  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filterName, setFilterName] = useState("all");
  const [viewAll, setViewAll] = useState(false);
  const [viewOptions, setViewOptions] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (mount) {
      setMount(false);
      setLoading(true);

      API.customFields
        .list(params.org_id)
        .then((res) => setListData(res.filter(e => e.type === "matter")))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));

        API.settings
        .customFieldGroups()
        .list(params.org_id)
        .then((res) => {
            setRecipients(res);
        })
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const renderTable = (header, body) => { 

    let other_body = body.filter(b => b.group_id == null || b.group_id == undefined);

    let theBody = body;
    let theRecipients = recipients;

    if(filterName !== "all"){
      theRecipients = theRecipients.filter(r => r._id == filterName);
    }
    
    return(
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-white">
          <tr>
            {header.map((header_item, i) => {
              return (
                <th
                  key={"th_" + i}
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {header_item.text}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {theRecipients.map((r) => {
            let filtered_body = body.filter(b => b.group_id == r._id);
            return (
            <Fragment key={r._id}>
              <tr>
                <th 
                  colSpan={header.length > 1 ? header.length - 1 : 0}
                  scope="colgroup"
                  className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                >{r.name} ({r.shortcode})</th>
              </tr>
              {filtered_body.length > 0 ? (
                filtered_body.map((body_item, body_index) => (
                  <tr key={body_index}>
                    {header.map((header_item, header_index) => (
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {body_item[header_item.value]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <td
                  colspan={header.length}
                  className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                >
                  No Data...
                </td>
              )}
            </Fragment>) 
          })}
          {(filterName == "all" || filterName == "other") && <>
            <tr>
              <th
                key={"other"}
                scope="colgroup"
                className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
              >
                Other
              </th>
            </tr>
            {other_body.length > 0 && (
                  other_body.map((body_item, body_index) => (
                    <tr key={body_index}>
                      {header.map((header_item, header_index) => (
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {body_item[header_item.value]}
                        </td>
                      ))}
                    </tr>
                  ))
              )}
          </>}          
        </tbody>
      </table>
    )
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <Segment>
        {loading ? (
          <Loader />
        ) : (

          <div className={`text-lg`}>
            <Button
              icon={PlusIcon}
              colour="green"
              label="Add"
              style={{ float: "right" }}
              onClick={() => {
                history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields/add` : "/settings/matter_custom_fields/add");
              }}
            />
            <Button
              icon={ArrowsExpandIcon}
              colour={viewOptions ? "amber" : "subtle"}
              label="View Options"
              style={{ float: "right", marginRight: "10px" }}
              onClick={() => setViewOptions(!viewOptions)}
            />
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">Matter Custom Fields</span>
            </h3>

            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                  {viewOptions && <>
                    View Options:
                    <div className="grid grid-cols-8 gap-4">

                    <div className="flex items-center">
                      <div className="flex h-6 items-center">
                        <input
                          id={`view_all`}
                          name={`view_all`}
                          type="checkbox"
                          className="ml-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                          checked={viewAll == true}
                          onChange={() => {

                            setViewAll(!viewAll)

                            setRecipients(recipients.map((r, index) => {
                              return {
                                ...r,
                                view: !viewAll
                              }
                            }));
                            // loading(false);

                          }}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor={`view_all`} className="font-medium text-gray-900">
                          All
                        </label>
                      </div>
                    </div>
                      

                      {recipients.map((r, index) => {
                        let name = (r.name).replace(/ /g, "_");
                        return <div className="flex items-center">
                          <div className="flex h-6 items-center" key={index}>
                            <input
                              id={`view_${name}`}
                              name={r.name}
                              type="checkbox"
                              className="ml-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                              checked={r.view == true}
                              onChange={() => {

                                let view = r.view == true ? false : true;

                                let newData = [].concat(recipients);
                                newData[index].view = view;

                                setRecipients(newData);
                                // loading(false);

                              }}
                            />
                          </div>
                          <div className="ml-3 text-sm leading-6">
                            <label htmlFor={`view_${name}`} className="font-medium text-gray-900">
                              {r.name}
                            </label>
                          </div>
                        </div>

                      })}

                      </div>
                  </>}                  
                <br/>
                <span className="isolate inline-flex rounded-md shadow-sm mb-2">
                  <button
                    type="button"
                    className={classNames("relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "all" && "bg-primary")}
                    onClick={() => setFilterName("all")}
                  >
                    View All
                  </button>
                  {recipients.filter(r => r.view == true).map((r) => {
                    return <button
                    key={r.name}
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == r._id && "bg-primary")}
                      onClick={() => setFilterName(r._id)}
                    >
                      {r.name} ({r.shortcode})
                    </button>
                  })}
                  <button
                    type="button"
                    className={classNames("relative inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:borderprimary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "Q-Z" && "bg-primary")}
                    onClick={() => setFilterName("other")}
                  >
                    Other
                  </button>
                  
                </span>
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

                    {renderTable(
                      [
                        { text: "Name", value: "name" },
                        { text: "Type", value: "fieldType" },
                        { text: "Shortcode", value: "shortcode" },
                        { text: "Description", value: "description" },
                        { text: "Group", value: "group" },
                        { text: "Actions", value: "action_btn" },
                      ],
                      listData.map((item) => ({
                        ...item,
                        shortcode: `{${item.shortcode}}`,
                        group: recipients.find(e => item.group && e._id === item.group._id) ? `${recipients.find(e => e._id === item.group._id).name} (${recipients.find(e => e._id === item.group._id).shortcode})` : "",
                        group_id: item.group && item.group._id,
                        action_btn: (
                          <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                            <Button
                              icon={PencilIcon}
                              colour="blue"
                              className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                              onClick={() => {
                                history.push(
                                  params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields/edit/${item._id}` :"/settings/matter_custom_fields/edit/" + item._id
                                );
                              }}
                            />
                            {/*  
                            <Button
                              icon={TrashIcon}
                              colour="red"
                              className="md:mr-auto md:mr-2"
                              onClick={() => {
                                setModalOpen(item._id);
                              }}
                            />*/}
                          </div>
                        ),
                      }))
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Segment>
    </>
  );
}
