import React from "react";
import CreditChecks from "../../../components/creditChecks";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../contexts/debtor_context/Debtor_Receiver";
import API from "../../../api";
import Segment from "../../../components/Segment";
import DebtorBar from "./_components/DebtorBar";
const CreditCheck = ({ matter, debtor }) => {
  const getAllReports = async () => {
    return API.matters.debtors.credit_checks.all(matter._id, debtor._id);
  };
  const getSingleReport = async (reportId) => {
    return API.matters.debtors.credit_checks.report(
      matter._id,
      debtor._id,
      reportId
    );
  };
  const requestReport = async (type, resultItem) => {
    return API.matters.debtors.credit_checks
      .newReport(matter._id, debtor._id, type, resultItem)
      .then(() => {
        // Do nothing
      });
  };
  return (
    <Segment>
      <DebtorBar debtor={debtor} contact={matter.contact} />
      <CreditChecks
        routePrefix={"/matters/:matter_id/debtors/:debtor_id"}
        getAllReports={getAllReports}
        requestReport={requestReport}
        getSingleReport={getSingleReport}
        defaultSearchName={debtor.debtor_name}
      />
    </Segment>
  );
};
export default ChainedContext(CreditCheck, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
