import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Segment from "../../../components/Segment";
import { Switch } from "@headlessui/react";
import RatesTable from "./_components/ratesTable";
import { ConfirmModal } from "../../../components/Modals";

export default function Add({ history }) {
  // const [listData, setListData] = useState([]);

  const params = useParams();

  const [data, setData] = useState({
    name: "",
    upfront_fee: "",
    upfront_fee_type: "",
  });
  // const [itemData, setItemData] = useState(initialState);

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [thresholds, setThresholds] = useState([]);

  const [archiveModal, setArchiveModal] = useState(false);

  const getData = () => {
    Promise.all([
      API.settings.fee_scales().threshold_get(params.id),
    ]).then(res => {
      setThresholds(res[0]);
    }).catch(err => {
      console.log("🚀 ~ file: add.js:34 ~ getData ~ err:", err)
    });
  };
    

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .fee_scales()
        .get(params.id)
        .then((res) =>
          setData({
            name: res.name,
            upfront_fee: res.upfront_fee,
            upfront_fee_type: res.upfront_fee_type,
            org_id: params.org_id ? params.org_id : undefined,
          })
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

    const onRateCreate = data => {
      return API.settings.fee_scales().threshold_create(params.id,data).finally(() => {
        getData();
      });
    };
    const onRateUpdate = (rateId, data) => {
      return API.settings.fee_scales().threshold_update(params.id,rateId, data).finally(() => {
        getData();
      });
    };
    const onRateDelete = rateId => {
      return API.settings.fee_scales().threshold_delete(params.id,rateId).then(() => {
        getData();
      })
    };

  return (
    <Segment>
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {editing ? "Save" : "Add"} Fee Set
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {editing ? "Save" : "Add"} Fee Set details for Global Fee Scale Sets
            </p>
          </div>

          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Name
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={data.name}
                  onChange={handleChange}
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="upfront_fee"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Upfront Fee (setup / LBA)
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  type="number"
                  name="upfront_fee"
                  id="name"
                  value={data.upfront_fee}
                  onChange={handleChange}
                  className="w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary sm:max-w-xs sm:text-sm"
                />
                <select
                    name="upfront_fee_type"
                    value={data.upfront_fee_type}
                    className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md bg-gray-200"
                    placeholder={"Select"}
                    onChange={handleChange}
                  >
                    <option value={"fixed"}>
                        £
                    </option>
                    <option value={"percent"}>
                        %
                    </option>
                  </select>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="icon"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Sliding Scale on Recovery:
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                {editing ? 
                <RatesTable rates={thresholds.sort((a, b) =>
                  a.threshold > b.threshold ? 1 : a.threshold < b.threshold ? -1 : 0
                )} onCreate={onRateCreate} onUpdate={onRateUpdate} onDelete={onRateDelete} /> : "Please Add the Fee Set First then return here to edit the Thresholds, this is due to using the Set ID"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            onClick={() => history.push("/settings/fee_scales")}
            type="button"
            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            Cancel
          </button>

          {editing && <>
                  <ConfirmModal
                    open={archiveModal}
                    handleClose={() => setArchiveModal(false)}
                    handleSubmit={() => {
                      API.settings
                          .fee_scales()
                          .edit(params.id, {archive: true})
                          .then((res) => history.push(params.org_id ? `/admin/organisations/${params.org_id}/fee_scales` : `/settings/fee_scales`))
                          .catch((e) => CommonFunctions.handleError(e));
                    }}
                    header="Archive Fee Set"
                    content={
                      "Are you sure you wish to archive this Fee Set?"
                    }
                  />

                  <button
                    type="submit"
                    onClick={() => setArchiveModal(true)}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Archive
                    </button>

                   
                  </>}

          <button
            type="submit"
            disabled={!data.name}
            onClick={() => {
              let sendData = {
                name: data.name,
                upfront_fee: data.upfront_fee,
                upfront_fee_type: data.upfront_fee_type,
                org_id: params.org_id,
              };

              if (editing) {
                API.settings
                  .fee_scales()
                  .edit(params.id, sendData)
                  .then((res) => history.push(editing ? (params.org_id ? `/admin/organisations/${params.org_id}/fee_scales` : `/settings/fee_scales`) : (params.org_id ? `/admin/organisations/${params.org_id}/fee_scales/edit/${res}` : `/settings/fee_scales/edit/${res}`)))
                  .catch((e) => CommonFunctions.handleError(e));
              } else {
                API.settings
                  .fee_scales()
                  .add(sendData)
                  .then((res) => history.push(params.org_id ? `/admin/organisations/${params.org_id}/fee_scales` :"/settings/fee_scales"))
                  .catch((e) => CommonFunctions.handleError(e));
              }
            }}
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {/* {editing ? "orange" : "green"}  */}
            {editing ? PencilIcon : PlusIcon}
            {editing ? "Save" : "Add"}
          </button>
        </div>
      </div>
    </Segment>
  );
}
