import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";
import base_rates from "./base_rates";
import area_tasks from "./area_tasks";
import Checklist from "./checklist";
import Services from "./services";
import EmailTemplates from "./emailTemplates";
import DocTemplates from "./docTemplates";
import DocTemplateTypes from "./docTemplateTypes";
import ActivityCategories from "./activityCategories";
import letterHeaders from "./letterHeaders";
import FeeScales from "./fee_scales";
import CustomFieldGroup from "./customFieldsGroups";
import background_tasks from "./backgroundTasks";

const SettingsAPI = {
  get: () => ApiRequest.get(`${AppConfig.api_server}/api/settings`),
  practice_areas: () => ApiRequest.get(`${AppConfig.api_server}/api/settings/practice_areas`),
  syncPracticeAreas: () => ApiRequest.patch(`${AppConfig.api_server}/api/settings/practice_areas`),
  uploadFile: (data) =>
        ApiRequest.post(`${AppConfig.api_server}/api/settings/upload_file`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
  getFileLink: (id) =>
      ApiRequest.get(AppConfig.api_server + `/api/settings/getFileLink/${id}`),
  edit: (data) =>
    ApiRequest.patch(`${AppConfig.api_server}/api/settings`, data),
  email_templates: () => new EmailTemplates(),
  doc_templates: () => new DocTemplates(),
  doc_types: () => new DocTemplateTypes(),
  customFieldGroups: () => new CustomFieldGroup(),
  letter_headers: () => new letterHeaders(),
  activity_categories: () => new ActivityCategories(),
  fee_scales: () => new FeeScales(),
  checklist: (groupName = "") => {
    const whitelistGroups = [
      "prospective_contact",
      "contact",
      "matter",
      "debtor",
    ];

    if (whitelistGroups.indexOf(groupName) === -1) {
      throw new Error(
        "Group name must be one of the following: " + whitelistGroups.join(", ")
      );
    }

    return new Checklist(groupName);
  },
  services: () => new Services(),
  base_rates,
  area_tasks,
  background_tasks,
  payment_types: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/payment_types${org ? `?org_id=${org}` : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/payment_types`, data),
    update: (rateId, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/payment_types/${rateId}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/payment_types/${rateId}`)
  },
  salutations: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/salutations${org ? `?org_id=${org}` : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/salutations`, data),
    update: (rateId, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/salutations/${rateId}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/salutations/${rateId}`)
  },
  courts: {
    get: (org) => ApiRequest.get(`${AppConfig.api_server}/api/settings/courts${org ? `?org_id=${org}` : ""}`),
    create: data => ApiRequest.post(`${AppConfig.api_server}/api/settings/courts`, data),
    update: (rateId, data) => ApiRequest.patch(`${AppConfig.api_server}/api/settings/courts/${rateId}`, data),
    delete: rateId => ApiRequest.delete(`${AppConfig.api_server}/api/settings/courts/${rateId}`)
  },
};

export default SettingsAPI;
