import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import api from "../../../api";

// ORGS
import Dash from "./list";
import Add from "./add";
import Edit from "./edit";
import View from "./view";
import Organisation_Provider from "../../../contexts/organisation_context/Organisation_Provider";

// VIEW ORG
import Navbar from "./_components/HeaderAndNavbar";

import Checklists from "./checklists";
import ServiceManagement from "./services/serviceManagement";
import ActivityCategories from "../../settings/activityCategories/dash";
import ActivityCategoriesAdd from "../../settings/activityCategories/add";
import Users from "../../settings/users";
import ClientCustomFields from "./custom_fields/contactFields";
import MatterCustomFields from "./custom_fields/matterFields";
import BaseRates from "./base_rates";
import FeeScales from "./fee_scales";
import PaymentTypes from "./payment_types";
import Salutations from "./salutations";
import Courts from "./courts";
import AreaTasks from "./area_tasks";
import EmailTemplates from "./emailTemplates";
import DocTemplates from "./documentTemplates";
import DocTypes from "./documentTypes";	
import LetterHeaders from "./letterHeaders"

const routePrefix = "/admin/organisations";

export default function Index() {
  return (
    <div>
      <Switch>
        {/* <Route exact path={`${routePrefix}/add`} component={AddContact} /> */}
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route exact path={`${routePrefix}/add`} component={Add} />
        <Route path={`${routePrefix}/:org_id`} component={ViewRoutes} />  
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

function ViewRoutes({ history, match }) {
  return (
    <div>
      <Organisation_Provider api={api}>
        <Navbar history={history} match={match} />
        <Switch>
          <Route
            exact
            path={`${routePrefix}/:org_id`}
            component={View}
          />
          <Route
            exact
            path={`${routePrefix}/:org_id/edit`}
            component={Edit}
          />
          <Route
            path={`${routePrefix}/:org_id/checklists`}
            component={Checklists}
          />
          <Route
            path={`${routePrefix}/:org_id/client_custom_fields`}
            component={ClientCustomFields}
          />
          <Route
            path={`${routePrefix}/:org_id/matter_custom_fields`}
            component={MatterCustomFields}
          />
          <Route
            path={`${routePrefix}/:org_id/services`}
            component={ServiceManagement}
          />
          <Route
            path={`${routePrefix}/:org_id/activity_categories/edit/:id`}
            component={ActivityCategoriesAdd}
          />
          <Route
            path={`${routePrefix}/:org_id/activity_categories/add`}
            component={ActivityCategoriesAdd}
          />
          <Route
            path={`${routePrefix}/:org_id/activity_categories`}
            component={ActivityCategories}
          />
          <Route
            path={`${routePrefix}/:org_id/users`}
            component={Users}
          />
          <Route
            path={`${routePrefix}/:org_id/base_rates`}
            component={BaseRates}
          />
          <Route
            path={`${routePrefix}/:org_id/fee_scales`}
            component={FeeScales}
          />
          <Route
            path={`${routePrefix}/:org_id/payment_types`}
            component={PaymentTypes}
          />
          <Route
            path={`${routePrefix}/:org_id/salutations`}
            component={Salutations}
          />
          <Route
            path={`${routePrefix}/:org_id/courts`}
            component={Courts}
          />
          <Route
            path={`${routePrefix}/:org_id/area_tasks`}
            component={AreaTasks}
          />
          <Route
            path={`${routePrefix}/:org_id/email_templates`}
            component={EmailTemplates}
          />
          <Route
            path={`${routePrefix}/:org_id/doc_templates`}
            component={DocTemplates}
          />
          <Route
            path={`${routePrefix}/:org_id/doc_types`}
            component={DocTypes}
          />
          <Route
            path={`${routePrefix}/:org_id/letter_header`}
            component={LetterHeaders}
          />
          <Route path="*" component={NotFound} />
        </Switch>
      </Organisation_Provider>
    </div>
  );
}