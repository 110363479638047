import API from "../../../api";
import dayjs from "dayjs";

const getList = async(get = false, data = {}) => {

    let custom = await API.customFields.list();

    if(!get){
        custom.filter(field => field.archived !== true);
    }

    let customList = custom.map(c => {
        let list = {
            key: `${c.type}.CustomField.${c.shortcode}`,
            text: `${c.type}.CustomField.${c.shortcode}`,
        }

        if(c.type === 'matter'){
            list.value = get ? data.matter && data.matter.custom_fields ? data.matter.custom_fields[c._id] : `{${c.type}.CustomField.${c.shortcode}}` : `{${c.type}.CustomField.${c.shortcode}}`;
        }else if(c.type === 'client'){
            list.value = get ? data.matter.contact && data.matter.contact.custom_fields ? data.matter.contact.custom_fields[c._id] : `{${c.type}.CustomField.${c.shortcode}}` : `{${c.type}.CustomField.${c.shortcode}}`;
        }

        return list;
    });

    let debtorsList = data.defendants ? data.defendants.map((d, i) => {

        let index = i + 1;

        let primary_email = d.email_addresses ? d.email_addresses.filter(e => e.primary == true) : [];
        let email = primary_email.length > 0 ? primary_email[0].address : (d.email_addresses ? d.email_addresses.length > 0 ? d.email_addresses[0].address : "" : "");

        let primary_tel = d.phone_numbers ? d.phone_numbers.filter(e => e.primary == true) : [];
        let tel = primary_tel.length > 0 ? primary_tel[0].number : (d.phone_numbers ? d.phone_numbers.length > 0 ? d.phone_numbers[0].number : "" : "");

        let primary_address = d.addresses ? d.addresses.filter(a => a.primary == true) : [];
        if(!primary_address.length > 0 && d.addresses && d.addresses.length > 0) {
            primary_address = d.addresses[0];
        }

        let street = "";
        let country = "";
        let city = "";
        let county = "";
        let postcode = "";
        if(primary_address.length > 0){
            street = primary_address[0].street;
            country = primary_address[0].country;
            city = primary_address[0].city;
            county = primary_address[0].province;
            postcode = primary_address[0].postal_code;
        }
  
        return [
          {key: `Matter.Defendant${index}.Salutation`, text: `Matter.Defendant${index}.Salutation`, value: get ? (`${d.title}`) : `{Matter.Defendant${index}.Salutation}`},
          {key: `Matter.Defendant${index}.CompanyName`, text: `Matter.Defendant${index}.CompanyName`, value: get ? (`${d.name}`) : `{Matter.Defendant${index}.CompanyName}`},
          {key: `Matter.Defendant${index}.Forename`, text: `Matter.Defendant${index}.Forename`, value: get ? (`${d.first_name} ${d.middle_name ? d.middle_name : ""}`) : `{Matter.Defendant${index}.Forename}`},
          {key: `Matter.Defendant${index}.Surname`, text: `Matter.Defendant${index}.Surname`, value: get ? (`${d.last_name}`) : `{Matter.Defendant${index}.Surname}`},
          {key: `Matter.Defendant${index}.Email`, text: `Matter.Defendant${index}.Email`, value: get ? (`${email}`) : `{Matter.Defendant${index}.Email}`},
          {key: `Matter.Defendant${index}.Telephone`, text: `Matter.Defendant${index}.Telephone`, value: get ? (`${tel}`) : `{Matter.Defendant${index}.Telephone}`},
  
          {key: `Matter.Defendant${index}.AddressLine1`, text: `Matter.Defendant${index}.AddressLine1`, value: get ? (`${street}`) : `{Matter.Defendant${index}.AddressLine1}`},
          {key: `Matter.Defendant${index}.AddressLine2`, text: `Matter.Defendant${index}.AddressLine2`, value: get ? (`${city}`) : `{Matter.Defendant${index}.AddressLine2}`},
          {key: `Matter.Defendant${index}.AddressLine3`, text: `Matter.Defendant${index}.AddressLine3`, value: get ? (`${county}`) : `{Matter.Defendant${index}.AddressLine3}`},
          {key: `Matter.Defendant${index}.AddressLine4`, text: `Matter.Defendant${index}.AddressLine4`, value: get ? (`${country}`) : `{Matter.Defendant${index}.AddressLine4}`},
          {key: `Matter.Defendant${index}.Postcode`, text: `Matter.Defendant${index}.Postcode`, value: get ? (`${postcode}`) : `{Matter.Defendant${index}.Postcode}`},
        ]
  
      }) : [];

    let courtsList = data.courts ? data.courts.map((d, i) => {

        let index = i + 1;

        let primary_email = d.email_addresses ? d.email_addresses.filter(e => e.primary == true) : [];
        let email = primary_email.length > 0 ? primary_email[0].address : (d.email_addresses ? d.email_addresses.length > 0 ? d.email_addresses[0].address : "" : "");

        let primary_tel = d.phone_numbers ? d.phone_numbers.filter(e => e.primary == true) : [];
        let tel = primary_tel.length > 0 ? primary_tel[0].number : (d.phone_numbers ? d.phone_numbers.length > 0 ? d.phone_numbers[0].number : "" : "");

        let primary_address = d.addresses ? d.addresses.filter(a => a.primary == true) : [];
        if(!primary_address.length > 0 && d.addresses && d.addresses.length > 0) {
            primary_address = d.addresses[0];
        }

        let street = "";
        let country = "";
        let city = "";
        let county = "";
        let postcode = "";
        if(primary_address.length > 0){
            street = primary_address[0].street;
            country = primary_address[0].country;
            city = primary_address[0].city;
            county = primary_address[0].province;
            postcode = primary_address[0].postal_code;
        }

        let KEY = 'Matter.Court';
  
        return [
        //   {key: `${KEY}${index}.Title`, text: `${KEY}${index}.Title`, value: get ? (`${d.title}`) : `{${KEY}${index}.Title}`},
          {key: `${KEY}${index}.CompanyName`, text: `${KEY}${index}.CompanyName`, value: get ? (`${d.name}`) : `{${KEY}${index}.CompanyName}`},
        //   {key: `${KEY}${index}.Forename`, text: `${KEY}${index}.Forename`, value: get ? (`${d.first_name} ${d.middle_name ? d.middle_name : ""}`) : `{${KEY}${index}.Forename}`},
        //   {key: `${KEY}${index}.Surname`, text: `${KEY}${index}.Surname`, value: get ? (`${d.last_name}`) : `{${KEY}${index}.Surname}`},
          {key: `${KEY}${index}.Email`, text: `${KEY}${index}.Email`, value: get ? (`${email}`) : `{${KEY}${index}.Email}`},
          {key: `${KEY}${index}.Telephone`, text: `${KEY}${index}.Telephone`, value: get ? (`${tel}`) : `{${KEY}${index}.Telephone}`},
  
          {key: `${KEY}${index}.AddressLine1`, text: `${KEY}${index}.AddressLine1`, value: get ? (`${street}`) : `{${KEY}${index}.AddressLine1}`},
          {key: `${KEY}${index}.AddressLine2`, text: `${KEY}${index}.AddressLine2`, value: get ? (`${city}`) : `{${KEY}${index}.AddressLine2}`},
          {key: `${KEY}${index}.AddressLine3`, text: `${KEY}${index}.AddressLine3`, value: get ? (`${county}`) : `{${KEY}${index}.AddressLine3}`},
          {key: `${KEY}${index}.AddressLine4`, text: `${KEY}${index}.AddressLine4`, value: get ? (`${country}`) : `{${KEY}${index}.AddressLine4}`},
          {key: `${KEY}${index}.Postcode`, text: `${KEY}${index}.Postcode`, value: get ? (`${postcode}`) : `${KEY}${index}.Postcode}`},
        ]
  
      }) : [];

    let claimentList = data.claiments ? data.claiments.map((d, i) => {

        let index = i + 1;

        let primary_email = d.email_addresses ? d.email_addresses.filter(e => e.primary == true) : [];
        let email = primary_email.length > 0 ? primary_email[0].address : (d.email_addresses ? d.email_addresses.length > 0 ? d.email_addresses[0].address : "" : "");

        let primary_tel = d.phone_numbers ? d.phone_numbers.filter(e => e.primary == true) : [];
        let tel = primary_tel.length > 0 ? primary_tel[0].number : (d.phone_numbers ? d.phone_numbers.length > 0 ? d.phone_numbers[0].number : "" : "");

        let primary_address = d.addresses ? d.addresses.filter(a => a.primary == true) : [];
        if(!primary_address.length > 0 && d.addresses && d.addresses.length > 0) {
            primary_address = d.addresses[0];
        }

        let street = "";
        let country = "";
        let city = "";
        let county = "";
        let postcode = "";
        if(primary_address.length > 0){
            street = primary_address[0].street;
            country = primary_address[0].country;
            city = primary_address[0].city;
            county = primary_address[0].province;
            postcode = primary_address[0].postal_code;
        }

        let KEY = 'Matter.Claiment';
  
        return [
          {key: `${KEY}${index}.Salutation`, text: `${KEY}${index}.Salutation`, value: get ? (`${d.title}`) : `{${KEY}${index}.Salutation}`},
          {key: `${KEY}${index}.CompanyName`, text: `${KEY}${index}.CompanyName`, value: get ? (`${d.name}`) : `{${KEY}${index}.CompanyName}`},
          {key: `${KEY}${index}.Forename`, text: `${KEY}${index}.Forename`, value: get ? (`${d.first_name} ${d.middle_name ? d.middle_name : ""}`) : `{${KEY}${index}.Forename}`},
          {key: `${KEY}${index}.Surname`, text: `${KEY}${index}.Surname`, value: get ? (`${d.last_name}`) : `{${KEY}${index}.Surname}`},
          {key: `${KEY}${index}.Email`, text: `${KEY}${index}.Email`, value: get ? (`${email}`) : `{${KEY}${index}.Email}`},
          {key: `${KEY}${index}.Telephone`, text: `${KEY}${index}.Telephone`, value: get ? (`${tel}`) : `{${KEY}${index}.Telephone}`},
  
          {key: `${KEY}${index}.AddressLine1`, text: `${KEY}${index}.AddressLine1`, value: get ? (`${street}`) : `{${KEY}${index}.AddressLine1}`},
          {key: `${KEY}${index}.AddressLine2`, text: `${KEY}${index}.AddressLine2`, value: get ? (`${city}`) : `{${KEY}${index}.AddressLine2}`},
          {key: `${KEY}${index}.AddressLine3`, text: `${KEY}${index}.AddressLine3`, value: get ? (`${county}`) : `{${KEY}${index}.AddressLine3}`},
          {key: `${KEY}${index}.AddressLine4`, text: `${KEY}${index}.AddressLine4`, value: get ? (`${country}`) : `{${KEY}${index}.AddressLine4}`},
          {key: `${KEY}${index}.Postcode`, text: `${KEY}${index}.Postcode`, value: get ? (`${postcode}`) : `${KEY}${index}.Postcode}`},
        ]
  
      }) : [];


      /*
    let ListInvoices = "<table><thead><tr><th>Date</th><th>Details</th><th>Debt</th><th>Compensation</th><th>Interest</th><th>Total</th></thead>";
        ListInvoices += "<tbody><tr><td colspan='6' style='text-align: center;'>Example of Table, This will be generated</td></tbody>";
        ListInvoices += "<th colspan='4'>&nbsp;</th><th>Total</th><th>£---</th></tfoot>";
        ListInvoices += "</table>"; */

    let list = [
         //Matter
         {key: "Matter.Description", value: get ? (data.matter && `${data.matter.description}`) : "{Matter.Description}", text: "Matter.Description"},
         {key: "Matter.ClientReference", value: get ? (data.matter && `${data.matter.client_reference}`) : "{Matter.ClientReference}", text: "Matter.ClientReference"},
         {key: "Matter.DisplayNumber", value: get ? (data.matter && `${data.matter.display_number}`) : "{Matter.DisplayNumber}", text: "Matter.DisplayNumber"},
         {key: "Matter.Location", value: get ? (data.matter && `${data.matter.location}`) : "{Matter.Location}", text: "Matter.Location"},
         {key: "Matter.ClioID", value: get ? (data.matter && `${data.matter._clioId}`) : "{Matter.ClioID}", text: "Matter.ClioID"},
         {key: "Matter.Status", value: get ? (data.matter && `${data.matter.status}`) : "{Matter.Status}", text: "Matter.Status"},
         {key: "Matter.BillingMethod", value: get ? (data.matter && `${data.matter.billing_method}`) : "{Matter.BillingMethod}", text: "Matter.BillingMethod"},
         {key: "Matter.OpenDate", value: get ? (data.matter && `${data.matter.open_date}`) : "{Matter.OpenDate}", text: "Matter.OpenDate"},
         {key: "Matter.CloseDate", value: get ? (data.matter && `${data.matter.close_date}`) : "{Matter.CloseDate}", text: "Matter.CloseDate"},
         {key: "Matter.PendingDate", value: get ? (data.matter && `${data.matter.pending_date}`) : "{Matter.PendingDate}", text: "Matter.PendingDate"},
         {key: "Matter.InterestRate", value: get ? (data.matter && (data.matter.interest_rate ? `${data.matter.interest_rate}` : "[NO RATE ADDED]")) : "{Matter.InterestRate}", text: "Matter.InterestRate"},
         {key: "Matter.PaymentTerms", value: get ? (data.matter && `${data.matter.paymentTerms}`) : "{Matter.PaymentTerms}", text: "Matter.PaymentTerms"},
         {key: "Matter.UpfrontFee", value: get ? (data.matter && `${data.matter.upfront_fee}`) : "{Matter.UpfrontFee}", text: "Matter.UpfrontFee"},
         {key: "Matter.PracticeArea", value: get ? (data.matter && `${data.matter.practice_area.length > 0 ? data.matter.practice_area[0].name : ""}`) : "{Matter.PracticeArea}", text: "Matter.PracticeArea"},
         
         {key: "Matter.InterestAct", value: get ? (data.matter && `${data.matter.interest_type}`) : "{Matter.InterestAct}", text: "Matter.InterestAct"},

         {key: "Matter.LBA.Fee", value: get ? (data.matter && data.matter.contact && `${data.matter.contact.settings.upfront_fee_type == "fixed" ? "£" : ""}${data.matter.contact.settings.upfront_fee}${data.matter.contact.settings.upfront_fee_type == "percent" ? "%" : ""}`) : "{Matter.UpfrontFee}", text: "Matter.LBA.Fee"},
         {key: "Matter.LBA.Due", value: get ? data.LBADue : "{Matter.LBA.Due}", text: "Matter.LBA.Due"},

         //Matter.Debtors
 
         {key: "Matter.Debts.TotalOutstanding", value: "{Matter.Debts.TotalOutstanding}", text: "Matter.Debts.TotalOutstanding"},
         {key: "Matter.Debts.ListInvoices", value: get ? "{Matter.Debts.ListInvoices}" : "{Matter.Debts.ListInvoices}", text: "Matter.Debts.ListInvoices"},
         {key: "Matter.Debts.TotalInterest", value:"{Matter.Debts.TotalInterest}", text: "Matter.Debts.TotalInterest"},

         //Matter Contact
         {key: "Matter.Contact.Salutation", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.prefix}` : "") : "{Matter.Contact.Salutation}", text: "Matter.Contact.Salutation"},
         {key: "Matter.Contact.Name", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.name} ${data.matter.contact.suffix ? data.matter.contact.suffix : ""}` : "") : "{Matter.Contact.Name}", text: "Matter.Contact.Name"},
         {key: "Matter.Contact.Type", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.type}` : "") : "{Matter.Contact.Type}", text: "Matter.Contact.Type"},
         {key: "Matter.Contact.vatNumber", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.vatRegistrationNumber}` : "") : "{Matter.Contact.vatNumber}", text: "Matter.Contact.vatNumber"},
         {key: "Matter.Contact.BankAccount", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.bank_account}` : "") : "{Matter.Contact.BankAccount}", text: "Matter.Contact.BankAccount"},
         {key: "Matter.Contact.BankSortCode", value: get ? (data.matter && data.matter.contact ? `${data.matter.contact.bank_sort}` : "") : "{Matter.Contact.BankSortCode}", text: "Matter.Contact.BankSortCode"},
         {key: "Matter.Contact.EmailAddresses", 
         value: get ? (data.matter && data.matter.contact && data.matter.contact.email_addresses ? `${data.matter.contact.email_addresses.map(e => e.address).join(", ")}` : "") : "{Matter.Contact.EmailAddresses}", text: "Matter.Contact.EmailAddresses"},
         {key: "Matter.Contact.Websites", value: get ? (data.matter && data.matter.contact && data.matter.contact.web_sites ? `${data.matter.contact.web_sites.map(e => e.address).join(", ")}` : "") : "{Matter.Contact.Websites}", text: "Matter.Contact.Websites"},
         {key: "Matter.Contact.PhoneNumbers", value: get ? (data.matter && data.matter.contact && data.matter.contact.phone_numbers ? `${data.matter.contact.phone_numbers.map(e => e.address).join(", ")}` : "") : "{Matter.Contact.PhoneNumbers}", text: "Matter.Contact.PhoneNumbers"},
         {key: "Matter.Contact.AddressLine1", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].name}` : "No Address") : "") : "{Matter.Contact.AddressLine1}", text: "Matter.Contact.AddressLine1"},
         {key: "Matter.Contact.AddressLine2", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].street}` : "No Address") : "") : "{Matter.Contact.AddressLine2}", text: "Matter.Contact.AddressLine2"},
         {key: "Matter.Contact.AddressLine3", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].city}` : "No Address") : "") : "{Matter.Contact.AddressLine3}", text: "Matter.Contact.AddressLine3"},
         {key: "Matter.Contact.AddressLine4", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].province}` : "No Address") : "") : "{Matter.Contact.AddressLine4}", text: "Matter.Contact.AddressLine4"},
         {key: "Matter.Contact.AddressLine5", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].postal_code}` : "No Address") : "") : "{Matter.Contact.AddressLine5}", text: "Matter.Contact.AddressLine5"},
         {key: "Matter.Contact.AddressLine6", value: get ? (data.matter && data.matter.contact ? (data.matter.contact.addresses.length > 0  ? `${data.matter.contact.addresses[0].country}` : "No Address") : "") : "{Matter.Contact.AddressLine6}", text: "Matter.Contact.AddressLine6"},
     
         //Extra
         {key: "Postbox.Signature", value: get ? (data.postbox && data.postbox.actionedBy && `${data.postbox.actionedBy.signature}`) : "{Postbox.Signature}", text: "Postbox.Signature"},
         {key: "System.Date", value: get ? (`${dayjs().format("DD MMMM YYYY")}`) : "{System.Date}", text: "System.Date"},
         {key: "Date", value: get ? (data.date_verbose ? `${dayjs(data.date_verbose).format("DD MMMM YYYY")}` : '{Date}') : "{Date}", text: "Date"},
         {key: "Postbox.Total_Debt_&_Interest", value: get ? data.totalOwed : "{Postbox.Total_Debt_&_Interest}", text: "Postbox.Total_Debt_&_Interest"},

         //Court
         {key: "Task.Court.Name", value: get ? (data.task && data.task.court && data.task.court.name ? `${data.task.court.name}` : "") : "{Task.Court.Name}", text: "Task.Court.Name"},
         {key: "Task.Court.Reference", value: get ? (data.task && data.task.court && data.task.court.reference ? `${data.task.court.reference}` : "") : "{Task.Court.Reference}", text: "Task.Court.Reference"},
         {key: "Task.Court.Address", value: get ? (data.task && data.task.court && data.task.court.address ? `${data.task.court.address}` : "") : "{Task.Court.Address}", text: "Task.Court.Address"},
         {key: "Task.Court.Contact", value: get ? (data.task && data.task.court && data.task.court.contact ? `${data.task.court.contact}` : "") : "{Task.Court.Contact}", text: "Task.Court.Contact"},
         {key: "Task.Court.Phone", value: get ? (data.task && data.task.court && data.task.court.phone ? `${data.task.court.phone}` : "") : "{Task.Court.Phone}", text: "Task.Court.Phone"},
         {key: "Task.Court.Email", value: get ? (data.task && data.task.court && data.task.court.email ? `${data.task.court.email}` : "") : "{Task.Court.Email}", text: "Task.Court.Email"},
         {key: "Task.Court.Claim Number", value: get ? (data.task && data.task.court && data.task.court.claimNumber ? `${data.task.court.claimNumber}` : "") : "{Task.Court.Claim Number}", text: "Task.Court.Claim Number"},
         
        ];

        let arr = list.concat(customList, debtorsList, courtsList, claimentList);

        return [].concat(...arr);

    /*

        let variables = {
          "Defendant.Short_Name/Company_Name": matter.contact ? matter.contact.name + (matter.contact.suffix ? " "+ matter.contact.suffix : "") : "",
          "Defendant.Letter/DX_Address_Line_1": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].name : "No Address" : "",
          "Defendant.Letter/DX_Address_Line_2": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].street : "No Address" : "",
          "Defendant.Letter/DX_Address_Line_3": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].city : "No Address" : "",
          "Defendant.Letter/DX_Address_Line_4": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].province : "No Address" : "",
          "Defendant.Letter/DX_Address_Line_5": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].postal_code : "No Address" : "",
          "Defendant.Letter/DX_Address_Line_6": matter.contact ? matter.contact.addresses ? matter.contact.addresses[0].country : "No Address" : "",
          "Defendant.Reference": matter.description,
          "Letter_Ref.Text": matter.client_reference,
          "Defendant.This_Letter_Date_full": dayjs().format("DD/MM/YYYY"),
          "Defendant_Salutation_text.Text": matter.contact ? `${matter.contact.prefix} ${matter.contact.last_name}` : "",
          "Client_global.Full_Name/Company_Name": matter.organisation ? `${matter.organisation.name}` : "",
          "Total_Debt_Interest": 1000,
          "Defendant_Account_number.Text": matter._clioId
        }; */

};

export {getList};