import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import Dash from "./dash";
import AddTemplate from "./add";
import FeesNavbar from "../_components/FeesNavbar";

const routePrefix = "/settings/fee_scales";

export default function Index({history, match}) {
  return (
    <>
      <FeesNavbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/edit/:id`} component={AddTemplate} />
        <Route exact path={`${routePrefix}/add`} component={AddTemplate} />
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
