import React, { useState, useEffect } from "react";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Input,
  Toggle,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

import ChainedContext from "../../../../contexts/chainedContext";
import AuthenticationReceiver from "../../../../plugins/clio_auth/_components/authenticationReceiver";

function Add({auth}) {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
    time: 0,
    currentRate: 0,
    isBillable: true,
    defaultAdminRate: false,
  });
  const [defaultAdminRate, setDefaultAdminRate] = useState(0);

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.settings.get().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        if (res[0]) {
          setUserList(
            res[0].users.map((a) => {
              if(auth && a.email == auth.user.email){
                setData({ ...data, assigned: a._id });
              }
              return {
                text: a.name,
                value: a._id,
                rate: a.rate,
              };
            })
          );
        }

        if (res[1]) {
          setDefaultAdminRate(res[1].adminRate ? res[1].adminRate : 0);
        }
      });

      setMountLoading(false);
    }
  }, [mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Create Activity
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          <Group className="py-2">
            <TextArea
              label="Description"
              name="description"
              value={data.description}
              onChange={onChange}
            />
          </Group>
          <Group className="py-2">
            <Dropdown
              label="Fee Earner"
              name="assigned"
              value={data.assigned}
              onChange={(e) => {
                const user = userList.find(
                  (item) => item.value === e.target.value
                );

                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                  currentRate:
                    data.defaultAdminRate !== true
                      ? user
                        ? user.rate
                        : 0
                      : data.currentRate,
                });
              }}
              options={userList}
            />
            <DateSelect
              label="Due Date"
              name="due_date"
              value={data.due_date}
              onChange={onDateChange}
            />
            <Input
              type="number"
              incrementBy="6"
              label="Time"
              name="time"
              value={data.time}
              onChange={onChange}
            />
            <Input
              name="cost"
              label="Cost (£)"
              disabled
              value={(
                data.time * (data.currentRate ? data.currentRate / 60 : 0)
              ).toFixed(2)}
              onChange={onChange}
            />
          </Group>
          <Group>
            <Toggle
              className="my-2"
              label="Charge Default Admin Rate"
              enabled={data.defaultAdminRate ? data.defaultAdminRate : false}
              onChange={() => {
                const user = userList.find(
                  (item) => item.value === data.assigned
                );

                setData({
                  ...data,
                  defaultAdminRate: data.defaultAdminRate
                    ? !data.defaultAdminRate
                    : true,
                  currentRate: (
                    data.defaultAdminRate !== undefined
                      ? !data.defaultAdminRate
                      : true
                  )
                    ? defaultAdminRate
                    : user
                    ? user.rate
                    : 0,
                });
              }}
            />
            <Toggle
              className="my-2"
              label="Is Billable?"
              enabled={data.isBillable ? data.isBillable : false}
              onChange={() =>
                setData({
                  ...data,
                  isBillable: data.isBillable ? !data.isBillable : true,
                })
              }
            />
          </Group>
          <div className="pt-2 flex justify-between">
            <Button
              colour="red"
              label="Cancel"
              onClick={() =>
                history.push(
                  `/prospective-contacts/${params.contact_id}/activities`
                )
              }
              icon={XIcon}
            />
            <Button
              colour="green"
              label="Create"
              onClick={() => {
                API.prospective_contact.activities
                  .create(params.contact_id, data)
                  .then((res) =>
                    history.push(
                      `/prospective-contacts/${params.contact_id}/activities/${res}`
                    )
                  );
              }}
              icon={CheckIcon}
            />
          </div>
        </div>
      </Segment>
    </div>
  );
}

export default ChainedContext(withRouter(Add), [
  [
    AuthenticationReceiver,
    (auth) => {
      return { auth };
    },
  ],
]);