import React, { useState, useEffect } from "react";
import { DocumentTextIcon, SearchIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import { Transition, Dialog } from "@headlessui/react";
import ChainedContext from "../../contexts/chainedContext";
import { Button } from "../../components/Forms";
import Segment from "../../components/Segment";
import API from "../../api";
import { Receiver as ContextReceiver } from "./_context";
import { ThemeTable } from "../Tables";
import ClientBreadcrumbs from "../../pages/contacts/components/ClientBreadcrumbs";

const CreditChecks_Dashboard = ({ creditSafeContext, history, match }) => {
  const [previousReports, setPreviousReports] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [contact, setContact] = useState({});
  const [creditSearchResult, setCreditSearchResult] = useState({
    totalSize: 0,
    companies: [],
  });

  const getData = () => {
    creditSafeContext.getAllReports().then((res) => {
      setPreviousReports(res);
    });
    API.contacts.get(match.params.contact_id).then((res) => {
      setContact(res);
    });
  };
  useEffect(() => {
    getData();
  }, [creditSafeContext]);

  const handleStartCreditCheck = () => {
    API.external_providers.credit_safe
      .searchCompanies({
        countries: "GB",
        name: creditSafeContext.defaultSearchName,
      })
      .then((res) => {
        setIsOpen(true);
        setCreditSearchResult(res);
      });
  };

  const handleGetCreditReport = (type, resultItem) => () => {
    creditSafeContext.requestReport(type, resultItem.id).then(() => {
      setIsOpen(false);
      setCreditSearchResult({
        totalSize: 0,
        companies: [],
      });
      getData();
    });
  };

  const renderCreditCheckSearchResults = () => {
    if (creditSearchResult.companies.length) {
      return (
        <div>
          <h2>
            Company Search Results for "{creditSafeContext.defaultSearchName}"
          </h2>

          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-800">
            <thead>
              <tr className="bg-gray-50 dark:bg-gray-900">
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Reg Num</th>
                <th>Vat Num</th>
                <th>Address</th>
                <th>{""}</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-black divide-y divide-gray-200">
              {creditSearchResult.companies.map((item) => {
                return (
                  <tr key={item.id} className="bg-white dark:bg-black">
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.status}</td>
                    <td>{item.regNo}</td>
                    <td>
                      {item.vatNo !== undefined ? item.vatNo.join(", ") : ""}
                    </td>
                    <td>{item.address.simpleValue}</td>
                    <td>
                      <Button
                        colour="amber"
                        label="Get JSON"
                        onClick={handleGetCreditReport("json", item)}
                        icon={DocumentTextIcon}
                      />
                      {/*<Button*/}
                      {/*    colour="blue"*/}
                      {/*    label="Get PDF"*/}
                      {/*    onClick={handleGetCreditReport("pdf", item)}*/}
                      {/*    icon={DocumentIcon}*/}
                      {/*/>*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };
  const renderPreviousReports = () => {
    return (
      <ThemeTable
        header={[
          { text: "Date", value: "createdAt" },
          { text: "Format", value: "reportFormat" },
          { text: "Requested By", value: "requestedBy" },
        ]}
        body={previousReports.map((item) => ({
          ...item,
          createdAt: dayjs(item.createdAt).format("DD-MM-YYYY HH:mm"),
          requestedBy: item.requestedBy ? item.requestedBy.name : "--",
        }))}
        action={(item) => {
          history.push(`${match.url}/report/${item._id}`);
        }}
      />
    );
  };
  const companySearchDialog = () => {
    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto "
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-7xl p-4 my-4 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="pt-2l">
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-medium text-gray-900 border-b border-gray-400 py-2"
                >
                  Companies
                </Dialog.Title>
              </div>
              <div>{renderCreditCheckSearchResults()}</div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <div className={""}>
      {companySearchDialog()}
      <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/contacts/${contact._id}`, current: false },
        { name: "Credit Reports", href: `/contacts/${contact._id}/credit_checks`, current: true},
      ]} />
      <Segment>
        <div className="flex justify-between items-center w-full mb-4 pb-4 border-b border-primary">
          <div className="text-2xl">Credit Reports</div>
          <Button
            colour="amber"
            label="New Credit Check"
            onClick={handleStartCreditCheck}
            icon={SearchIcon}
          />
        </div>
        {renderPreviousReports()}
      </Segment>
    </div>
  );
};
export default ChainedContext(CreditChecks_Dashboard, [
  [ContextReceiver, "creditSafeContext"],
]);
