import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import DebtsList from "./list";
import AddDebt from "./Add";
import AddPayment from "./AddPayment";

const routePrefix = "/matters/:matter_id/debts";

export default function DebtorPages() {
  return (
    <div>
      <Switch>
        <Route exact path={`${routePrefix}/payment`} component={AddPayment}/>
        <Route exact path={`${routePrefix}/add`} component={AddDebt}/>
        <Route exact path={`${routePrefix}`} component={DebtsList} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
