import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default class Services {
  async add(data) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/services/`,
      data
    );
  }

  async edit(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/services/${id}`,
      data
    );
  }

  async remove(id) {
    return ApiRequest.delete(
      `${AppConfig.api_server}/api/settings/services/${id}`
    );
  }

  async list(org) {
    return ApiRequest.get(`${AppConfig.api_server}/api/settings/services/list${org ? "?org_id="+org : ""}`);
  }

  async clioMatters() {
    return ApiRequest.get(`${AppConfig.api_server}/api/settings/services/clioMatters`);
  }
  async getClioMatters() {
    return ApiRequest.get(`${AppConfig.api_server}/api/settings/services/getClioMatters`);
  }

  async runServiceCheck(id) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/services/${id}/check`
    );
  }

  async updateContactService(service_id, contact_id) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/services/${service_id}/updateContact/${contact_id}`
    );
  }
}
