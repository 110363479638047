import React from "react";
import {Switch, Route} from "react-router-dom";
import view from "./view";
import list from "./list";
import NotFound from "../../components/NotFound";

const routePrefix = "/postbox"

export default function Index(){
    return (
        <Switch>
            <Route path={`${routePrefix}/:postbox_id`} component={view}/>
            <Route exact path={`${routePrefix}`} component={list}/>
            <Route path="*" component={NotFound}/>
        </Switch>
    );
}