import React from "react";
import Contact from "./Contact_Context";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";

class Contact_Receiver extends React.Component {
  state = {
    firstLoad: true,
    contact: {},
  };

  componentDidMount() {
    this._refreshContact();
  }

  _refreshContact = () => {
    this.props.api.contacts
      .get(this.props.match.params.contact_id)
      .then((res) => {
        this.setState({
          contact: res,
          firstLoad: false,
        });
      })
      .finally(() => {});
  };

  render() {
    return (
      <Contact.Provider
        value={{
          _refresh: this._refreshContact,
          ...this.state.contact,
        }}
      >
        {this.state.firstLoad ? (
          <Loader>Loading Contact</Loader>
        ) : (
          this.props.children
        )}
      </Contact.Provider>
    );
  }
}

export default withRouter(Contact_Receiver);
