import React, { useEffect, useState } from "react";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import DayJS from "dayjs";
import { Button, Input } from "../../../../components/Forms";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import { GiCheckMark } from "react-icons/gi";

const Table = ({ data, onCreate, onUpdate, onDelete = () => {} }) => {
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    reference: "",
    contact: "",
    claimNumber: "",
    archived: false
  });
  const [submitError, setSubmitError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    let errors = {};
    if (formData.name === "") {
      errors.name = "Name can not be empty";
    }

    setFormErrors(errors);
  }, [formData]);

  const editing = formData._id !== undefined;
  const handleOnChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleFormSubmit = () => {
    setFormIsSubmitting(true);
    setSubmitError(null);
    let apiAction;
    if (formData._id === undefined) {
      // Create new
      apiAction = onCreate(formData);
    } else {
      // Editing
      apiAction = onUpdate(formData._id, formData);
    }
    apiAction
      .then(() => {
        setFormData({
          title: "",
          archived: false
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };
  const handleDeleteSubmit = () => {
    setShowDelete(false);
    setFormIsSubmitting(true);
    setSubmitError(null);
    onDelete(formData._id)
      .then(() => {
        setFormData({
          title: "",
          archived: false
        }); // Reset the form
      })
      .catch((err) => {
        if (err.isAxiosError !== undefined) {
          setSubmitError(err.response.data.message);
        } else {
          setSubmitError(
            "There was an unexpected error while processing data."
          );
        }
      })
      .finally(() => {
        setFormIsSubmitting(false);
      });
  };

  return (
    <div>
      {submitError && (
        <div
          className={"p-2 border rounded border-red-800 bg-red-700 text-white"}
        >
          {submitError}
        </div>
      )}
      {showDelete && (
        <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={true}
          header={"Are you sure?"}
          content={
            "Are you sure you like to remove this Court, this action can't be undone."
          }
          handleSubmit={handleDeleteSubmit}
        />
      )}

      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

          <div className="border-b border-gray-900/10 pb-12">

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleOnChange}
                  error={formErrors.name}
                  disabled={formIsSubmitting}
                />
                {formErrors.title && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.name}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Telephone
              </label>
              <div className="mt-2">
                  <Input
                    name="phone"
                    value={formData.phone}
                    onChange={handleOnChange}
                    error={formErrors.phone}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.title && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.phone}
                    </span>
                  )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                Reference
              </label>
              <div className="mt-2">
                <Input
                  name="reference"
                  value={formData.reference}
                  onChange={handleOnChange}
                  error={formErrors.reference}
                  disabled={formIsSubmitting}
                />
                {formErrors.title && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.reference}
                  </span>
                )}
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                  <Input
                    name="email"
                    value={formData.email}
                    onChange={handleOnChange}
                    error={formErrors.email}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.title && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.email}
                    </span>
                  )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Contact
              </label>
              <div className="mt-2">
                <Input
                    name="contact"
                    value={formData.contact}
                    onChange={handleOnChange}
                    error={formErrors.contact}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.title && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.contact}
                    </span>
                  )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Claim Number
              </label>
              <div className="mt-2">
                  <Input
                    name="claimNumber"
                    value={formData.claimNumber}
                    onChange={handleOnChange}
                    error={formErrors.claimNumber}
                    disabled={formIsSubmitting}
                  />
                  {formErrors.title && (
                    <span className={"p-1 text-red-500"}>
                      {formErrors.claimNumber}
                    </span>
                  )}
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                Address
              </label>
              <div className="mt-2">
                <Input
                  name="address"
                  value={formData.address}
                  onChange={handleOnChange}
                  error={formErrors.address}
                  disabled={formIsSubmitting}
                />
                {formErrors.title && (
                  <span className={"p-1 text-red-500"}>
                    {formErrors.address}
                  </span>
                )}
              </div>
            </div>

          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
              {editing && (
                  <Button
                    className="text-sm font-semibold leading-6 text-gray-900"
                    label={formData.archived ? "Un-Archive" : "Archive"}
                    colour={formData.archived ? "green" : "red"}
                    icon={formData.archived ? GiCheckMark : TrashIcon}
                    disabled={
                      Object.keys(formErrors).length > 0 ||
                      formIsSubmitting
                    }
                    onClick={handleDeleteSubmit}
                  />
                )}
          <Button
              label={editing ? "Save" : "Add"}
              colour={editing ? "blue" : "green"}
              icon={editing ? PencilIcon : PlusIcon}
              className={"rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"}
              disabled={
                Object.keys(formErrors).length > 0 ||
                formIsSubmitting || (editing && formData.archived)
              }
              onClick={handleFormSubmit}
            />
        </div>

        </div>

            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">

              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      E-Mail
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Telephone
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Reference
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Contact
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      ClaimNumber
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.length ? (
                    data.map((d, index) => (
                      <tr key={"court_" + index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.name + " "}{d.archived && <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                        Archived
                      </span>}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6" style={{maxWidth: "150px", whiteSpace: "initial"}}>
                          {d.address}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.email}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.phone}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.reference}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.contact}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {d.claimNumber}
                        </td>
                        {/* 
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {d.archived}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Button
                            label={""}
                            colour={"blue"}
                            icon={PencilIcon}
                            className={"ml-auto"}
                            onClick={() => {
                              setFormData({
                                _id: d._id,
                                name: d.name,
                                address: d.address,
                                email: d.email,
                                phone: d.phone,
                                reference: d.reference,
                                contact: d.contact,
                                claimNumber: d.claimNumber,
                                archived: d.archived,
                              });
                            }}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        There are no items to display.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;
