import React from "react";
import PropTypes from "prop-types";

export default function TextArea({
  name = "",
  label = "",
  value = "",
  rows = 4,
  type = "text",
  placeholder = "",
  onChange = () => {},
  className = "",
}) {
  return (
    <div className={className ? className : ""}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          rows={rows}
          type={type}
          name={name}
          value={value}
          className="p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200"
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
