import React, { useState, useEffect } from "react";
import DayJS from "dayjs";
import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import { Button } from "../../../components/Forms";
import API from "./_api";
import Segment from "../../../components/Segment";

const WorkflowPage = ({ matter }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [workflowTasks, setWorkflowTasks] = useState([]);
  const [workflows, setWorkflows] = useState([]);

  const getData = () => {
    API.workflow.getMatterWorkflows(matter._id).then((res) => {
      setWorkflows(res);
    });
    API.workflow.getMatterWorkflowTasks(matter._id).then((res) => {
      setWorkflowTasks(res);
    });
  };
  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 17000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleCreateWorkflow = () => {
    setIsCreating(true);

    API.workflow
      .startNew(matter._id, {
        flowStartAction: "lba:prepare",
      })
      .finally(() => {
        setIsCreating(false);
        setTimeout(() => {
          getData();
        }, 1000);
      });
  };
  const handleTaskClick = (taskId, response) => {
    API.workflow.setTaskResponse(matter._id, taskId, response).then(() => {
      setTimeout(() => {
        getData();
      }, 1000);
    });
  };

  return (
    <Segment>
      <div className={"p-2"}>
        <h1>Tim's workflow tester</h1>
        <Button
          onClick={handleCreateWorkflow}
          label={isCreating ? "Creating..." : "Create Workflow"}
          disabled={isCreating}
        />

        <div className={"pt-2"}>
          <h2>Workflow Tasks</h2>
          {workflowTasks.map((task) => {
            return (
              <div key={task._id} className={"p-2 border-2 border-gray-300"}>
                {task.title}
                {task.taskType === "action" && (
                  <Button
                    onClick={() => handleTaskClick(task._id, "done")}
                    label={"Done"}
                    colour={"green"}
                  />
                )}
                {task.taskType === "decision" && (
                  <React.Fragment>
                    <Button
                      onClick={() => handleTaskClick(task._id, "no")}
                      label={"No"}
                      colour={"red"}
                    />
                    <Button
                      onClick={() => handleTaskClick(task._id, "yes")}
                      label={"Yes"}
                      colour={"green"}
                    />
                  </React.Fragment>
                )}
              </div>
            );
          })}
          <h2>Workflows</h2>
          {workflows.map((workflow) => {
            return (
              <div
                key={workflow._id}
                className={"p-2 border-2 border-gray-300"}
              >
                Started At:{" "}
                {DayJS(workflow.createdAt).format("DD/MM/YYYY hh:mma")}
                <br />
                {workflow.finishedAt !== null &&
                  `Finished At: ${DayJS(workflow.finishedAt).format(
                    "DD/MM/YYYY hh:mma"
                  )}`}
                <br />
                <h3>History</h3>
                {workflow.flowHistory.map((historyItem) => {
                  return (
                    <div
                      key={historyItem._id}
                      className={"my-2 p-2 border-2 border-gray-300"}
                    >
                      Ran At:{" "}
                      {DayJS(historyItem.ranAt).format("DD/MM/YYYY hh:mma")}
                      <br />
                      actionId: {historyItem.actionId}
                      <br />
                      endResult: {historyItem.endResult}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Segment>
  );
};
export default ChainedContext(WorkflowPage, [[MatterReceiver, "matter"]]);
