import {ApiRequest} from "../../../plugins/clio_auth";
import AppConfig from "../../../_appConfig";

export default {
  workflow: {
    startNew: (matter_id, data) => ApiRequest.post(`${AppConfig.api_server}/api/matters/${matter_id}/workflow/flows/create`, data),
    getMatterWorkflows: (matter_id) => ApiRequest.get(`${AppConfig.api_server}/api/matters/${matter_id}/workflow/flows`),
    getMatterWorkflowTasks: (matter_id) => ApiRequest.get( `${AppConfig.api_server}/api/matters/${matter_id}/workflow/tasks`),
    setTaskResponse: (matter_id, task_id, response) => ApiRequest.put(`${AppConfig.api_server}/api/matters/${matter_id}/workflow/tasks/${task_id}/response`, {response}),
  }
}