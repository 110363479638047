import React from "react";
import Debtor from "./Debtor_Context";

const joinProps = (a, b) => {
  return {
    ...a,
    ...b,
  };
};
const Reciever = (Compo, mapContext) => {
  return (props) => {
    return (
      <Debtor.Consumer>
        {(context) => <Compo {...joinProps(props, mapContext(context))} />}
      </Debtor.Consumer>
    );
  };
};
export default Reciever;
