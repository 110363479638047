import React from "react";
import { Route, Switch } from "react-router-dom";

import Contacts from "./contacts";
import ProspectiveContacts from "./prospective_contacts";
import SearchPage from "./search";
import Settings from "./settings";
import Home from "./home";
import Matters from "./matters";
import Postbox from "./postbox";
import Notifications from "./notifications";
import NotFound from "../components/NotFound";
import Admin from "./admin";

const IndexRoutes = () => {
  return (
    <Switch>
      <Route path="/search" component={SearchPage} />
      <Route path="/matters" component={Matters} />
      <Route path="/prospective-contacts" component={ProspectiveContacts} />
      <Route path="/contacts" component={Contacts} />
      <Route path="/settings" component={Settings} />
      <Route path="/postbox" component={Postbox} />
      <Route path="/notifications" component={Notifications} />
      <Route path="/admin" component={Admin} />
      <Route exact path="/" component={Home} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default IndexRoutes;
