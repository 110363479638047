import React, { useState, useRef } from "react";
import Loader from "../Loader";
import toast from "react-hot-toast";

const FileUploader = ({maxFileSize = 95, onFileToUpload, uploading = false}) => {
  const [isHovered, setIsHovered] = useState(false);
  const uploadField = useRef();

  const maxFileUpload = maxFileSize * (1024 * 1024);

  const onFileLoad = (e) => {
    if (e.currentTarget.files[0] === undefined) {
      toast.error("File Error");
      return;
    }
    const file = e.currentTarget.files[0];

    if(file.size > maxFileUpload){
      toast.error("File is too large to upload");
      return;
    }

    onFileToUpload(file);
    setIsHovered(false);
    uploadField.current.value = null;
  };

  return (
    <div className="flex flex-col text-center rounded-lg my-1">
      <div className={`field w-full relative bg-gray-300 border-dashed border-2 rounded p-2 ${isHovered ? "border-black" : "border-gray-400"}`}>
        {uploading ? <div className="helper-text text-center pt-2"><Loader /></div> :
          <>
            <input
              type="file"
              name="file-browser-input"
              className={"w-full h-full top-0 left-0 absolute opacity-0 z-10"}
              ref={uploadField}
              onDragOver={() => setIsHovered(true)}
              onDragLeave={() => setIsHovered(false)}
              onChange={(e) => onFileLoad(e)}
            />
            <div className="helper-text text-center">
              <div className={"inline-block my-2 px-2 border rounded border-white"}>Select File</div><br />
              or drag & drop file here<br /> <span className={"text-xs"}>Maximum Size: {maxFileSize} MB</span>
            </div>
          </>}
      </div>
    </div>
  );
}

export default FileUploader;
