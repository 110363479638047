import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import AddDebtors from "./addDebtors";
import EditDebtors from "./editDebtors";
import CreditCheckRoutes from "./creditCheck";
import View from "./view";
import api from "../../../api";
import DebtorNavbar from "./navbar";
import DebtorProvider from "../../../contexts/debtor_context/Debtor_Provider";
import DebtorsList from "./list";
import TaskPages from "./tasks";
import ActivityPages from "./activities";
import theDebt from "./theDebt";
import DebtPages from "./debt";

const routePrefix = "/matters/:matter_id/debtors";

export default function DebtorPages() {
  return (
    <div>
      <Switch>
        <Route exact path={`${routePrefix}`} component={DebtorsList} />
        <Route exact path={`${routePrefix}/add`} component={AddDebtors} />
        <Route
          path={`${routePrefix}/:debtor_id`}
          component={ViewDebtorsRoutes}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
function ViewDebtorsRoutes({ history, match }) {
  return (
    <DebtorProvider api={api}>
      <DebtorNavbar history={history} match={match} />
      <Switch>
        <Route
          path={`${routePrefix}/:debtor_id/activities`}
          component={ActivityPages}
        />
        <Route path={`${routePrefix}/:debtor_id/tasks`} component={TaskPages} />
        <Route
          path={`${routePrefix}/:debtor_id/credit_checks`}
          component={CreditCheckRoutes}
        />
        <Route
          exact
          path={`${routePrefix}/:debtor_id/edit`}
          component={EditDebtors}
        />
        <Route path={`${routePrefix}/:debtor_id/debt`} component={DebtPages} />
        <Route exact path={`${routePrefix}/:debtor_id`} component={View} />
      </Switch>
    </DebtorProvider>
  );
}
