import React, { Component } from "react";
import { XCircleIcon, SearchIcon } from "@heroicons/react/solid";
import PageScroller from "./PageScroller";
import { Toggle } from "../Forms";
import Loader from "../Loader";

const pageSizes = [10, 25, 50, 100];

export default class PaginateWrapper extends Component {
  state = {
    pageSize: pageSizes[1],
    pageNum: 1,
    totalPages: 0,
    totalItems: 0,
    search: "",
    filters: this.props.status_filter ? {
      filter_active: true,
      filter_hold: false,
      filter_closed: false,
      filter_archived: false,
    } : {},
    items: [],
    loading: true,
    globalError: null,
  };

  componentDidMount() {
    if (this.props.preserve_options !== undefined) {
      let search = sessionStorage.getItem(
        `${this.props.preserve_options}_search`
      );
      let filters = sessionStorage.getItem(
        `${this.props.preserve_options}_filters`
      );
      let page_num = sessionStorage.getItem(
        `${this.props.preserve_options}_pageNum`
      );
      let page_size = sessionStorage.getItem(
        `${this.props.preserve_options}_pageSize`
      );

      this.setState(
        {
          pageSize: page_size !== null ? parseFloat(page_size) : pageSizes[1],
          pageNum: page_num !== null ? parseFloat(page_num) : 1,
          search: search !== null ? search : "",
          filters:
            filters !== null
              ? {...JSON.parse(filters), filter_active : true}
              : this.props.archived_toggle !== undefined
              ? { [this.props.archived_toggle.field]: false, filter_active: true }
              : {filter_active: true},
        },
        () => this.queryData()
      );
    } else {
      this.queryData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataQuery && prevProps.dataQuery !== this.props.dataQuery) {
      this.queryData();
    }
  }

  handleSearch = (e) =>
    this.setState({ search: e.target.value, pageNum: 1 }, () => {
      if (this.props.preserve_options !== undefined) {
        sessionStorage.setItem(`${this.props.preserve_options}_pageNum`, 1);

        sessionStorage.setItem(
          `${this.props.preserve_options}_search`,
          e.target.value
        );
      }
      this.queryData();
    });

  handleDropdownChange = (e) =>
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [e.target.name]: e.target.value,
        },
        pageNum: 1,
      },
      () => {
        if (this.props.preserve_options !== undefined) {
          sessionStorage.setItem(`${this.props.preserve_options}_pageNum`, 1);

          sessionStorage.setItem(
            `${this.props.preserve_options}_filters`,
            JSON.stringify(this.state.filters)
          );
        }
        this.queryData();
      }
    );

  queryData() {
    if (this.props.dataQuery) {
      this.setState(
        {
          loading: true,
          globalError: null,
        },
        () => {
          this.props
            .dataQuery(
              this.state.pageNum,
              this.state.pageSize,
              this.state.search,
              this.state.filters
            )
            .then((data) => {
              this.setState({
                pageNum: data.page,
                totalPages: data.pages,
                totalItems: data.total,
                items: data.docs,
              });
            })
            .catch((err) => {
              let globalError =
                "There was an unexpected error while retrieving data from the server";

              if (err.response !== undefined) {
                globalError = err.response.data.message;
              }

              this.setState({
                globalError,
              });
            })
            .finally(() => {
              this.setState({
                loading: false,
              });
            });
        }
      );
    }
  }

  updatePageNumber = (newPageNum) => {
    this.setState({ pageNum: newPageNum }, () => {
      if (this.props.preserve_options !== undefined) {
        sessionStorage.setItem(
          `${this.props.preserve_options}_pageNum`,
          newPageNum
        );
      }

      this.queryData();
    });
  };

  render() {
    return (
      <>
        {this.state.globalError ? (
          <div className="rounded-md bg-red-200 p-4 mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">ERROR</h3>
                <div className="mt-2 text-sm text-red-700">
                  {this.state.globalError}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="grid grid-cols-2">
          {this.props.search === true && (
            <div className="col-span-2">
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    name="search"
                    className="h-9 block w-full bg-gray-200 pl-10 sm:text-sm border-gray-300 rounded-md border-transparent"
                    placeholder="Search"
                    value={this.state.search}
                    onChange={this.handleSearch}
                  />
                </div>
              </div>
            </div>
          )}
          {this.props.archived_toggle !== undefined ? (
            <div className="col-span-2">
              <div className="w-fit ml-auto">
                <Toggle
                  className="mt-2"
                  enabled={
                    this.state.filters.show_archived
                      ? this.state.filters.show_archived
                      : false
                  }
                  label={"Archived Visible"}
                  onChange={() => {
                    this.setState(
                      {
                        filters: {
                          show_archived: this.state.filters.show_archived
                            ? !this.state.filters.show_archived
                            : true,
                        },
                      },
                      () => {
                        if (this.props.preserve_options !== undefined) {
                          sessionStorage.setItem(
                            `${this.props.preserve_options}_filters`,
                            JSON.stringify(this.state.filters)
                          );
                        }
                        this.queryData();
                      }
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}

          {this.props.status_filter &&
          
          <div className="col-span-2">
              <div className="w-fit ml-auto">
                <div className="relative inline-flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_active"
                      aria-describedby="comments-description"
                      name="filter_active"
                      type="checkbox"
                      checked={this.state.filters.filter_active}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters, 
                              filter_active: !this.state.filters.filter_active,
                            },
                          },
                          () => {
                            this.queryData();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_active" className="font-medium text-gray-900">
                      Active
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_hold"
                      aria-describedby="comments-description"
                      name="filter_hold"
                      type="checkbox"
                      checked={this.state.filters.filter_hold}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters, 
                              filter_hold: !this.state.filters.filter_hold,
                            },
                          },
                          () => {
                            this.queryData();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_hold" className="font-medium text-gray-900">
                      On Hold
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_closed"
                      aria-describedby="comments-description"
                      name="filter_closed"
                      type="checkbox"
                      checked={this.state.filters.filter_closed}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters, 
                              filter_closed: this.state.filters.filter_closed
                                ? !this.state.filters.filter_closed
                                : true,
                            },
                          },
                          () => {
                            this.queryData();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_closed" className="font-medium text-gray-900">
                      Closed
                    </label>
                  </div>
                </div>
                <div className="relative inline-flex items-start ml-4">
                  <div className="flex h-6 items-center">
                    <input
                      id="filter_archived"
                      aria-describedby="comments-description"
                      name="filter_archived"
                      type="checkbox"
                      checked={this.state.filters.filter_archived}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters, 
                              filter_archived: this.state.filters.filter_archived
                                ? !this.state.filters.filter_archived
                                : true,
                            },
                          },
                          () => {
                            this.queryData();
                          }
                        );
                      }}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor="filter_archived" className="font-medium text-gray-900">
                      Archived
                    </label>
                  </div>
                </div>
              </div>
            </div>
          }

          {this.props.advanced_toggled !== undefined ? (
            <div className="col-span-2">
              <div className="w-fit ml-auto">
                <Toggle
                  className="mt-2"
                  enabled={
                    this.state.filters[this.props.advanced_toggle.field] !== undefined
                      ? this.state.filters[this.props.advanced_toggle.field]
                      : this.props.advanced_toggle.default ? this.props.advanced_toggle.default : false
                  }
                  label={
                    this.props.advanced_toggle.unchecked_label && this.props.advanced_toggle.checked_label ? 
                    (this.state.filters[this.props.advanced_toggle.field] ? this.props.advanced_toggle.checked_label : this.props.advanced_toggle.unchecked_label) 
                    : this.props.advanced_toggle.title }
                  onChange={() => {
                    this.setState(
                      {
                        filters: {
                          [this.props.advanced_toggle.field]: this.state.filters[this.props.advanced_toggle.field]
                            ? !this.state.filters[this.props.advanced_toggle.field]
                            : true,
                        },
                      },
                      () => {
                        if (this.props.preserve_options !== undefined) {
                          sessionStorage.setItem(
                            `${this.props.preserve_options}_filters`,
                            JSON.stringify(this.state.filters)
                          );
                        }
                        this.queryData();
                      }
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className={`${
              this.props.archived_toggle !== undefined ||
              this.props.search !== undefined
                ? "mt-4"
                : ""
            } col-span-2`}
          >
            {this.state.loading ? (
              <Loader />
            ) : this.props.render ? (
              this.props.render(this.state.items)
            ) : (
              <></>
            )}
          </div>
          {this.state.items.length >= 1 && !this.props.hidePageSelect && (
            <>
              <div className="mt-4 col-span-2 lg:col-span-1">
                <div>
                  <label
                    htmlFor="pageSize"
                    className="inline text-sm text-primary mr-4"
                  >
                    Page Size:
                  </label>
                  <select
                    name="pageSize"
                    value={this.state.pageSize}
                    onChange={(e) => {
                      this.setState(
                        {
                          pageSize: e.target.value,
                          pageNum: 1,
                        },
                        () => {
                          if (this.props.preserve_options !== undefined) {
                            sessionStorage.setItem(
                              `${this.props.preserve_options}_pageNum`,
                              1
                            );
                            sessionStorage.setItem(
                              `${this.props.preserve_options}_pageSize`,
                              e.target.value
                            );
                          }
                          this.queryData();
                        }
                      );
                    }}
                    className="inline w-full bg-gray-200 lg:w-24 pl-3 pr-10 py-2 text-primary sm:text-sm rounded-md"
                  >
                    {pageSizes.map((i) => {
                      return <option key={i}>{i}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="mt-4 col-span-2 lg:col-span-1">
                <PageScroller
                  totalPages={this.state.totalPages}
                  pageNum={this.state.pageNum}
                  updatePageNumber={this.updatePageNumber}
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
