import React, { useEffect, useState } from "react";
import Segment from "../../../components/Segment";
import API from "../../../api";
import toast from "react-hot-toast";
import CommonFunctions from "../../../CommonFunctions";
import ChainedContext from "../../../contexts/chainedContext";
import OrganisationReceiver from "../../../contexts/organisation_context/Organisation_Receiver";
import { Button, Input } from "../../../components/Forms";

import {
  UserIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import { GiHouse } from "react-icons/gi";
import Select2 from "../../../components/Forms/Select2";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Dash({ org, history }) {
  const [licence, setLicence] = useState({
    price: 0,
    licence: null,
    seats: 0,
  });

  const [totals, setTotals] = useState({
    price: 0,
    seats: 0,
  });

  const [licenceTypes, setLicenceTypes] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  function getData(){
    API.licences.getAll().then(res => {

      let price = 0;
      let seats = 0;

      let arr = res.map((l, index) => {

        price += parseFloat(l.price) || 0;
        seats += parseInt(l.seats, 10) || 0;

        return ({
          key: index, 
          value: l._id, 
          text: l.description,
          price: l.price,
          seats: l.seats
        })
      });

      setTotals({
        price, seats
      });
      setLicenceTypes(arr)
  
  }
  
  )
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="">
            {/* Page header */}
            <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-primary">{org.name}</h1>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <GiHouse
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.addresses &&
                    org.addresses.find((item) => item.primary === true)
                      ? `${
                          org.addresses.find((item) => item.primary === true)
                            .postal_code
                        } (${
                          org.addresses.find((item) => item.primary === true).name
                        })`
                      : "N/A"}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <PhoneIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.contactNumber}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.contact}
                  </div>
                </div>
              </div>
            </div>
              <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                <Button
                  onClick={() => history.push(`/admin/organisations/${org._id}/edit`)}
                  label="Edit Organisation"
                  colour="blue"
                />
              </div>
          </div>
            {/* Main Content */}
            <div className="mt-4 mx-auto md:grid md:grid-cols-3">
                <div className="col-span-2" >
                  <div className="md:grid md:grid-cols-2">
                    <div className="p-6 col-span-2">
                      <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                        <div className="text-center text-xl py-2 text-primary">
                          Users
                        </div>
                        <div className="flex justify-around mb-4">
                          <div className=" p-4 rounded-xl text-center">
                            <div className="text-3xl">
                            {org.users}
                            </div>
                            <div className="text-xl text-primary">No. of Users</div>
                          </div>
                          <div className=" p-4 rounded-xl text-center">
                            <div className="text-3xl">
                            {org.licenced} / {org.licence_total_seats}
                            </div>
                            <div className="text-xl text-primary">Licenced Users / Licences Total</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>
                <div className="col" >
                  <div className="text-xl py-2 text-primary">
                        Licences
                      </div>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Licence
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          Assigned / Seats
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          Price p/month
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          <Select2 
                            options={licenceTypes}
                            name="licence"
                            value={licence && licence.licence}
                            onChange={(e) => {
                              let licence = licenceTypes.findIndex(a => a.value == e.target.value);
                              console.log("🚀 ~ Dash ~ licence:", licence);

                              setLicence({
                                licence: e.target.value,
                                price: licence !== -1 ? licenceTypes[licence].price : 0,
                                seats: licence !== -1 ? licenceTypes[licence].seats : 0,
                              })


                            }}
                          />
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          {licence && licence.seats}
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                        >
                          {licence && licence.price}
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <Button
                          disabled={!licence || !licence.licence}
                          onClick={() => {
                                API.organisations.addLicence(org._id, licence).then(res => {
                                  if(res.success) {
                                    toast.success(res.message);
                                    getData();
                                  }else{
                                    toast.error(res.message);
                                  }
                                })
                              }}
                           label="Add"
                           colour="green"
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {org.licences.map((l, index) => (
                        <tr key={index}>
                          <td
                            className={classNames(
                              index === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            {l.licence.description}
                          </td>
                          <td
                            className={classNames(
                              index === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            {l.assigned_to.length} / {l.licence.seats}
                          </td>
                          <td
                            className={classNames(
                              index === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            £{CommonFunctions.toFormattedFixed(l.licence.price)}
                          </td>
                          <td
                            className={classNames(
                              index === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell',
                            )}
                          >
                            Action
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/*  
                    <tfoot>
                      <tr>
                        <td>Total: {org.licences.length}</td>
                        <td>{totals.seats}</td>
                        <td>£{totals.price && CommonFunctions.toFormattedFixed(totals.price)}</td>
                        <td>&nbsp;</td>
                      </tr>
                    </tfoot>*/}
                  </table>
                  </div>  
                </div>
              <div>
              </div>  
          </main>
        </Segment>
      </div>
    </>
  );
}

export default ChainedContext(Dash, [
  [OrganisationReceiver, "org"],
]);

