import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button, SelectTwo, SelectZero} from "../../../components/Forms";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import Segment from "../../../components/Segment";
import { ConfirmModal } from "../../../components/Modals";
import { BiUpload } from "react-icons/bi";

export default function AddDocumentTemplate({ history }) {

  const params = useParams();

  const [data, setData] = useState({
    name: "",
    description: "",
    shortcode: "",
    archived: false,
    active: false,
    parent: "",
  });
  // const [itemData, setItemData] = useState(initialState);

  const [parentAreas, setParentAreas] = useState([]);
  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [mountAreas, setMountAreas] = useState(true);
  const [loading, setLoading] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .doc_types()
        .get(params.id)
        .then((res) =>
          setData({
            name: res.name,
            description: res.description,
            shortcode: res.shortcode,
            archived: res.archived,
            parent: res.parent,
            active: res.active,
          })
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  useEffect(() => {

    API.settings.doc_types().list(params.org_id).then(res => {
      setParentAreas(res.filter(a => a._id !== params.id && a.archived !== true).map(s => {
        return {
          text: s.name,
          value: s._id
        }
      }));
      setMountAreas(false);
  });

  }, [mountAreas]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Segment>
      <div>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Template Type Settings
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Enter Name of template recipient type
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="shadow sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Active
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="checkbox"
                          name="active"
                          className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          checked={data.active}
                          onChange={() => setData({ ...data, active: !data.active })}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Template
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Shortcode
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="shortcode"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.shortcode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Parent Area
                      </label>
                      <div class="">
                        <SelectZero 
                          clearable
                          name="parent"
                          onChange={handleChange}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          options={parentAreas}
                          value={data.parent}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
              {editing && <>
                  <ConfirmModal
                    open={archiveModal}
                    handleClose={() => setArchiveModal(false)}
                    handleSubmit={() => {
                      let sendData = {
                        archive: !data.archived
                      };

                      API.settings
                        .doc_types()
                        .edit(params.id, sendData)
                        .then((res) =>
                          history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_types` : "/settings/doc_types")
                        )
                        .catch((e) => CommonFunctions.handleError(e));
                    }}
                    header={data.archived ? "UnArchive Template" : "Archive Template"}
                    content={
                      `Are you sure you wish to ${data.archived ? "un-" : ""}archive this Area of Law?`
                    }
                  />

                  <Button
                    className={`inline-flex mr-2 justify-center rounded-md border ${data.archived ? "bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500" : "bg-red-600 hover:bg-red-700 focus:ring-red-500"} border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    colour={"amber"}
                    icon={data.archived ? BiUpload : TrashIcon}
                    label={data.archived ? "UnArchive" : "Archive"}
                    onClick={() => setArchiveModal(true)}
                  /></>}
                  <Button
                    className={`inline-flex justify-center rounded-md border border-transparent ${editing ? "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500"} py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    icon={editing ? PencilIcon : PlusIcon}
                    label={editing ? "Save" : "Add"}
                    colour={editing ? "amber" : "green"}
                    onClick={() => {
                      let sendData = {
                        name: data.name,
                        description: data.description,
                        shortcode: data.shortcode,
                        org_id: params.org_id ? params.org_id : undefined,
                        parent: data.parent ? data.parent : undefined,
                        active: data.active,
                      };

                      if (editing) {
                        API.settings
                          .doc_types()
                          .edit(params.id, sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_types` : "/settings/doc_types")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.settings
                          .doc_types()
                          .add(sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/doc_types` : "/settings/doc_types")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}
